import * as ImagePicker from "expo-image-picker";
import * as ImageManipulator from "expo-image-manipulator";
import { SaveFormat } from "expo-image-manipulator";
import { Alert, Platform } from "react-native";
// @ts-ignore
import mime from "mime";
import { AxiosRequestConfig } from "axios";
import { picklePOST } from "../../api/pickle";

export class PickImage {
  private width: number | null;
  private height: number | null;
  private compress: number = 1;

  constructor(width: number | null, height: number | null) {
    this.width = width;
    this.height = height;
  }

  public PickImage: () => Promise<string | null> = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      base64: true,
    });

    if (!result.cancelled) {
      const { uri } = result as ImagePicker.ImageInfo;

      const image = await this.manipulateImage(uri);
      const cloudImg = await this.uploadImage(image);

      return cloudImg.imageUrl;
    }

    return null;
  };

  private manipulateImage: (uri: string) => Promise<string> = async (uri) => {
    const manipResult = await ImageManipulator.manipulateAsync(uri, [], {
      compress: this.compress,
      format: SaveFormat.PNG,
    });

    return manipResult.uri;
  };

  private uploadImage: (uri: string) => Promise<{ imageUrl: string }> = async (
    uri
  ) => {
    if (Platform.OS !== "web") {
      Alert.alert("Not supported on this platform");
      return Promise.reject();
    }
    const fileName = uri.split("/").pop();
    const fileType = mime.getType(uri);
    const data: FormData = new FormData();
    const byteString = atob(uri.split(",")[1]);

    const ab = new ArrayBuffer(byteString.length);
    const arr = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++)
      arr[i] = byteString.charCodeAt(i);

    const blob = new Blob([arr], {
      type: fileType || "image/png",
    });

    const file = new File([blob], `${fileName}.png`);

    data.append("photo", file);

    const config = {
      Accept: "application/json",
    } as AxiosRequestConfig["headers"];

    if (this.width || this.height) {
      return picklePOST(
        `/upload?width=${this.width}&height=${this.height}`,
        data,
        config
      );
    }

    return picklePOST(`/upload`, data, config);
  };
}
