import React from "react";
import { Image, ImageStyle, StyleProp } from "react-native";

export type PNGIcon =
  | "ArrowShortLeft"
  | "ArrowShortLeftGreen"
  | "ArrowShortRight"
  | "ArrowShortRightGreen"
  | "Bank"
  | "Block"
  | "Camera"
  | "Chat"
  | "ChatFill"
  | "Check"
  | "CheckCircleFill"
  | "ClockFill"
  | "Close"
  | "CloseBlack"
  | "CreativeFill"
  | "Edit"
  | "Email"
  | "Engage"
  | "EyeOn"
  | "EyeOnFill"
  | "FacebookCircle"
  | "Filter"
  | "Follower"
  | "Gender"
  | "HeartFill"
  | "IGCircle"
  | "Instagram"
  | "Image"
  | "ImpressionFill"
  | "LemonCircle"
  | "Link"
  | "Lock"
  | "LogOut"
  | "MoneyBag"
  | "More"
  | "Parcel"
  | "People"
  | "Phone"
  | "PlusGray"
  | "Profile"
  | "Reach"
  | "ShareFill"
  | "Star"
  | "StarFill"
  | "Story"
  | "TermsFill"
  | "Tiktok"
  | "TiktokCircle"
  | "TrashRed"
  | "WaitingFill"
  | "Web";

interface Props {
  name: PNGIcon;
  size?: number;
  imageStyle?: StyleProp<ImageStyle>;
}

const RIcon = (props: Props) => {
  const { name, size = 24, imageStyle = {} } = props;

  return (
    <Image
      source={{ uri: require("../../assets/icons/" + name + ".png") }}
      style={[imageStyle, { width: size, height: size }]}
    />
  );
};

export default RIcon;
