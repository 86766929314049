import React from "react";
import {
  Image,
  ImageStyle,
  ImageURISource,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import RButton from "../reusable/RButton";
import { PLACEHOLDER_IMAGE } from "../Util";

interface Props {
  image?: ImageURISource;
  imageContainerStyle?: ImageStyle;
  title: string;
  subtitle: Array<string>;
  buttonText?: string;
  buttonFunction?: () => void;
  buttonStyle?: ViewStyle;
  bottomText?: string;
  bottomTextStyle?: TextStyle;
}

const EmptyState = (props: Props) => {
  const {
    image,
    imageContainerStyle,
    title,
    subtitle,
    buttonText,
    buttonFunction = () => {},
    buttonStyle = {},
    bottomText,
    bottomTextStyle,
  } = props;

  return (
    <View
      style={{
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image
        defaultSource={image}
        source={image || { uri: PLACEHOLDER_IMAGE }}
        style={[
          {
            width: 130,
            height: 130,
            marginTop: 28,
            resizeMode: "cover",
          },
          imageContainerStyle,
        ]}
      />
      <Text
        style={{
          ...TEXT.header6BOLD,
          color: COLOR.BLACK,
          marginTop: 13,
          marginBottom: 6,
        }}
      >
        {title}
      </Text>
      {subtitle.map((text, index) => {
        return (
          <Text
            key={text + index}
            style={{
              ...TEXT.body2,
              color: COLOR.DARKGRAY,
              textAlign: "center",
            }}
          >
            {text}
          </Text>
        );
      })}
      <Text style={[{ ...TEXT.body2BOLD }, bottomTextStyle]}>{bottomText}</Text>

      {!!buttonText && (
        <RButton
          title={buttonText}
          onPress={buttonFunction}
          containerStyle={{
            marginTop: 13,
            width: 131,
            height: 44,
            ...buttonStyle,
          }}
        />
      )}
    </View>
  );
};

export default EmptyState;
