import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useVerificationStore } from "../../stores/storeContexts";
import { COLOR, SHADOW } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import BanModal from "../molecules/BanModal";
import RIcon from "../reusable/RIcon";

interface Props {
  positionX: number;
  positionY: number;
  id: string;
  banStatus: boolean;
  setBanId: React.Dispatch<React.SetStateAction<string>>;
}

const BanInfluencer = (props: Props) => {
  const { positionX, positionY, id, banStatus, setBanId } = props;

  const { banInfluencer, unbanInfluencer } = useVerificationStore();

  const [type, setType] = useState<"permanent" | "temporary" | "unban">(
    "temporary"
  );
  const [active, setActive] = useState<boolean>(false);
  const [bannedDate, setBannedDate] = useState<string>("1");
  const [banReason, setBanReason] = useState<string>("");

  const onPress = (type: "permanent" | "temporary" | "unban") => {
    setType(type);
    setActive(!active);
  };

  const onClose = () => {
    setBanId("");
    setActive(false);
  };

  const onAccept = () => {
    banInfluencer(id, parseInt(bannedDate), banReason);
    setBannedDate("1");
    setBanReason("");
    setBanId("");
    setActive(false);
  };

  const onAcceptUnban = () => {
    unbanInfluencer(id);
    setBanId("");
    setActive(false);
  };

  const renderMenu = () => {
    if (banStatus) {
      return (
        <View style={[styles.container, { top: positionY, left: positionX }]}>
          <TouchableOpacity
            style={styles.oneContainer}
            onPress={() => onPress("unban")}
          >
            <RIcon name="CheckCircleFill" />
            <Text style={styles.body1Text}>Unban</Text>
          </TouchableOpacity>
          <BanModal
            active={active}
            id={id}
            type={type}
            onClose={onClose}
            onAccept={onAcceptUnban}
            bannedDate={bannedDate}
            setBannedDate={setBannedDate}
            reason={banReason}
            setReason={setBanReason}
            banStatus={banStatus}
          />
        </View>
      );
    }

    return (
      <View style={[styles.container, { top: positionY, left: positionX }]}>
        <TouchableOpacity
          style={styles.topContainer}
          onPress={() => onPress("temporary")}
        >
          <RIcon name="Lock" />
          <Text style={styles.body1Text}>Temporarily Ban</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.bottomContainer}
          onPress={() => onPress("permanent")}
        >
          <RIcon name="Block" />
          <Text style={[styles.body1Text, { color: COLOR.PINK }]}>
            Permanently Ban
          </Text>
        </TouchableOpacity>
        <BanModal
          active={active}
          id={id}
          type={type}
          onClose={onClose}
          onAccept={onAccept}
          bannedDate={bannedDate}
          setBannedDate={setBannedDate}
          reason={banReason}
          setReason={setBanReason}
          banStatus={banStatus}
        />
      </View>
    );
  };

  return renderMenu();
};

export default BanInfluencer;

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    backgroundColor: COLOR.WHITE,
    borderRadius: 16,
    zIndex: 99999,
    ...SHADOW.BLACK5,
  },
  topContainer: {
    flexDirection: "row",
    gap: 8,
    width: 227,
    paddingVertical: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  bottomContainer: {
    flexDirection: "row",
    gap: 8,
    width: 227,
    paddingVertical: 16,
    justifyContent: "center",
    alignItems: "center",
    borderTopColor: COLOR.LIGHTGRAY,
    borderTopWidth: 1,
  },
  oneContainer: {
    flexDirection: "row",
    gap: 8,
    width: 227,
    paddingVertical: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  body1Text: {
    ...TEXT.body1,
  },
});
