import { observer } from "mobx-react";
import React, { useState } from "react";
import {
  ImageStyle,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  ViewStyle,
  Image,
} from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { PickImage } from "../images/PickImage";
import RIcon from "./RIcon";

interface Props {
  onChange: (image: string) => void;
  containerStyle?: StyleProp<ViewStyle>;
  imageStyle?: StyleProp<ImageStyle>;
  title?: string;
}

const RImagePicker = (props: Props) => {
  const { onChange, containerStyle, imageStyle, title = "เพิ่มรูป" } = props;
  const [image, setImage] = useState<string>();

  const pickImage = async () => {
    const PImage = new PickImage(32, 32);

    const image = await PImage.PickImage();
    if (!image) return;

    setImage(image);
    onChange(image);
  };

  const renderImageUploader = () => {
    if (image) {
      return (
        <Image
          source={{ uri: image }}
          style={[styles.imageStyle, imageStyle]}
        />
      );
    } else {
      return (
        <TouchableOpacity
          style={[styles.imageContainer, containerStyle]}
          onPress={pickImage}
        >
          <RIcon name="PlusGray" size={16} />
          <Text style={styles.body1Text}>{title}</Text>
        </TouchableOpacity>
      );
    }
  };

  return renderImageUploader();
};

export default observer(RImagePicker);

const styles = StyleSheet.create({
  imageContainer: {
    width: 120,
    height: 120,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: COLOR.OFFWHITE,
    borderRadius: 16,
    marginTop: 10,
  },
  body1Text: {
    ...TEXT.body1,
    marginTop: 16,
    color: COLOR.GRAY,
  },
  imageStyle: {
    width: 120,
    height: 120,
    borderRadius: 16,
    marginTop: 10,
  },
});
