import React, { useState } from "react";
import {
  ActivityIndicator,
  Image,
  Linking,
  Modal,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { useVerificationStore } from "../../stores/storeContexts";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { IBrandAccount, initialBrandAccount } from "../../types/brandAccount";
import Text from "../Text";
import {
  FACEBOOK_PATTERN,
  HTTP_PATTERN,
  PLACEHOLDER_IMAGE,
  SOCIAL_PATTERN,
  isNull,
} from "../Util";
import RButton from "../reusable/RButton";
import RIcon, { PNGIcon } from "../reusable/RIcon";

const BrandModal = (props: {
  brandId: string | undefined;
  visible: boolean;
  onClose: () => void;
}) => {
  const { brandId, visible, onClose } = props;

  const { getBrand } = useVerificationStore();

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<{
    data: IBrandAccount;
    approve: () => Promise<void>;
    reject: () => void;
  }>({
    data: initialBrandAccount,
    approve: async () => {},
    reject: async () => {},
  });

  const isEmpty = loading || !response || !response.data;
  const brand = response?.data;

  const onLoad = async () => {
    try {
      setLoading(true);
      if (!brandId) {
        return;
      }
      const response = await getBrand(brandId);
      setResponse(response);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const onUnload = () => {
    setResponse({
      data: initialBrandAccount,
      approve: async () => {},
      reject: async () => {},
    });
    onClose();
  };

  const approve = async () => {
    try {
      if (response?.approve) {
        await response.approve();
      }
    } catch (error) {
      throw error;
    } finally {
      onUnload();
    }
  };

  const renderEmailIcon = () => {
    if (brand.isEmailVerified) {
      return (
        <View style={styles.emailStatusContainer}>
          <RIcon name="CheckCircleFill" size={32} />
        </View>
      );
    }
  };

  const renderEmailVerified = () => {
    if (brand.isEmailVerified) {
      return (
        <View style={styles.verifiedContainer}>
          <RIcon
            name="CheckCircleFill"
            size={20}
            imageStyle={{ marginRight: 4 }}
          />
          <Text style={styles.greenCaptionText}>Email Verified</Text>
        </View>
      );
    } else {
      return (
        <View style={styles.unVerifiedContainer}>
          <RIcon name="WaitingFill" size={20} imageStyle={{ marginRight: 4 }} />
          <Text style={styles.orangeBody1Text}>Email Unverified</Text>
        </View>
      );
    }
  };

  const renderInformation = (
    data: string,
    icon: PNGIcon,
    onPress: () => void,
    clickable?: boolean
  ) => {
    if (!isNull(data)) {
      if (clickable) {
        return (
          <TouchableOpacity
            style={{ flexDirection: "row", gap: 8 }}
            onPress={onPress}
          >
            <RIcon name={icon} />
            <Text style={styles.boldButtonText}>{data}</Text>
          </TouchableOpacity>
        );
      } else {
        return (
          <View style={{ flexDirection: "row", gap: 8 }}>
            <RIcon name={icon} />
            <Text style={styles.buttonText}>{data}</Text>
          </View>
        );
      }
    }
  };

  return (
    <Modal
      transparent={true}
      onDismiss={onUnload}
      onShow={onLoad}
      visible={visible}
    >
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <TouchableWithoutFeedback onPress={onUnload}>
          <View
            style={[
              StyleSheet.absoluteFill,
              {
                backgroundColor: "rgba(0,0,0,0.7)",
              },
            ]}
          />
        </TouchableWithoutFeedback>
        {isEmpty ? (
          <ActivityIndicator style={{ marginTop: 32 }} />
        ) : (
          <View
            style={{
              backgroundColor: COLOR.WHITE,
              flexWrap: "wrap",
              width: 440,
              padding: 32,
              overflow: "hidden",
              borderRadius: 16,
              alignContent: "center",
            }}
          >
            <View style={styles.columnContainer}>
              <View style={styles.rowContainer}>
                <View style={{ position: "relative" }}>
                  <Image
                    source={{ uri: brand.img || PLACEHOLDER_IMAGE }}
                    style={{ width: 112, height: 112, borderRadius: 100 }}
                  />
                  {renderEmailIcon()}
                </View>
                <View style={styles.nameContainer}>
                  {/* detail related */}
                  <Text style={[styles.boldHeader5Text, { width: 200 }]}>
                    {brand.name || "-"}
                  </Text>
                  <Text style={styles.body1Text}>{brand.category}</Text>
                  {renderEmailVerified()}
                </View>
              </View>
              {renderInformation(brand.contactName, "Profile", () => {})}
              {renderInformation(brand.contactTelephone, "Phone", () => {
                () => {
                  Linking.openURL(`tel:${brand.contactTelephone}`);
                };
              })}
              {renderInformation(brand.email, "Email", () => {
                () => {
                  Linking.openURL(`mailto:${brand.email}`);
                };
              })}
              <View style={styles.solidLine} />
              {renderInformation(
                brand.instagram,
                "IGCircle",
                () => {
                  if (SOCIAL_PATTERN.test(brand.instagram)) {
                    if (HTTP_PATTERN.test(brand.instagram)) {
                      Linking.openURL(brand.instagram);
                    } else {
                      Linking.openURL("https://" + brand.instagram);
                    }
                  } else {
                    Linking.openURL("https://instagram.com/" + brand.instagram);
                  }
                },
                true
              )}
              {renderInformation(
                brand.tiktok,
                "TiktokCircle",
                () => {
                  if (SOCIAL_PATTERN.test(brand.tiktok)) {
                    if (HTTP_PATTERN.test(brand.tiktok)) {
                      Linking.openURL(brand.tiktok);
                    } else {
                      Linking.openURL("https://" + brand.tiktok);
                    }
                  } else if (/@/.test(brand.tiktok)) {
                    Linking.openURL("https://tiktok.com/" + brand.tiktok);
                  } else {
                    Linking.openURL("https://tiktok.com/@" + brand.tiktok);
                  }
                },
                true
              )}
              {renderInformation(
                brand.facebook,
                "FacebookCircle",
                () => {
                  if (FACEBOOK_PATTERN.test(brand.facebook)) {
                    if (HTTP_PATTERN.test(brand.facebook)) {
                      Linking.openURL(brand.facebook);
                    } else {
                      Linking.openURL("https://" + brand.facebook);
                    }
                  } else {
                    Linking.openURL("https://facebook.com/" + brand.facebook);
                  }
                },
                true
              )}
              {renderInformation(
                brand.website,
                "Web",
                () => {
                  if (brand.website.includes("http")) {
                    Linking.openURL(brand.website);
                  } else {
                    Linking.openURL("https://" + brand.website);
                  }
                },
                true
              )}

              {!brand.isVerified && (
                <View style={styles.spaceBetweenRowContainer}>
                  <RButton
                    title="Reject"
                    onPress={approve}
                    containerStyle={{ width: 136, backgroundColor: COLOR.PINK }}
                  />
                  <RButton
                    title="Approve"
                    onPress={approve}
                    containerStyle={{ width: 136 }}
                  />
                </View>
              )}
            </View>
            <TouchableOpacity
              onPress={() => {
                onUnload();
              }}
            >
              <Text weight="900">X</Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </Modal>
  );
};

export default BrandModal;

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    gap: 32,
  },
  spaceBetweenRowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 32,
  },
  columnContainer: {
    flexDirection: "column",
    height: "100%",
    backgroundColor: COLOR.WHITE,
    maxWidth: 360,
    overflow: "hidden",
    gap: 16,
  },
  nameContainer: {
    flexDirection: "column",
    height: "100%",
    backgroundColor: COLOR.WHITE,
    overflow: "hidden",
    gap: 2,
  },
  verifiedContainer: {
    color: COLOR.WHITE,
    borderRadius: 16,
    padding: 4,
    flexDirection: "row",
    backgroundColor: COLOR.LIGHTGREEN2,
    width: 114,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 8,
  },
  unVerifiedContainer: {
    color: COLOR.WHITE,
    borderRadius: 16,
    padding: 4,
    flexDirection: "row",
    backgroundColor: COLOR.LIGHTORANGE,
    with: 128,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 8,
  },
  emailStatusContainer: {
    position: "absolute",
    width: 32,
    height: 32,
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.WHITE,
    borderWidth: 3,
    bottom: 0,
    right: 0,
    borderRadius: 32,
    alignItems: "center",
    justifyContent: "center",
  },
  solidLine: {
    borderWidth: 1,
    borderColor: COLOR.LIGHTGRAY,
    width: 440,
  },
  body1Text: {
    ...TEXT.body1,
  },
  whiteBody1Text: {
    ...TEXT.body1,
    color: COLOR.WHITE,
  },
  orangeBody1Text: {
    ...TEXT.body1,
    color: COLOR.ORANGE,
  },
  greenCaptionText: {
    ...TEXT.caption1,
    color: COLOR.GREEN,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
    marginBottom: 8,
  },
  buttonText: {
    ...TEXT.button,
  },
  boldButtonText: {
    ...TEXT.buttonBOLD,
  },
});
