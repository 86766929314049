import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";

const UnverifiedTag = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.captionText}>Unverified</Text>
    </View>
  );
};

export default UnverifiedTag;

const styles = StyleSheet.create({
  container: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: COLOR.ORANGE,
    paddingHorizontal: 8,
    paddingVertical: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  captionText: {
    ...TEXT.caption1,
    color: COLOR.ORANGE,
  },
});
