import React, { useRef } from "react";
import { ScrollView, StyleSheet } from "react-native";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Drawer from "../components/Drawer";
import Header from "../components/Header";
import SubHeader from "../components/SubHeader";
import { WINDOW_HEIGHT } from "../components/Util";
import BrandBannerScreen from "../screens/BannerScreen";
import InfluencerDeleteAccountScreen from "../screens/DeleteAccountScreen";
import LoginScreen from "../screens/LoginScreen";
import {
  default as BrandNotificationScreen,
  default as InfluencerNotificationScreen,
} from "../screens/NotificationScreen";
import WelcomeScreen from "../screens/WelcomeScreen";
import BrandDetailScreen from "../screens/brand/BrandDetailScreen";
import BrandListScreen from "../screens/brand/BrandListScreen";
import DealDetailScreen from "../screens/brand/DealDetailScreen";
import BrandVerification from "../screens/brand/VerificationScreen";
import InfluencerVerificationScreen from "../screens/influencer/InfluVerificationScreen";
import InfluencerDetailScreen from "../screens/influencer/InfluencerDetailScreen";
import InfluencerListScreen from "../screens/influencer/InfluencerListScreen";
import { COLOR } from "../styles/Colors";

const RootNavigation = () => {
  const scrollViewRef = useRef<ScrollView | null>(null);
  return (
    <Router>
      <Header />
      <SubHeader />
      <Drawer />
      <ScrollView style={styles.route} ref={scrollViewRef}>
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/platform" element={<WelcomeScreen />} />
          <Route path="/brand/banner" element={<BrandBannerScreen />} />
          <Route path="/brand/verification" element={<BrandVerification />} />
          <Route path="/brand/list" element={<BrandListScreen />} />
          <Route path="/brand/deals" element={<BrandDetailScreen />} />
          <Route path="/brand/deal/detail" element={<DealDetailScreen />} />
          <Route
            path="/brand/infludetail"
            element={<InfluencerDetailScreen scrollViewRef={scrollViewRef} />}
          />
          <Route
            path="/brand/notification"
            element={<BrandNotificationScreen type="brand" />}
          />
          <Route
            path="/influencer/verification"
            element={<InfluencerVerificationScreen />}
          />
          <Route
            path="/influencer/delete"
            element={<InfluencerDeleteAccountScreen />}
          />
          <Route
            path="/influencer/notification"
            element={<InfluencerNotificationScreen type="influencer" />}
          />
          <Route path="/influencer/list" element={<InfluencerListScreen />} />
          <Route
            path="/influencer/detail"
            element={<InfluencerDetailScreen scrollViewRef={scrollViewRef} />}
          />
        </Routes>
      </ScrollView>
    </Router>
  );
};

export default RootNavigation;

const styles = StyleSheet.create({
  route: {
    marginLeft: 229,
    backgroundColor: COLOR.WHITE,
    height: WINDOW_HEIGHT - 120,
  },
});
