import React from "react";
import { Image, Linking, StyleSheet, Text, View } from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { ITiktokVideoInfo } from "../../types/influencerAccount";
import { PLACEHOLDER_IMAGE } from "../Util";
import RButton from "../reusable/RButton";

interface Props {
  medias: Array<ITiktokVideoInfo>;
  username: string;
}

const TiktokRecentMedia = (props: Props) => {
  const { medias, username } = props;

  const renderMedia = () => {
    return medias.map((media) => {
      return (
        <Image
          style={styles.media}
          source={{
            uri: media.cover_image_url,
          }}
          defaultSource={{ uri: PLACEHOLDER_IMAGE }}
        />
      );
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.rowContainer}>
        <Text style={styles.boldHeader6Text}>View on</Text>
        <RButton
          containerStyle={styles.button}
          textStyle={styles.boldBody1Text}
          title="Tiktok"
          onPress={() => Linking.openURL(`https://tiktok.com/@${username}`)}
          icon="TiktokCircle"
        />
      </View>
      <View style={styles.mediaContainer}>{renderMedia()}</View>
    </View>
  );
};

export default TiktokRecentMedia;

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 24,
  },
  rowContainer: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  mediaContainer: {
    flexDirection: "row",
    gap: 32,
  },
  button: {
    justifyContent: "center",
    alignItems: "center",
    width: 100,
    height: 32,
    borderRadius: 16,
    backgroundColor: COLOR.BLACK,
  },
  media: {
    width: 250,
    height: 250,
    borderRadius: 16,
  },
  boldHeader6Text: {
    ...TEXT.header6BOLD,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
    color: COLOR.WHITE,
  },
});
