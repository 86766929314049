import { FirebaseOptions, initializeApp } from "firebase/app";
import {
  Database,
  get,
  getDatabase,
  ref,
  runTransaction,
  update,
} from "firebase/database";
import { IBanner, IBanners } from "./IBanner";
import { IBannerFirebase } from "./IBannerFirebase";
import {
  FIREBASE_PRODUCTION_apiKey,
  FIREBASE_PRODUCTION_appId,
  FIREBASE_PRODUCTION_authDomain,
  FIREBASE_PRODUCTION_databaseURL,
  FIREBASE_PRODUCTION_measurementId,
  FIREBASE_PRODUCTION_messagingSenderId,
  FIREBASE_PRODUCTION_projectId,
  FIREBASE_PRODUCTION_storageBucket,
  FIREBASE_STAGING_apiKey,
  FIREBASE_STAGING_appId,
  FIREBASE_STAGING_authDomain,
  FIREBASE_STAGING_databaseURL,
  FIREBASE_STAGING_measurementId,
  FIREBASE_STAGING_messagingSenderId,
  FIREBASE_STAGING_projectId,
  FIREBASE_STAGING_storageBucket,
} from "@env";

export const firebaseConfig = {
  staging: {
    apiKey: FIREBASE_STAGING_apiKey,
    authDomain: FIREBASE_STAGING_authDomain,
    databaseURL: FIREBASE_STAGING_databaseURL,
    projectId: FIREBASE_STAGING_projectId,
    storageBucket: FIREBASE_STAGING_storageBucket,
    messagingSenderId: FIREBASE_STAGING_messagingSenderId,
    appId: FIREBASE_STAGING_appId,
    measurementId: FIREBASE_STAGING_measurementId,
  },
  production: {
    apiKey: FIREBASE_PRODUCTION_apiKey,
    authDomain: FIREBASE_PRODUCTION_authDomain,
    databaseURL: FIREBASE_PRODUCTION_databaseURL,
    projectId: FIREBASE_PRODUCTION_projectId,
    storageBucket: FIREBASE_PRODUCTION_storageBucket,
    messagingSenderId: FIREBASE_PRODUCTION_messagingSenderId,
    appId: FIREBASE_PRODUCTION_appId,
    measurementId: FIREBASE_PRODUCTION_measurementId,
  },
};

export class FirebaseDatabase implements IBannerFirebase {
  private databaseRef: string;
  private database: Database;

  constructor(
    environment: "staging" | "production",
    firebaseConfig: FirebaseOptions
  ) {
    const firebaseApp = initializeApp(firebaseConfig, environment);

    this.databaseRef = `${environment}/config/banners`;
    this.database = getDatabase(firebaseApp);
  }

  public getCurrentBanners: () => Promise<IBanners> = () => {
    const bannerRef = ref(this.database, this.databaseRef);

    const snapshot = get(bannerRef);

    return new Promise((resolve, reject) => {
      snapshot
        .then((snap) => {
          const banners: IBanners = snap.val();
          resolve(banners);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  public removeBanner: (key: string) => Promise<IBanners> = async (key) => {
    const bannerRef = ref(this.database, this.databaseRef);

    return update(bannerRef, {
      [key]: null,
    }).then(this.getCurrentBanners);

    // return new Promise();
  };

  public addBanner: (banner: IBanner) => Promise<IBanners> = async (banner) => {
    const bannerRef = ref(this.database, this.databaseRef);

    return runTransaction(bannerRef, (currentData) => {
      if (!currentData) {
        return { [0]: banner };
      }
      const values = [banner, ...Object.values(currentData)];
      return values;
    }).then(this.getCurrentBanners);

    // return push(bannerRef, banner).then(this.getCurrentBanners);
  };
}
