import { observer } from "mobx-react";
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useLocation, useNavigate } from "react-router-dom";
import { useNavigationStore } from "../stores/storeContexts";
import { COLOR, SHADOW } from "../styles/Colors";
import { TEXT } from "../styles/Text";
import RIcon from "./reusable/RIcon";

const SubHeader = () => {
  const { popStack, navigationStack, clearStack, pushStack, spliceStack } =
    useNavigationStore();
  const navigation = useNavigate();
  const route = useLocation();

  const renderHistoryList = () => {
    return navigationStack.map((stack, index) => {
      if (index === 0) {
        return (
          <TouchableOpacity
            onPress={() => {
              clearStack();
              if (stack.display === "Influencer") {
                pushStack({
                  display: "Influencer",
                  path: "/influencer/verification",
                });
                pushStack({
                  display: "Verification",
                  path: "/influencer/verification",
                });
              } else {
                pushStack({ display: "Brand", path: "/brand/verification" });
                pushStack({
                  display: "Verification",
                  path: "/brand/verification",
                });
              }
              navigation(stack.path);
            }}
          >
            <Text style={styles.body1Text}>{stack.display}</Text>
          </TouchableOpacity>
        );
      } else if (index === navigationStack.length - 1) {
        return (
          <View style={styles.rowContainer}>
            <RIcon name="ArrowShortRight" size={20} />
            <Text style={styles.body1Text}>{stack.display}</Text>
          </View>
        );
      } else {
        return (
          <TouchableOpacity
            onPress={() => {
              spliceStack(stack.path);
              navigation(stack.path);
            }}
            style={styles.rowContainer}
          >
            <RIcon name="ArrowShortRight" size={20} />
            <Text style={styles.body1Text}>{stack.display}</Text>
          </TouchableOpacity>
        );
      }
    });
  };

  if (route.pathname === "/" || route.pathname === "/platform") {
    return <></>;
  }

  return (
    <View style={styles.headerContainer}>
      <TouchableOpacity
        onPress={() => {
          const length = navigationStack.length;
          if (length > 2) {
            popStack();
            navigation(navigationStack[navigationStack.length - 1].path);
          } else {
            clearStack();
            navigation("/platform");
          }
        }}
        style={styles.rowContainer}
      >
        <RIcon name="ArrowShortLeft" />
        <Text style={styles.buttonBoldText}>กลับ</Text>
      </TouchableOpacity>
      <View style={[styles.rowContainer, { marginLeft: 156 }]}>
        {renderHistoryList()}
      </View>
    </View>
  );
};

export default observer(SubHeader);

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
    height: 48,
    zIndex: 99999,
    backgroundColor: COLOR.WHITE,
    ...SHADOW.BLACK5,
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  buttonBoldText: {
    ...TEXT.buttonBOLD,
  },
  body1Text: {
    ...TEXT.body1,
  },
});
