import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { IInfluencerAccount } from "../../types/influencerAccount";
import InstagramCard from "../molecules/InstagramCard";
import InstagramDemographic from "../molecules/InstagramDemographic";
import InstagramRecentMedia from "../molecules/InstagramRecentMedia";
import RButton from "../reusable/RButton";
import UnlinkModal from "./UnlinkModal";

interface Props {
  influencer: IInfluencerAccount;
  onUnlink: () => void;
}

const InfluencerDetailInstagram = (props: Props) => {
  const { influencer, onUnlink } = props;

  const [showModal, setShowModal] = useState<string>("");

  const renderInstagramCard = () => {
    if (!!influencer.instagram) {
      return <InstagramCard instagram={influencer.instagram} />;
    }
  };
  const renderDemographic = () => {
    if (
      (!!influencer.instagram?.genders &&
        influencer.instagram.genders.length > 0) ||
      (!!influencer.instagram?.locations &&
        influencer.instagram.locations.length > 0) ||
      (!!influencer.instagram?.ages && influencer.instagram.ages.length > 0)
    ) {
      return (
        <InstagramDemographic
          genders={influencer.instagram.genders}
          locations={influencer.instagram.locations}
          ages={influencer.instagram.ages}
        />
      );
    }
  };

  const renderRecentMedia = () => {
    if (
      !!influencer.instagram?.recentMedia &&
      influencer.instagram?.recentMedia.length > 0
    ) {
      return (
        <InstagramRecentMedia
          medias={influencer.instagram.recentMedia}
          username={influencer.instagram.username}
        />
      );
    }
  };

  const renderModal = () => {
    if (showModal === "unlink") {
      return (
        <UnlinkModal
          visible={true}
          onClose={() => {
            setShowModal("");
          }}
          onApprove={onUnlink}
          influencer={influencer}
        />
      );
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.spaceBetweenRowContainer}>
        <Text style={styles.boldHeader5Text}>Instagram</Text>
        <RButton
          title="ยกเลิกการเชื่อมต่อ"
          onPress={() => setShowModal("unlink")}
          containerStyle={styles.buttonStyle}
        />
      </View>
      {renderInstagramCard()}
      {renderDemographic()}
      {renderRecentMedia()}
      {renderModal()}
    </View>
  );
};

export default InfluencerDetailInstagram;

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 24,
    padding: 32,
    backgroundColor: COLOR.WHITE,
  },
  spaceBetweenRowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 928,
  },
  buttonStyle: {
    backgroundColor: COLOR.PINK,
    paddingHorizontal: 16,
    width: 200,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
  },
});
