import { makeAutoObservable } from "mobx";
import { Alert } from "react-native";
import { Notification } from "../components/types/Notification";
import { picklePOST } from "./../api/pickle";

export class NotificationStore {
  isSending: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  sendNotification = async ({
    notification,
    type,
    targetIds,
  }: {
    notification: Notification;
    type: "brand" | "influencer";
    targetIds: Array<string>;
  }) => {
    this.isSending = true;
    try {
      if (type === "influencer") {
        const body = { ...notification, targetIds };
        await picklePOST(`/influencers/notification/boardcast`, {
          ...body,
          targetIds,
        });
      } else {
        const body = { ...notification, targetIds };
        await picklePOST(`/brands/notifications/boardcast`, {
          ...body,
          targetIds,
        });
      }
    } catch (error) {
      Alert.alert("Error", "Wrong password");
    } finally {
      this.isSending = false;
    }
  };
}
export default new NotificationStore();
