import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { IAge } from "../../types/influencerAccount";

interface Props {
  ages: Array<IAge>;
  dataExistFollowers: number;
}

const Age = (props: Props) => {
  const { ages, dataExistFollowers } = props;
  if (ages.length === 0) {
    return null;
  }

  const ageDemographic = (age: string, followers: number, isFirst = false) => {
    return (
      <View style={styles.row}>
        <Text style={styles.value} numberOfLines={1}>
          {age}
        </Text>
        <View style={styles.container}>
          <View
            style={{
              backgroundColor: isFirst ? COLOR.GREEN : COLOR.LIGHTGREEN,
              borderRadius: 10,
              height: "100%",
              width: `${Math.round((followers / dataExistFollowers) * 100)}%`,
            }}
          />
        </View>
        <Text style={styles.percentage} numberOfLines={1}>
          {Math.round((followers / dataExistFollowers) * 100)}%
        </Text>
      </View>
    );
  };

  const otherFollowers =
    dataExistFollowers - ages[0][1] - ages[1][1] - ages[2][1] - ages[3][1];

  return (
    <View style={styles.contentContainer}>
      {ageDemographic(ages[0][0], ages[0][1], true)}
      {ageDemographic(ages[1][0], ages[1][1])}
      {ageDemographic(ages[2][0], ages[2][1])}
      {ageDemographic(ages[3][0], ages[3][1])}
      {ageDemographic("Others", otherFollowers)}
    </View>
  );
};

export default Age;

const styles = StyleSheet.create({
  contentContainer: {
    width: "66%",
    alignSelf: "center",
    gap: 16,
  },
  header: {
    ...TEXT.caption2,
    color: COLOR.DARKGRAY,
    marginTop: 10,
  },
  row: {
    flexDirection: "row",
  },
  value: {
    width: "30%",
    alignItems: "center",
    marginRight: 4,
    ...TEXT.body1,
  },
  container: {
    height: 10,
    flex: 1,
    alignSelf: "center",
  },
  percentage: {
    width: "20%",
    textAlign: "right",
    ...TEXT.body2BOLD,
  },
});
