import React from "react";
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
  ViewStyle,
} from "react-native";
import { COLOR } from "../../styles/Colors";
import RIcon from "./RIcon";

interface Props {
  checked: boolean;
  toggleCheck: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  hideBorder?: boolean;
}

const RCheckbox = (props: Props & TouchableOpacityProps) => {
  const { checked, toggleCheck, containerStyle, hideBorder = false } = props;

  return (
    <TouchableOpacity
      {...props}
      style={[
        styles.checkBoxContainer,
        {
          backgroundColor: checked ? COLOR.GREEN : COLOR.WHITE,
          borderColor: checked ? COLOR.WHITE : COLOR.GRAY,
          borderWidth: hideBorder ? 0 : 1,
        },
        containerStyle,
      ]}
      onPress={toggleCheck}
    >
      <RIcon name="Check" size={20} />
    </TouchableOpacity>
  );
};

export default RCheckbox;

const styles = StyleSheet.create({
  checkBoxContainer: {
    height: 20,
    width: 20,
    borderWidth: 1,
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
  },
});
