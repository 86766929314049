import { initializeApp } from "firebase/app";

const config = {
  apiKey: "AIzaSyD4Ua5GictWXCYRw8OB4JNq04k7opq2R4M",
  authDomain: "verificationauth.firebaseapp.com",
  databaseURL: "https://verificationauth.firebaseio.com",
  projectId: "verificationauth",
  storageBucket: "verificationauth.appspot.com",
  messagingSenderId: "993914294712",
  appId: "1:993914294712:web:5c45161baf687f5bd9bf4d",
};

export class Firebase {
  public init = () => {
    initializeApp(config);
  };
}
