import React from "react";
import {
  StyleProp,
  StyleSheet,
  Text,
  TextInput,
  TextInputProps,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { IconName, RXIcon } from "rn-rx-icons";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import RIcon from "./RIcon";

interface Props {
  required?: boolean;
  textProps: TextInputProps;
  passwordVisible?: boolean;
  setPasswordVisible?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
  type: "normal" | "success" | "error";
  enableCheckMark?: boolean;
  enableAlertMark?: boolean;
  errorMessage?: string | null;
  checkTrigger?: boolean;
  errorTrigger?: boolean;
  iconName?: IconName;
  iconColor?: string;
}

const RInput = (props: Props) => {
  const {
    textProps,
    passwordVisible = true,
    setPasswordVisible,
    containerStyle,
    type,
    enableCheckMark = true,
    enableAlertMark = true,
    errorMessage = null,
    iconName,
    iconColor,
  } = props;
  const bgInput = type === "success" ? COLOR.WHITE : COLOR.OFFWHITE;

  const isPasswordInput = !!setPasswordVisible;

  const getBorderColor = () => {
    if (type === "success" && enableCheckMark) return COLOR.GREEN;
    else if (type === "error" && enableAlertMark) return COLOR.PINK;
    return COLOR.LIGHTGRAY;
  };

  return (
    <View style={{ marginBottom: errorMessage && enableAlertMark ? 11 : 0 }}>
      <View
        style={[
          styles.inputContainer,
          {
            minHeight: 50,
            backgroundColor: bgInput,
            borderColor: getBorderColor(),
          },
          containerStyle,
        ]}
      >
        {!!iconName && <RXIcon name={iconName} color={iconColor} />}
        <TextInput
          secureTextEntry={!passwordVisible}
          placeholderTextColor={COLOR.GRAY}
          autoCorrect={false}
          autoCapitalize="none"
          {...textProps}
          style={[
            {
              ...TEXT.body1,
              flex: 1,
              textAlignVertical: "top",
            },
            textProps.style,
          ]}
        />

        {type === "success" && enableCheckMark && (
          <View style={{ alignItems: "center", justifyContent: "center" }}>
            <RXIcon name="CheckCircle" color={COLOR.GREEN} />
          </View>
        )}
        {type === "error" ? (
          <View style={{ alignItems: "center", justifyContent: "center" }}>
            <RXIcon name="Warning" color={COLOR.PINK} size={24} />
          </View>
        ) : null}
        {isPasswordInput && (
          <TouchableOpacity
            style={{
              marginLeft:
                !enableCheckMark && !enableAlertMark
                  ? 0
                  : type === "success" || type === "error"
                  ? 8
                  : 32,
              alignSelf: "center",
              paddingTop: 4,
            }}
            onPress={setPasswordVisible}
          >
            <RIcon name="EyeOn" />
          </TouchableOpacity>
        )}
      </View>
      {errorMessage && (
        <Text style={{ ...TEXT.caption2, color: COLOR.PINK, marginTop: -5 }}>
          {errorMessage}
        </Text>
      )}
    </View>
  );
};

export default RInput;

const styles = StyleSheet.create({
  inputContainer: {
    marginVertical: 5,
    justifyContent: "space-between",
    flexDirection: "row",
    borderRadius: 24,
    backgroundColor: COLOR.OFFWHITE,
    paddingHorizontal: 20,
    paddingVertical: 10,
    gap: 8,
  },
});
