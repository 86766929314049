import { makeAutoObservable, runInAction } from "mobx";
import { pickleGET, picklePOST } from "../api/pickle";
import { IBrandAccount } from "../types/brandAccount";
import { List, DealsList, DealList } from "../types/list";

interface TypeCount {
  [key: string]: number;
}

type PageType = "verification" | "list" | "deals" | "deal";

type listInfluencerResponse = any[];

interface listBrandResponse {
  data: any[];
  detail: any;
}

interface dealContactsResponse {
  [key: string]: {
    pendingCount: number;
    ongoingCount: number;
    cancelledCount: number;
    rejectedCount: number;
    doneCount: number;
  };
}
export class VerificationStore {
  constructor() {
    makeAutoObservable(this, {});
  }

  influencers: List = {
    data: [],
    loading: false,
    page: 0,
    limit: 10,
    offset: 0,
    sortBy: { display: "createdAt", value: "createdAt" },
    sortOrder: "desc",
  };

  influencerList: List = {
    data: [],
    loading: false,
    page: 0,
    limit: 10,
    offset: 0,
    sortBy: { display: "createdAt", value: "createdAt" },
    sortOrder: "desc",
  };

  influencerReviewList: List = {
    id: "",
    data: [],
    loading: false,
    page: 0,
    limit: 10,
    offset: 0,
    sortBy: { display: "createdAt", value: "createdAt" },
    sortOrder: "desc",
  };

  brands: List = {
    data: [],
    loading: false,
    page: 0,
    limit: 10,
    offset: 0,
    sortBy: { display: "createdAt", value: "createdAt" },
    sortOrder: "desc",
  };

  brandList: List = {
    data: [],
    loading: false,
    page: 0,
    limit: 10,
    offset: 0,
    sortBy: { display: "createdAt", value: "createdAt" },
    sortOrder: "desc",
  };

  deals: DealsList = {
    data: [],
    loading: false,
    sortBy: { display: "createdAt", value: "createdAt" },
    sortOrder: "desc",
  };

  deal: DealList = {
    detail: {},
    data: [],
    loading: false,
    sortBy: { display: "createdAt", value: "createdAt" },
    sortOrder: "desc",
  };

  selectedList: Array<string> = [];

  banLoading: boolean = false;

  sortByKey(key: string, order: "asc" | "desc" = "asc") {
    return function (a: any, b: any) {
      if (a[key] === undefined && b[key] === undefined) {
        return 0;
      } else if (a[key] === undefined) {
        return 1; // 'a' goes after 'b' if 'a' is undefined
      } else if (b[key] === undefined) {
        return -1; // 'b' goes after 'a' if 'b' is undefined
      } else if (typeof a[key] === "number" && typeof b[key] === "number") {
        // Compare numbers if both keys are numbers
        return order === "asc" ? a[key] - b[key] : b[key] - a[key];
      } else if (typeof a[key] === "string" && typeof b[key] === "string") {
        // Compare strings if both keys are strings
        return order === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      } else {
        // Handle mixed types (string vs number) by placing strings first
        return typeof a[key] === "string" ? -1 : 1;
      }
    };
  }

  async sortData(platform: "brands" | "influencers", type: PageType) {
    if (platform === "brands") {
      switch (type) {
        case "verification":
          this.brands.data?.sort(
            this.sortByKey(this.brands.sortBy?.value, this.brands.sortOrder)
          );
          break;
        case "list":
          this.brandList.data?.sort(
            this.sortByKey(
              this.brandList.sortBy?.value,
              this.brandList.sortOrder
            )
          );
          break;
        case "deals":
          this.deals.data?.sort(
            this.sortByKey(this.deals.sortBy?.value, this.deals.sortOrder)
          );
          break;
        case "deal":
          this.deal.data?.sort(
            this.sortByKey(this.deals.sortBy?.value, this.deals.sortOrder)
          );
          break;
      }
    } else {
      switch (type) {
        case "verification":
          this.influencers.data?.sort(
            this.sortByKey(
              this.influencers.sortBy?.value,
              this.influencers.sortOrder
            )
          );
          break;
        case "list":
          if (
            this.influencerList.sortBy.value.includes("Count") &&
            platform === "influencers" &&
            type === "list"
          ) {
            this.influencerList.loading = true;
            const queryString = new URLSearchParams({
              sortBy: this.influencerList.sortBy.value,
              sortOrder: this.influencerList.sortOrder,
            });
            const data = await pickleGET<listBrandResponse>(
              `/influencers/auth/sort?${queryString.toString()}`
            );
            this.influencerList.data = data.data;
            this.influencerList.loading = false;
            this.influencerList.data?.sort(
              this.sortByKey(
                this.influencerList.sortBy?.value,
                this.influencerList.sortOrder
              )
            );
          } else {
            this.influencerList.data?.sort(
              this.sortByKey(
                this.influencerList.sortBy?.value,
                this.influencerList.sortOrder
              )
            );
          }
          break;
        case "deals":
        // this.deals.data?.sort(
        //   this.sortByKey(this.deals.sortBy?.value, this.deals.sortOrder)
        // );
      }
    }
  }

  async getDealContacts() {
    try {
      if (
        this.influencerList.data.length === 0 ||
        this.influencerList.page === 0
      ) {
        return;
      }
      const startOfSlice = (this.influencerList.page - 1) * 10;
      const endOfSlice = this.influencerList.page * 10;
      const influList = this.influencerList.data
        .slice(startOfSlice, endOfSlice)
        .map((element) => (element = element.id));

      const influListWithDealContacts = await picklePOST<dealContactsResponse>(
        `/influencers/auth/userlist/dealcontacts`,
        {
          userList: influList,
        }
      );
      this.influencerList.data = this.influencerList.data.map(
        (influ) =>
          (influ = {
            ...influ,
            ...influListWithDealContacts[influ.id],
          })
      );
    } catch (error) {
      console.log(error);
    }
  }

  setSortBy = (
    platform: "brands" | "influencers",
    type: PageType,
    sortBy: { display: string; value: string }
  ) => {
    if (platform === "brands") {
      switch (type) {
        case "verification":
          this.brands.sortBy = sortBy;
          break;
        case "list":
          this.brandList.sortBy = sortBy;
          break;
        case "deals":
          this.deals.sortBy = sortBy;
          break;
        case "deal":
          this.deal.sortBy = sortBy;
          break;
      }
    } else {
      switch (type) {
        case "verification":
          this.influencers.sortBy = sortBy;
          break;
        case "list":
          this.influencerList.sortBy = sortBy;
          break;
        // case "detail":
        //   this.deals.sortBy = sortBy;
      }
    }
    this.sortData(platform, type);
  };

  setSortOrder = (
    platform: "brands" | "influencers",
    type: PageType,
    sortOrder: "asc" | "desc"
  ) => {
    if (platform === "brands") {
      switch (type) {
        case "verification":
          this.brands.sortOrder = sortOrder;
          break;
        case "list":
          this.brandList.sortOrder = sortOrder;
          break;
        case "deals":
          this.deals.sortOrder = sortOrder;
          break;
        case "deal":
          this.deal.sortOrder = sortOrder;
          break;
      }
    } else {
      switch (type) {
        case "verification":
          this.influencers.sortOrder = sortOrder;
          break;
        case "list":
          this.influencerList.sortOrder = sortOrder;
          break;
        // case "detail":
        //   this.deals.sortBy = sortBy;
      }
    }
    this.sortData(platform, type);
  };

  getDeal = async (dealId: string) => {
    this.deal.loading = true;
    const queryString = new URLSearchParams({
      dealId,
    });

    const data = await pickleGET<listBrandResponse>(
      `/brands/verification/deal?${queryString.toString()}`
    );

    this.deal.detail = data.detail;
    this.deal.data = data.data;
    this.deal.loading = false;
  };

  getInfluencers = async (page: number) => {
    try {
      this.influencers.loading = true;

      if (page === this.influencers.page) {
        return;
      }

      const data = await pickleGET<listInfluencerResponse>(
        `/influencers/auth/unverifies`
      );

      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element.type === "INSTAGRAM_BUSINESS") {
          data[index].instagramUsername = element.username;
          data[index].instagramFollowers = element?.followers_count || 0;
        } else if (element.type === "TIKTOK") {
          data[index].tiktokUsername = element.username;
          data[index].tiktokFollowers = element?.followers_count || 0;
        } else if (element.type === "FACEBOOK_PAGE") {
          data[index].facebookUsername = element.username;
          data[index].facebookFollowers = element?.followers_count || 0;
        }
      }

      runInAction(() => {
        if (page === 1 || !this.influencers.data) {
          this.influencers.data = data;
        } else {
          this.influencers.data = [...this.influencers.data, ...data];
        }
        this.influencers.page = page;
      });
    } catch (error) {
      if (error instanceof Error) this.influencers.error = error.message;
    } finally {
      this.influencers.loading = false;
    }
  };

  getInfluencerReviews = async (id: string, page: number) => {
    try {
      if (this.influencerReviewList.id !== id) {
        this.clearReviewList();
      }

      this.influencerReviewList.id = id;

      if (page === this.influencerReviewList.page) {
        return;
      }

      runInAction(() => {
        this.influencerReviewList.loading = true;
        this.influencerReviewList.page = page;
      });

      const queryString = new URLSearchParams({
        limit: this.influencerReviewList.limit.toString(),
        offset: (
          page * this.influencerReviewList.limit -
          (this.influencerReviewList.limit - this.influencerReviewList.offset)
        ).toString(),
      });

      const data = await pickleGET<listBrandResponse>(
        `/influencers/auth/getReviews/${id}?${queryString.toString()}`
      );

      runInAction(() => {
        if (page === 1 || !this.influencerReviewList.data) {
          this.influencerReviewList.data = data.data;
        } else {
          this.influencerReviewList.data = [
            ...this.influencerReviewList.data,
            ...data.data,
          ];
        }
        this.influencerReviewList.page = page;
      });
    } catch (error) {
      runInAction(() => {
        if (error instanceof Error)
          this.influencerReviewList.error = error.message;
      });
    } finally {
      runInAction(() => {
        this.influencerReviewList.loading = false;
      });
    }
  };

  clearReviewList = () => {
    this.influencerReviewList = {
      id: "",
      data: [],
      loading: false,
      page: 0,
      limit: 10,
      offset: 0,
      sortBy: { display: "createdAt", value: "createdAt" },
      sortOrder: "desc",
    };
  };

  getInfluencerList = async (params: {
    page: number;
    searchText?: string;
    date?: Date[];
    sortBy?: string;
    sortOrder?: "asc" | "desc";
  }) => {
    const { page, searchText } = params;
    try {
      runInAction(() => {
        this.influencerList.loading = true;
        this.influencerList.page = page;
      });

      // disable pagination for now due to time issue
      const queryString = new URLSearchParams({
        // limit: this.influencerList.limit.toString(),
        // offset: (
        //   page * this.influencerList.limit -
        //   (this.influencerList.limit - this.influencerList.offset)
        // ).toString(),
        searchText: searchText || "",
        offset: "0",
        limit: "200",
      });

      const data = await pickleGET<listBrandResponse>(
        `/influencers/auth/userlist?${queryString.toString()}`
      );

      runInAction(() => {
        if (page === 1 || !this.influencerList.data) {
          this.influencerList.data = data.data;
        } else {
          this.influencerList.data = [
            ...this.influencerList.data,
            ...data.data,
          ];
        }
        this.influencerList.page = page;
        this.getDealContacts();
      });
    } catch (error) {
      runInAction(() => {
        if (error instanceof Error) this.influencerList.error = error.message;
      });
    } finally {
      runInAction(() => {
        this.influencerList.loading = false;
      });
    }
  };

  resetBrands = (type: "verification" | "list" | "deals") => {
    switch (type) {
      case "verification":
        this.brands.page = 0;
        this.brands.data = [];
        break;
      case "list":
        this.brandList.page = 0;
        this.brandList.data = [];
        break;
      case "deals":
        this.deals.data = [];
        break;
    }
  };

  resetInfluencers = (type: "verification" | "list" | "deals") => {
    switch (type) {
      case "verification":
        this.influencers.page = 0;
        this.influencers.data = [];
        break;
      case "list":
        this.influencerList.page = 0;
        this.influencerList.data = [];
      // case "deals":
      //   this.deals.data = [];
    }
  };

  getBrands = async (page: number) => {
    try {
      if (page === this.brands.page) {
        return;
      }

      runInAction(() => {
        this.brands.loading = true;
        this.brands.page = page;
      });

      const queryString = new URLSearchParams({
        limit: this.brands.limit.toString(),
        offset: (
          page * this.brands.limit -
          (this.brands.limit - this.brands.offset)
        ).toString(),
      });

      const data = await pickleGET<listBrandResponse>(
        `/brands/verification?${queryString.toString()}`
      );

      runInAction(() => {
        if (page === 1 || !this.brands.data) {
          this.brands.data = data.data;
        } else {
          this.brands.data = [...this.brands.data, ...data.data];
        }
        this.brands.page = page;
      });
    } catch (error) {
      runInAction(() => {
        if (error instanceof Error) this.brands.error = error.message;
      });
    } finally {
      runInAction(() => {
        this.brands.loading = false;
      });
    }
  };

  getBrandList = async (page: number) => {
    try {
      if (page === this.brandList.page) {
        return;
      }

      runInAction(() => {
        this.brandList.loading = true;
        this.brandList.page = page;
      });

      const queryString = new URLSearchParams({
        limit: this.brandList.limit.toString(),
        offset: (
          page * this.brandList.limit -
          (this.brandList.limit - this.brandList.offset)
        ).toString(),
      });

      const data = await pickleGET<listBrandResponse>(
        `/brands/verification/userlist?${queryString.toString()}`
      );

      runInAction(() => {
        if (page === 1 || !this.brandList.data) {
          this.brandList.data = data.data;
        } else {
          this.brandList.data = [...this.brandList.data, ...data.data];
        }
        this.brandList.page = page;
      });
    } catch (error) {
      runInAction(() => {
        if (error instanceof Error) this.brandList.error = error.message;
      });
    } finally {
      runInAction(() => {
        this.brandList.loading = false;
      });
    }
  };

  getDeals = async (brandId: string) => {
    try {
      this.deals.loading = true;
      const queryString = new URLSearchParams({
        brandId,
      });

      const data = await pickleGET<listBrandResponse>(
        `/brands/verification/deals?${queryString.toString()}`
      );

      runInAction(() => {
        this.deals.data = data.data;
      });
    } catch (error) {
      runInAction(() => {
        if (error instanceof Error) this.deals.error = error.message;
      });
    } finally {
      runInAction(() => {
        this.deals.loading = false;
      });
    }
  };

  approveBrand = async (brandId: string) => {
    // find this.brands.data
    let index = -1;
    this.brands.data?.forEach((brand, i) => {
      if (brand.brandId === brandId) {
        index = i;
      }
    });
    if (index === -1) {
      return;
    }

    // wait 2 second
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    await picklePOST("/brands/verification/approve", { brandId });

    runInAction(() => {
      // update status
      if (this.brands?.data?.[index]) {
        this.brands.data[index].isVerified = true;
      }
    });
  };

  bulkApproveBrand = async (brandIdList: string[]) => {
    const verifiedBrand = this.brands.data.filter((brand) => {
      return brandIdList.includes(brand.brandId);
    });

    const result = verifiedBrand.map(async (brand) => {
      await picklePOST("/brands/verification/approve", {
        brandId: brand.brandId,
      });
    });

    await Promise.all(result);

    runInAction(() => {
      // update status
      verifiedBrand.map((brand) => {
        const brandIndex = this.brands.data.findIndex(
          (oldBrand) => oldBrand.brandId === brand.brandId
        );
        if (brandIndex) {
          this.brands.data[brandIndex].isVerified = true;
        }
      });
    });
  };

  setSelectedList = (newList: Array<string>) => {
    this.selectedList = newList;
  };

  rejectBrand = async (brandId: string) => {
    console.log("reject", brandId);
  };

  getBrand = async (brandId: string) => {
    try {
      const response = await pickleGET<IBrandAccount>(
        `/brands/verification/${brandId}`
      );

      return {
        data: response,
        approve: () => this.approveBrand(brandId),
        reject: () => this.rejectBrand(brandId),
      };
    } catch (error) {
      throw error;
    }
  };

  approveInflu = async (
    tiktokId: string | undefined,
    instagramId: string | undefined,
    facebookId: string | undefined
  ) => {
    try {
      const result = await picklePOST(`/influencers/auth/verify`, {
        tiktokId,
        instagramId,
        facebookId,
      });
      runInAction(() => {
        this.influencers.data = this.influencers.data?.filter(({ id }) => {
          return id !== tiktokId && id !== instagramId && id !== facebookId;
        });
      });
      return result;
    } catch (error) {
      throw error;
    }
  };

  rejectInflu = async (
    tiktokId: string | undefined,
    instagramId: string | undefined,
    facebookId: string | undefined,
    rejectedReason: string
  ) => {
    try {
      await picklePOST(`/influencers/auth/unverify`, {
        tiktokId,
        instagramId,
        facebookId,
        rejectedReason,
      });
      runInAction(() => {
        this.influencers.data = this.influencers.data?.filter(({ id }) => {
          return id !== tiktokId && id !== instagramId && id !== facebookId;
        });
      });
    } catch (error) {
      throw error;
    }
  };

  bulkApproveInflu = async (influencerList: string[]) => {
    const result = influencerList.map(async (influencerId) => {
      await picklePOST(`/influencers/auth/verify`, {
        tiktokId: influencerId,
        instagramId: influencerId,
        facebookId: influencerId,
      });
    });

    await Promise.all(result);

    runInAction(() => {
      // update status
      influencerList.map((influencerId) => {
        this.influencers.data = this.influencers.data?.filter(({ id }) => {
          return id !== influencerId;
        });
      });
    });
  };

  setBrandsPage = async (page: number) => {
    this.brands.page = page;
  };

  setBrandListPage = async (page: number) => {
    this.brandList.page = page;
  };

  setInfluencersPage = async (page: number) => {
    this.influencers.page = page;
  };

  setInfluencerListPage = async (page: number) => {
    this.influencerList.page = page;
    this.getDealContacts();
  };

  banInfluencer = async (id: string, length: number, reason: string) => {
    try {
      this.banLoading = true;
      await picklePOST("/backoffice/ban/influencer", {
        influencerAccountId: id,
        banDaysDuration: length,
        banReason: reason,
      });
      this.influencerList.data[
        this.influencerList.data.findIndex((influencer) => influencer.id === id)
      ].isBanned.status = true;
      this.banLoading = false;
    } catch (error) {
      console.log(error);
      this.banLoading = false;
    }
  };

  unbanInfluencer = async (id: string) => {
    try {
      this.banLoading = true;
      await picklePOST("/backoffice/unban/influencer", {
        influencerAccountId: id,
      });
      this.influencerList.data[
        this.influencerList.data.findIndex((influencer) => influencer.id === id)
      ].isBanned.status = false;
      this.banLoading = false;
    } catch (error) {
      console.log(error);
      this.banLoading = false;
    }
  };
}

export default new VerificationStore();
