import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { ITiktokInfluencer } from "../../types/influencerAccount";
import { PLACEHOLDER_IMAGE } from "../Util";
import RIcon from "../reusable/RIcon";

interface Props {
  tiktok: ITiktokInfluencer;
}

const TiktokCard = (props: Props) => {
  const { tiktok } = props;
  const {
    profile_picture_url = PLACEHOLDER_IMAGE,
    username = "-",
    followers_count = 0,
    recentMediaLikes = 0,
    recentMediaViews = 0,
    recentMediaComments = 0,
    recentMediaShares = 0,
  } = tiktok;

  const renderProfileImage = () => {
    if (!!profile_picture_url) {
      return (
        <Image
          style={styles.avatarContainer}
          source={{ uri: profile_picture_url }}
        />
      );
    } else {
      return (
        <Image
          style={styles.avatarContainer}
          source={{ uri: PLACEHOLDER_IMAGE }}
        />
      );
    }
  };

  return (
    <View style={styles.tiktokContainer}>
      <View style={styles.tiktokLeftContainer}>
        <View style={styles.profileContainer}>
          {renderProfileImage()}
          <Text style={styles.boldHeader6Text}>{username}</Text>
        </View>
        <View style={[styles.columnContainer, { gap: 0 }]}>
          <Text style={styles.boldHeader5Text}>{followers_count}</Text>
          <View style={styles.rowContainer}>
            <RIcon name="Follower" />
            <Text style={styles.followerText}>Followers</Text>
          </View>
        </View>
      </View>
      <View style={styles.tiktokRightContainer}>
        <View style={[styles.statContainer, { flex: 2 }]}>
          <View style={styles.columnContainer}>
            <View style={styles.rowContainer}>
              <RIcon name="Tiktok" />
              <Text style={styles.boldButtonText}>Tiktok Video</Text>
            </View>
            <Text style={styles.body2Text}>
              Engagement เฉลี่ยจาก 60 วันล่าสุด
            </Text>
          </View>
        </View>
        <View style={[styles.statContainer, { flex: 1 }]}>
          <View style={styles.columnContainer}>
            <View style={styles.rowContainer}>
              <RIcon name="EyeOnFill" />
              <Text style={styles.boldButtonText}>{recentMediaViews}</Text>
            </View>
            <Text style={styles.body2Text}>Video Views</Text>
          </View>
          <View style={styles.columnContainer}>
            <View style={styles.rowContainer}>
              <RIcon name="HeartFill" />
              <Text style={styles.boldButtonText}>{recentMediaLikes}</Text>
            </View>
            <Text style={styles.body2Text}>Likes</Text>
          </View>
        </View>
        <View style={[styles.statContainer, { flex: 1 }]}>
          <View style={styles.columnContainer}>
            <View style={styles.rowContainer}>
              <RIcon name="Chat" />
              <Text style={styles.boldButtonText}>{recentMediaComments}</Text>
            </View>
            <Text style={styles.body2Text}>Comments</Text>
          </View>
          <View style={styles.columnContainer}>
            <View style={styles.rowContainer}>
              <RIcon name="ShareFill" />
              <Text style={styles.boldButtonText}>{recentMediaShares}</Text>
            </View>
            <Text style={styles.body2Text}>Shares</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default TiktokCard;

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "column",
    backgroundColor: COLOR.WHITE,
    gap: 16,
  },
  followerDetailContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  columnContainer: {
    flexDirection: "column",
    gap: 4,
  },
  alignEndColumnContainer: {
    flexDirection: "column",
    gap: 4,
    alignItems: "flex-end",
  },
  avatarContainer: {
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: COLOR.WHITE,
  },
  tiktokContainer: {
    padding: 32,
    width: 928,
    height: 213,
    borderRadius: 16,
    margin: 3,
    backgroundColor: COLOR.WHITE,
    flexDirection: "row",
    borderColor: COLOR.BLACK,
    borderWidth: 3,
  },
  tiktokLeftContainer: {
    flexDirection: "column",
    gap: 18,
    borderStyle: "dashed",
    borderRightColor: COLOR.BLACK,
    borderRightWidth: 1,
    paddingRight: 48,
  },
  statContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 4,
    gap: 10,
  },
  tiktokRightContainer: {
    flex: 1,
    flexDirection: "row",
    paddingLeft: 40,
    alignItems: "center",
  },
  profileContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  rowContainer: {
    flexDirection: "row",
    gap: 4,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
  },
  boldHeader6Text: {
    ...TEXT.header6BOLD,
  },
  boldButtonText: {
    ...TEXT.buttonBOLD,
    marginTop: 2,
  },
  body2Text: {
    ...TEXT.body2,
    marginLeft: 28,
  },
  followerText: {
    ...TEXT.body2,
    marginTop: 4,
  },
});
