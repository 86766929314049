import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { IInfluencerAccount } from "../../types/influencerAccount";
import { PLACEHOLDER_IMAGE } from "../Util";
import RIcon from "../reusable/RIcon";
import { observer } from "mobx-react";

interface Props {
  influencer: IInfluencerAccount;
  loading: boolean;
}

const InfluencerDetailOverview = (props: Props) => {
  const { influencer, loading } = props;

  const { profileImages = [] } = influencer;

  const [image, setImage] = useState<string>("");

  const onPressImage = (image: string) => {
    setImage(image);
  };

  const ImageComponent = ({ index }: { index: number }) => {
    if (!!profileImages[index]) {
      return (
        <TouchableOpacity onPress={() => onPressImage(profileImages[index])}>
          <Image
            style={styles.imageContainer}
            source={{ uri: profileImages[index] }}
          />
        </TouchableOpacity>
      );
    } else {
      return (
        <View>
          <Image
            style={styles.imageContainer}
            source={{ uri: PLACEHOLDER_IMAGE }}
          />
        </View>
      );
    }
  };

  const ImagesComponent = () => {
    return (
      <>
        <View style={styles.rowContainer}>
          <View style={styles.columnContainer}>
            <ImageComponent index={0} />
            <ImageComponent index={1} />
            <ImageComponent index={2} />
            <ImageComponent index={3} />
          </View>
          <Image
            style={[styles.bigImageContainer, { objectFit: "scale-down" }]}
            source={{ uri: image }}
          />
        </View>
      </>
    );
  };

  const IntroduceComponent = () => {
    if (!!influencer.introduce) {
      return (
        <Text style={styles.boldHeader6Text}>
          {"“"}&nbsp;
          {influencer.introduce.trim()}&nbsp;{"“"}
        </Text>
      );
    }
    return <></>;
  };

  const ValueComponent = ({
    valueArray,
    bold,
  }: {
    valueArray: string[];
    bold: boolean;
  }) => {
    let returnValue: string = "";
    if (valueArray.length === 1) {
      returnValue = valueArray[0];
    } else {
      valueArray.map((value, index) => {
        if (index === 0) returnValue += value || "-";
        else returnValue += "/" + (value || "-");
      });
    }
    if (!returnValue) returnValue = "-";
    if (bold) {
      return <Text style={styles.boldBody1Text}>{returnValue}</Text>;
    }
    return <Text style={styles.body1Text}>{returnValue}</Text>;
  };

  const onCheckGender = () => {
    if (!!influencer.gender && influencer.gender !== "undefined") {
      return influencer.gender;
    }
    return "ไม่ระบุ";
  };

  const BodySizeComponent = () => {
    return (
      <View style={styles.profileContainer}>
        <IntroduceComponent />
        <Text style={styles.boldButtonText}>สัดส่วนอินฟลูเอนเซอร์</Text>
        <View style={styles.rowContainer}>
          <View style={styles.bodySizeContainer}>
            <Text style={styles.boldBody1Text}>
              <ValueComponent
                valueArray={[influencer.bodySize?.weight]}
                bold={true}
              />
            </Text>
            <Text style={styles.body2Text}>{"น้ำหนัก (กก.)"}</Text>
          </View>
          <View style={styles.bodySizeContainer}>
            <ValueComponent
              valueArray={[influencer.bodySize?.height]}
              bold={true}
            />
            <Text style={styles.body2Text}>{"ส่วนสูง (ซม.)"}</Text>
          </View>
          <View style={styles.bodySizeContainer}>
            <ValueComponent
              valueArray={[
                influencer.bodySize?.bust,
                influencer.bodySize?.waist,
                influencer.bodySize?.hip,
              ]}
              bold={true}
            />
            <Text style={styles.body2Text}>{"อก/เอว/สะโพก(นิ้ว)"}</Text>
          </View>
          <View style={styles.bodySizeContainer}>
            <ValueComponent
              valueArray={[influencer.bodySize?.footwear]}
              bold={true}
            />
            <Text style={styles.body2Text}>{"รองเท้า(EUR)"}</Text>
          </View>
        </View>
      </View>
    );
  };

  const InformationComponent = () => {
    return (
      <View style={styles.rowContainer}>
        <View style={styles.InformationContainer}>
          <Text style={styles.boldButtonText}>ข้อมูลส่วนตัว</Text>
          <View style={styles.InfoTextContainer}>
            <RIcon name="Profile" />
            <ValueComponent
              valueArray={[influencer.contactName || "-"]}
              bold={false}
            />
          </View>
          <View style={styles.InfoTextContainer}>
            <RIcon name="Gender" />
            <ValueComponent valueArray={[onCheckGender()]} bold={false} />
          </View>
          <View style={styles.InfoTextContainer}>
            <RIcon name="Phone" />
            <ValueComponent
              valueArray={[influencer.contactTelephone]}
              bold={false}
            />
          </View>
          <View style={styles.InfoTextContainer}>
            <RIcon name="Email" />
            <ValueComponent valueArray={[influencer.email]} bold={false} />
          </View>
        </View>
        <View style={styles.InformationContainer}>
          <Text style={styles.boldButtonText}>ข้อมูลสำหรับจัดส่ง</Text>
          <View style={styles.InfoTextContainer}>
            <RIcon name="Profile" />
            <ValueComponent
              valueArray={[influencer.addressRecipient]}
              bold={false}
            />
          </View>
          <View style={styles.InfoTextContainer}>
            <RIcon name="Phone" />
            <ValueComponent
              valueArray={[influencer.deliveryPhone]}
              bold={false}
            />
          </View>
          <View style={styles.InfoTextContainer}>
            <RIcon name="Parcel" />
            <ValueComponent valueArray={[influencer.address]} bold={false} />
          </View>
        </View>
        <View style={styles.LastChildInformationContainer}>
          <Text style={styles.boldButtonText}>ข้อมูลธุรกรรม</Text>

          <View style={styles.InfoTextContainer}>
            <RIcon name="Bank" />
            <ValueComponent valueArray={[influencer.bank]} bold={false} />
          </View>
          <View style={styles.InfoTextContainer}>
            <RIcon name="MoneyBag" />
            <ValueComponent valueArray={[influencer.bankNumber]} bold={false} />
          </View>
          <View style={styles.InfoTextContainer}>
            <RIcon name="People" />
            <ValueComponent
              valueArray={[influencer.bankOwnerName]}
              bold={false}
            />
          </View>
        </View>
      </View>
    );
  };

  useEffect(() => {
    if (!!profileImages[0]) {
      setImage(profileImages[0]);
    } else {
      setImage(PLACEHOLDER_IMAGE);
    }
  }, [loading]);

  return (
    <View style={styles.container}>
      <Text style={styles.boldHeader5Text}>Overview</Text>
      <ImagesComponent />
      <BodySizeComponent />
      <InformationComponent />
    </View>
  );
};

export default observer(InfluencerDetailOverview);

const styles = StyleSheet.create({
  container: {
    padding: 32,
    flexDirection: "column",
    backgroundColor: COLOR.WHITE,
    gap: 16,
  },
  columnContainer: {
    flexDirection: "column",
    gap: 16,
  },
  rowContainer: {
    flexDirection: "row",
    gap: 16,
  },
  InformationRowContainer: {
    flexDirection: "row",
    gap: 16,
    justifyContent: "space-evenly",
  },
  imageContainer: {
    width: 120,
    height: 120,
    borderRadius: 12,
  },
  bigImageContainer: {
    width: 792,
    height: 528,
    borderRadius: 16,
    backgroundColor: COLOR.GRAY,
  },
  profileContainer: {
    width: 928,
    flexDirection: "column",
    alignItems: "center",
    padding: 16,
    gap: 16,
  },
  bodySizeContainer: {
    flexDirection: "column",
    backgroundColor: COLOR.OFFWHITE,
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingVertical: 8,
    minWidth: 140,
    minHeight: 56,
    alignItems: "center",
    justifyContent: "center",
  },
  InformationContainer: {
    paddingVertical: 8,
    paddingHorizontal: 32,
    gap: 16,
    borderRightWidth: 1,
    borderRightColor: COLOR.LIGHTGRAY,
    borderStyle: "dashed",
    width: 321,
  },
  LastChildInformationContainer: {
    paddingVertical: 8,
    paddingHorizontal: 32,
    gap: 16,
    width: 321,
  },
  InfoTextContainer: {
    maxWidth: 250,
    gap: 8,
    flexDirection: "row",
    alignItems: "center",
  },
  boldHeader6Text: {
    ...TEXT.header6BOLD,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
  },
  boldButtonText: {
    ...TEXT.buttonBOLD,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
  },
  body2Text: {
    ...TEXT.body2,
  },
  body1Text: {
    ...TEXT.body1,
    maxWidth: 200,
  },
});
