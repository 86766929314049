import React, { useState } from "react";
import {
  Image,
  Linking,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import { COLOR, SHADOW } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import {
  IFacebookInfluencer,
  IInstagramInfluencer,
  ITiktokInfluencer,
} from "../../types/influencerAccount";
import { formatDate } from "../../utils/formatDate";
import {
  HTTP_PATTERN,
  PLACEHOLDER_IMAGE,
  SOCIAL_PATTERN,
  isNull,
  mapCategoryIcon,
} from "../Util";
import UnverifiedTag from "../atoms/UnverifiedTag";
import VerifiedTag from "../atoms/VerifiedTag";
import RCheckbox from "./RCheckbox";
import RIcon from "./RIcon";

type SocialInfluencer =
  | IInstagramInfluencer
  | ITiktokInfluencer
  | IFacebookInfluencer;
interface Props {
  data?: Record<string, any>;
  keys?: Array<ListKey>;
  ignored?: string[];
  onPress?: () => void;
  color?: string;
  title?: Array<TitleKey>;
  selectedList: string[];
  setSelectedList: (newList: Array<string>) => void;
  sortOrder?: "asc" | "desc";
  sortKey?: string;
  setSortKey?: React.Dispatch<React.SetStateAction<string>>;
  swapSort?: () => void;
  index?: number;
  containerStyle?: StyleProp<ViewStyle>;
  useEmailVerify?: boolean;
  showVerify?: boolean;
  nameContainerStyle?: StyleProp<ViewStyle>;
  nameTextStyle?: StyleProp<TextStyle>;
  cellStyle?: StyleProp<ViewStyle>;
  banId?: string;
  setBanId?: React.Dispatch<React.SetStateAction<string>>;
  setBanStatus?: React.Dispatch<React.SetStateAction<boolean>>;
  setPositionX?: React.Dispatch<React.SetStateAction<number>>;
  setPositionY?: React.Dispatch<React.SetStateAction<number>>;
}
export interface ListKey {
  display: string;
  value: string;
  size: number;
}

export interface TitleKey {
  display: string;
  size: number;
}

const ListItem = (props: Props) => {
  const {
    title = [],
    data = {},
    ignored,
    onPress = () => {},
    color = COLOR.WHITE,
    keys = [],
    selectedList,
    setSelectedList,
    sortOrder = "desc",
    sortKey = "updatedAt",
    setSortKey = () => {},
    swapSort = () => {},
    index = 1,
    containerStyle,
    useEmailVerify = true,
    showVerify = false,
    nameContainerStyle,
    nameTextStyle,
    cellStyle,
    banId = "",
    setBanId = () => {},
    setBanStatus = () => {},
    setPositionX = () => {},
    setPositionY = () => {},
  } = props;

  const [hover, setHover] = useState<boolean>(false);

  const onPressRadio = (id: string, isVerified: boolean) => {
    if (isVerified) {
      return;
    }
    const tempList = [...selectedList];
    const idIndex = tempList.findIndex((element) => element === id);
    if (idIndex === -1) {
      tempList.push(id);
    } else {
      tempList.splice(idIndex, 1);
    }
    setSelectedList(tempList);
  };

  const renderSmallEmailIcon = () => {
    if (!showVerify) return <></>;
    if (useEmailVerify) {
      if (data["isEmailVerified"]) {
        return (
          <View style={styles.smallEmailStatusContainer}>
            <RIcon name="CheckCircleFill" size={21} />
          </View>
        );
      } else {
        return (
          <View style={styles.smallEmailStatusContainer}>
            <RIcon name="WaitingFill" size={21} />
          </View>
        );
      }
    } else {
      if (data.isBanned.status) {
        return (
          <View style={styles.banStatusContainer}>
            <RIcon name="Close" size={21} />
          </View>
        );
      }
      if (data?.tiktok?.isVerified || data?.instagram?.isVerified) {
        return (
          <View style={styles.smallEmailStatusContainer}>
            <RIcon name="CheckCircleFill" size={21} />
          </View>
        );
      }
      return (
        <View style={styles.smallEmailStatusContainer}>
          <RIcon name="WaitingFill" size={21} />
        </View>
      );
    }
  };

  const renderSortIcon = (key: string) => {
    if (sortKey === key) {
      switch (sortOrder) {
        case "asc":
          return (
            <RIcon
              name="ArrowShortLeft"
              imageStyle={{ transform: [{ rotate: "90deg" }] }}
            />
          );
        case "desc":
          return (
            <RIcon
              name="ArrowShortRight"
              imageStyle={{ transform: [{ rotate: "90deg" }] }}
            />
          );
      }
    }
  };

  const TagAndFollower = ({
    social,
    follower,
    activeFollower,
  }: {
    social: IInstagramInfluencer | ITiktokInfluencer | IFacebookInfluencer;
    follower?: string;
    activeFollower?: string;
  }) => {
    const element: JSX.Element[] = [];
    if (!!follower || !!activeFollower) {
      if (!!follower) {
        element.push(
          <Text style={styles.boldBody1Text} numberOfLines={1}>
            {follower}
          </Text>
        );
      }
      if (!!activeFollower) {
        element.push(
          <Text style={styles.boldCaption1Text} numberOfLines={1}>
            {"Active Followers " + activeFollower}
          </Text>
        );
      }
      return <>{element}</>;
    } else {
      if (social?.isVerified) {
        return <VerifiedTag />;
      } else {
        return <UnverifiedTag />;
      }
    }
  };

  const cellDecoration = (
    key: string,
    data: Record<string, string | SocialInfluencer>
  ) => {
    const value: string | boolean | Array<string> | SocialInfluencer = isNull(
      data[key]
    )
      ? "-"
      : data[key];
    const stringValue = value.toString();
    if (key === value) {
      return (
        <TouchableOpacity
          style={styles.centerRowContainer}
          onPress={() => {
            setSortKey(key);
            swapSort();
          }}
        >
          <Text style={[styles.body1Text, { maxWidth: 150 }]} numberOfLines={1}>
            {value === "Ban" ? "" : value}
          </Text>
          {renderSortIcon(key)}
        </TouchableOpacity>
      );
    }
    if (typeof value === "boolean") {
      return (
        <Text style={styles.body1Text} numberOfLines={1}>
          {value ? "Yes" : "No"}
        </Text>
      );
    }
    if (key === "invited") {
      if (Array.isArray(value) && value.length > 0) {
        return (
          <Text style={styles.body1Text} numberOfLines={1}>
            Yes
          </Text>
        );
      } else {
        return (
          <Text style={styles.body1Text} numberOfLines={1}>
            No
          </Text>
        );
      }
    }
    if (key === "interests") {
      if (Array.isArray(value) && value.length > 0) {
        return (
          <Text style={styles.body1Text}>
            {value.map((interest: string) => {
              return mapCategoryIcon(interest)?.icon + " ";
            })}
          </Text>
        );
      } else {
        return (
          <Text style={styles.body1Text} numberOfLines={1}>
            -
          </Text>
        );
      }
    }
    if (key === "number") {
      return (
        <Text style={styles.body1Text} numberOfLines={1}>
          {index + 1}
        </Text>
      );
    }
    if (key === "img" || key === "profile_picture_url") {
      const renderUri = () => {
        if (
          value === "-" ||
          (typeof value === "string" && value.includes("_test"))
        ) {
          return PLACEHOLDER_IMAGE;
        } else {
          return value;
        }
      };
      return (
        <View style={{ position: "relative" }}>
          <Image
            source={{ uri: renderUri() }}
            style={{
              width: 50,
              height: 50,
              borderRadius: 50,
              borderWidth: 3,
              borderColor: COLOR.OFFWHITE,
            }}
          />
          {renderSmallEmailIcon()}
        </View>
      );
    }
    if (key === "createdAt" || key === "updatedAt") {
      return <Text>{formatDate(stringValue)}</Text>;
    }
    if (key === "name" || key === "displayName") {
      return (
        <View style={[styles.columnContainer, nameContainerStyle]}>
          <Text style={[styles.boldBody1Text, nameTextStyle]} numberOfLines={1}>
            {value}
          </Text>
          {!!data["contactName"] && (
            <Text style={styles.body1Text} numberOfLines={1}>
              {data["contactName"]}
            </Text>
          )}
        </View>
      );
    }
    if (key === "socialName") {
      const {
        type,
        instagramUsername,
        instagram,
        instagramFollowers,
        activeFollowers,
        tiktokUsername,
        tiktok,
        tiktokFollowers,
        facebookUsername,
        facebookFollowers,
        facebook,
      } = data;
      if (
        type === "INSTAGRAM_BUSINESS" &&
        instagramUsername !== "-" &&
        typeof instagram !== "string"
      )
        return (
          <View style={[styles.columnContainer, { gap: 4 }]}>
            <View
              style={[
                styles.rowContainer,
                { paddingVertical: 0, minHeight: 0 },
              ]}
            >
              <RIcon name="IGCircle" />
              <Text
                style={[styles.boldBody1Text, { width: "70%" }]}
                numberOfLines={1}
              >
                {instagramUsername}
              </Text>
            </View>
            <TagAndFollower
              social={instagram}
              follower={instagramFollowers?.toString()}
              activeFollower={activeFollowers?.toString()}
            />
          </View>
        );
      if (
        type === "TIKTOK" &&
        tiktokUsername !== "-" &&
        typeof tiktok !== "string"
      )
        return (
          <View style={[styles.columnContainer, { gap: 4 }]}>
            <View
              style={[
                styles.rowContainer,
                { paddingVertical: 0, minHeight: 0 },
              ]}
            >
              <RIcon name="TiktokCircle" />
              <Text
                style={[styles.boldBody1Text, { width: "70%" }]}
                numberOfLines={1}
              >
                {tiktokUsername}
              </Text>
            </View>
            <TagAndFollower
              social={tiktok}
              follower={tiktokFollowers?.toString()}
            />
          </View>
        );
      if (
        type === "FACEBOOK_PAGE" &&
        facebookUsername !== "-" &&
        typeof facebook !== "string"
      )
        return (
          <View style={[styles.columnContainer, { gap: 4 }]}>
            <View
              style={[
                styles.rowContainer,
                { paddingVertical: 0, minHeight: 0 },
              ]}
            >
              <RIcon name="FacebookCircle" />
              <Text
                style={[styles.boldBody1Text, { width: "70%" }]}
                numberOfLines={1}
              >
                {facebookUsername}
              </Text>
            </View>
            <TagAndFollower
              social={facebook}
              follower={facebookFollowers?.toString()}
            />
          </View>
        );
    }
    if (key === "tiktokUsername" && value !== "-") {
      const { tiktok, tiktokFollowers } = data;
      return (
        <View style={[styles.columnContainer, { gap: 4 }]}>
          <View
            style={[styles.rowContainer, { paddingVertical: 0, minHeight: 0 }]}
          >
            <RIcon name="TiktokCircle" />
            <Text
              style={[styles.boldBody1Text, { width: "70%" }]}
              numberOfLines={1}
            >
              {" "}
              {value}
            </Text>
          </View>
          <TagAndFollower
            social={tiktok as unknown as ITiktokInfluencer}
            follower={tiktokFollowers?.toString()}
          />
        </View>
      );
    }
    if (key === "instagramUsername" && value !== "-") {
      const { instagram, instagramFollowers, activeFollowers } = data;
      return (
        <View style={[styles.columnContainer, { gap: 4 }]}>
          <View
            style={[styles.rowContainer, { paddingVertical: 0, minHeight: 0 }]}
          >
            <RIcon name="IGCircle" />
            <Text
              style={[styles.boldBody1Text, { width: "70%" }]}
              numberOfLines={1}
            >
              {" "}
              {value}
            </Text>
          </View>
          <TagAndFollower
            social={instagram as unknown as IInstagramInfluencer}
            follower={instagramFollowers?.toString()}
            activeFollower={activeFollowers?.toString()}
          />
        </View>
      );
    }
    if (key === "select") {
      let idKey: string = "";
      const { brandId, id: influencerId } = data;
      if (!!influencerId) {
        idKey = "id";
      } else {
        idKey = "brandId";
      }
      return (
        <View>
          <RCheckbox
            checked={
              selectedList.findIndex(
                (id) => id === brandId || id === influencerId
              ) !== -1
            }
            toggleCheck={() =>
              onPressRadio(
                data[idKey].toString(),
                data["isVerified"] as unknown as boolean
              )
            }
          />
        </View>
      );
    }
    if (key === "instagram" || key === "tiktok") {
      if (SOCIAL_PATTERN.test(stringValue)) {
        if (HTTP_PATTERN.test(stringValue)) {
          return (
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(stringValue);
              }}
            >
              <Text style={styles.boldBody1Text} numberOfLines={1}>
                {value}
              </Text>
            </TouchableOpacity>
          );
        } else {
          return (
            <TouchableOpacity
              onPress={() => {
                Linking.openURL("https://" + value);
              }}
            >
              <Text style={styles.boldBody1Text} numberOfLines={1}>
                {value}
              </Text>
            </TouchableOpacity>
          );
        }
      } else {
        return (
          <Text style={styles.boldBody1Text} numberOfLines={1}>
            {value}
          </Text>
        );
      }
    }
    if (key === "bannable") {
      return (
        <TouchableOpacity
          hitSlop={3}
          onPress={(e) => {
            if (banId === "") {
              const isBanned = data?.isBanned as unknown as { status: boolean };
              setBanId(data.id.toString());
              setBanStatus(isBanned.status);
              setPositionX(e.nativeEvent.pageX);
              setPositionY(e.nativeEvent.pageY);
            } else setBanId("");
          }}
        >
          <RIcon name="More" />
        </TouchableOpacity>
      );
    }
    return (
      <Text style={styles.body1Text} numberOfLines={1}>
        {value}
      </Text>
    );
  };

  const renderColor = () => {
    if (color !== COLOR.WHITE) {
      return color;
    }
    if (hover) {
      return COLOR.OFFWHITE;
    }
    return COLOR.WHITE;
  };

  const renderData = () => {
    if (title.length > 0) {
      return (
        <View
          style={[
            styles.rowContainer,
            {
              backgroundColor: color || COLOR.WHITE,
              height: 40,
              minHeight: 40,
              ...SHADOW.BLACK5,
              zIndex: 99,
            },
            containerStyle,
          ]}
        >
          {title.map((key) => {
            const data: Record<string, string> = {};
            data[key.display] = key.display;
            return (
              <View
                key={key.display}
                style={[styles.cellContainer, { flex: key.size }]}
              >
                {cellDecoration(key.display, data)}
              </View>
            );
          })}
        </View>
      );
    } else {
      return (
        <Pressable
          onPress={onPress}
          disabled={!onPress}
          onHoverIn={() => setHover(true)}
          onHoverOut={() => setHover(false)}
          style={[{ position: "relative" }]}
        >
          <View
            style={[
              styles.rowContainer,
              {
                backgroundColor: renderColor(),
              },
              containerStyle,
            ]}
          >
            {keys.map((key) => {
              if (ignored?.includes(key.value)) return null;

              return (
                <View
                  key={key.value}
                  style={[styles.cellContainer, { flex: key.size }, cellStyle]}
                >
                  {cellDecoration(key.value, data)}
                </View>
              );
            })}
          </View>
        </Pressable>
      );
    }
  };

  return renderData();
};

export default ListItem;

const styles = StyleSheet.create({
  centerRowContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  rowContainer: {
    padding: 10,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 80,
  },
  columnContainer: {
    flexDirection: "column",
    gap: 16,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  cellContainer: {
    minWidth: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  smallEmailStatusContainer: {
    position: "absolute",
    width: 21,
    height: 21,
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.WHITE,
    borderWidth: 3,
    bottom: 0,
    right: 0,
    borderRadius: 28,
    alignItems: "center",
    justifyContent: "center",
  },
  banStatusContainer: {
    position: "absolute",
    width: 21,
    height: 21,
    backgroundColor: COLOR.PINK,
    borderColor: COLOR.WHITE,
    borderWidth: 2,
    bottom: 0,
    right: 0,
    borderRadius: 28,
    alignItems: "center",
    justifyContent: "center",
  },
  body1Text: {
    ...TEXT.body1,
    overflow: "hidden",
    maxWidth: 150,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
    overflow: "hidden",
    maxWidth: 150,
  },
  boldCaption1Text: {
    ...TEXT.caption1BOLD,
    overflow: "hidden",
  },
});
