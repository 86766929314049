import { observer } from "mobx-react";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { useNavigate } from "react-router-dom";
import Text from "../components/Text";
import RButton from "../components/reusable/RButton";
import RInput from "../components/reusable/RInput";
import { useAuthStore } from "../stores/storeContexts";
import { COLOR, SHADOW } from "../styles/Colors";
import { TEXT } from "../styles/Text";
import RIcon from "../components/reusable/RIcon";

const LoginScreen = () => {
  const { login } = useAuthStore();
  const navigation = useNavigate();
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loginFail, setLoginFail] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const onLogin = async () => {
    setLoginFail(false);
    const loginResult = await login(username, password);
    if (loginResult) {
      navigation("/platform");
    }
    setLoginFail(true);
  };

  const onPressPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <View style={styles.container}>
      <View style={styles.cardContainer}>
        <Text style={styles.boldHeader4Text}>เข้าสู่ระบบ</Text>
        <View style={styles.rowContainer}>
          <View style={styles.iconContainer}>
            <RIcon name="Profile" />
          </View>
          <Text style={styles.boldBody1Text}>Username</Text>
        </View>
        <RInput
          type="normal"
          textProps={{
            value: username,
            onChangeText: (text) => {
              setUsername(text);
            },
          }}
          containerStyle={{
            marginBottom: 32,
          }}
        />
        <View style={styles.rowContainer}>
          <View style={styles.iconContainer}>
            <RIcon name="Lock" />
          </View>
          <Text style={styles.boldBody1Text}>Password</Text>
        </View>
        <RInput
          type="normal"
          textProps={{
            value: password,
            onChangeText: (text) => {
              setPassword(text);
            },
            onSubmitEditing: onLogin,
          }}
          containerStyle={{
            marginBottom: 32,
          }}
          passwordVisible={passwordVisible}
          setPasswordVisible={onPressPassword}
        />
        <RButton
          title="เข้าสู่ระบบ"
          onPress={onLogin}
          disabled={username === "" && password === ""}
          containerStyle={{
            width: 136,
            alignSelf: "flex-end",
          }}
        />
        {loginFail && (
          <Text style={{ ...TEXT.caption1, color: COLOR.PINK }}>
            Your username or password is incorrect
          </Text>
        )}
      </View>
    </View>
  );
};

export default observer(LoginScreen);

const styles = StyleSheet.create({
  container: { flex: 1, alignItems: "center", backgroundColor: COLOR.WHITE },
  cardContainer: {
    display: "flex",
    width: 565,
    height: 592,
    paddingVertical: 50,
    paddingHorizontal: 88,
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 24,
    backgroundColor: COLOR.WHITE,
    marginTop: 77,
    marginLeft: -229,
    ...SHADOW.BLACK5,
  },
  iconContainer: {
    marginRight: 8,
  },
  rowContainer: {
    flexDirection: "row",
  },
  boldHeader4Text: {
    ...TEXT.header4BOLD,
    marginBottom: 24,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
    marginTop: 2,
    marginBottom: 8,
  },
  darkGreyText: {
    ...TEXT.body1,
    color: COLOR.DARKGRAY,
    marginBottom: 32,
  },
});
