import { makeAutoObservable } from "mobx";

export type BrandAccount = {
  brandId: string;
  img: string;
  isEmailVerified: false;
  name: string;
  category: string;
  dealCount: number;
  negoDealCount: number;
  createdAt: string;
};

export class BrandStore {
  constructor() {
    makeAutoObservable(this);
  }

  brand: BrandAccount = {
    brandId: "",
    img: "-",
    isEmailVerified: false,
    name: "",
    category: "",
    dealCount: 0,
    negoDealCount: 0,
    createdAt: "01/01/1970",
  };

  setBrand = (newBrand: BrandAccount) => {
    this.brand = newBrand;
  };
}
export const brandStore = new BrandStore();
