import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import RButton from "../reusable/RButton";
import { ENVIRONMENT } from "@env";

interface Props {
  onPress: () => void;
  active: boolean;
}

const SubmitNotification = (props: Props) => {
  const { onPress, active } = props;

  const renderWarningText = () => {
    if (ENVIRONMENT === "production") {
      return (
        <Text style={[styles.boldHeader5Text, { color: COLOR.PINK }]}>
          You are on Production
        </Text>
      );
    }
    return (
      <Text style={[styles.boldHeader5Text, { color: COLOR.DARKGREEN }]}>
        You are on Staging
      </Text>
    );
  };

  return (
    <View style={styles.container}>
      <RButton
        title="เผยแพร่"
        icon="Check"
        iconPosition="front"
        onPress={onPress}
        textStyle={[TEXT.buttonBOLD, { color: COLOR.WHITE }]}
        containerStyle={{ width: 150, height: 48 }}
        disabled={!active}
      />
      {renderWarningText()}
    </View>
  );
};

export default SubmitNotification;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    gap: 24,
    alignItems: "center",
  },
  buttonBoldText: {
    ...TEXT.buttonBOLD,
    color: COLOR.WHITE,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
  },
});
