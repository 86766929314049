import { View, StyleSheet, Text, TouchableOpacity, Image } from "react-native";
import React from "react";
import { COLOR } from "../../styles/Colors";
import { Notification } from "../types/Notification";
import RXModal from "../reusable/RXModal/RXModal";
import { TEXT } from "../../styles/Text";
import RIcon from "../reusable/RIcon";
import RButton from "../reusable/RButton";

interface Props {
  notification: Notification;
  activeModal: boolean;
  setActiveModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSend: () => void;
  type: "brand" | "influencer";
}

const PreviewModal = (props: Props) => {
  const { notification, activeModal, setActiveModal, type, onSend } = props;

  const renderPhoneIcon = () => {
    if (type === "brand") {
      return (
        <Image
          source={require("../../assets/pickle-brand.png")}
          style={{ width: 48, height: 48 }}
        />
      );
    } else {
      return (
        <Image
          source={require("../../assets/pickle-influencer.png")}
          style={{ width: 48, height: 48 }}
        />
      );
    }
  };

  const renderAppIcon = () => {
    if (type === "brand") {
      return (
        <Image
          source={require("../../assets/pickle-brand.png")}
          style={{ width: 48, height: 48, borderRadius: 24 }}
        />
      );
    } else {
      return (
        <Image
          source={require("../../assets/pickle-influencer.png")}
          style={{ width: 48, height: 48, borderRadius: 24 }}
        />
      );
    }
  };

  return (
    <RXModal visible={activeModal} onClose={() => setActiveModal(false)}>
      <View style={styles.modalContainer}>
        <View style={styles.headerContainer}>
          <Text style={styles.boldHeader5Text}>ดูตัวอย่าง</Text>
          <TouchableOpacity
            style={styles.iconContainer}
            onPress={() => setActiveModal(false)}
          >
            <RIcon name="CloseBlack" />
          </TouchableOpacity>
        </View>
        <View style={styles.contextContainer}>
          <Text style={styles.boldBody1Text}>In-Phone Notification</Text>
          <View
            style={[
              styles.notificationContainer,
              { justifyContent: "space-between" },
            ]}
          >
            <View style={{ flexDirection: "row", gap: 16 }}>
              {renderPhoneIcon()}
              <View style={styles.columnContainer}>
                <Text style={styles.boldBody1Text} numberOfLines={1}>
                  {notification.title}
                </Text>
                <Text style={styles.body1Text} numberOfLines={1}>
                  {notification.body}
                </Text>
              </View>
            </View>
            <Text style={[styles.body1Text, { color: COLOR.DARKGRAY }]}>
              now
            </Text>
          </View>
          <Text style={styles.boldBody1Text}>In-App Notification</Text>
          <View style={styles.notificationContainer}>
            {renderAppIcon()}
            <View style={styles.columnContainer}>
              <Text style={styles.boldBody1Text} numberOfLines={1}>
                {notification.title}
              </Text>
              <Text style={styles.body1Text} numberOfLines={1}>
                {notification.body}
              </Text>
              <Text style={styles.body2Text}>01 January 2023 12.00</Text>
            </View>
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <RButton
            title="แก้ไข"
            icon="Edit"
            onPress={() => setActiveModal(false)}
            containerStyle={{
              width: 150,
              borderColor: COLOR.GREEN,
              borderWidth: 1,
              backgroundColor: COLOR.WHITE,
            }}
            textStyle={{ color: COLOR.DARKGREEN, marginLeft: 4 }}
          />
          <RButton
            title="ยืนยัน"
            icon="Check"
            onPress={() => {
              onSend();
              setActiveModal(false);
            }}
            containerStyle={{ width: 150 }}
            textStyle={{ marginLeft: 4 }}
          />
        </View>
      </View>
    </RXModal>
  );
};

export default PreviewModal;

const styles = StyleSheet.create({
  modalContainer: {
    width: 612,
    borderRadius: 16,
    backgroundColor: COLOR.WHITE,
    flexDirection: "column",
    alignItems: "center",
  },
  headerContainer: {
    borderBottomColor: COLOR.LIGHTGRAY,
    borderBottomWidth: 1,
    padding: 27,
    alignItems: "center",
    width: "100%",
  },
  iconContainer: {
    position: "absolute",
    top: 27,
    right: 27,
  },
  contextContainer: {
    margin: 24,
    flexDirection: "column",
    gap: 10,
  },
  notificationContainer: {
    width: 421,
    padding: 16,
    borderWidth: 1,
    borderColor: COLOR.LIGHTGRAY,
    borderRadius: 2,
    flexDirection: "row",
    gap: 16,
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 36,
    marginBottom: 32,
  },
  columnContainer: {
    flexDirection: "column",
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
    maxWidth: 270,
    overflow: "hidden",
  },
  body1Text: {
    ...TEXT.body1,
    maxWidth: 270,
    overflow: "hidden",
  },
  body2Text: {
    ...TEXT.body2,
    color: COLOR.DARKGRAY,
  },
});
