import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { Calendar as RNCalendar } from "react-native-calendars";
import { MarkedDates } from "react-native-calendars/src/types";
import { COLOR } from "../../styles/Colors";
import { getDaysArray, minusHours } from "../Util";
import RXModal from "../reusable/RXModal/RXModal";
import RIcon from "../reusable/RIcon";
import RButton from "../reusable/RButton";

interface Props {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  date: Array<Date>;
  setDate: React.Dispatch<React.SetStateAction<Array<Date>>>;
}

const Calendar = (props: Props) => {
  const { active, setActive, date, setDate } = props;

  const [calendarStartDate, setCalendarStartDate] = useState<Date>(new Date());
  const [calendarDueDate, setCalendarDueDate] = useState<Date | null>(null);

  const markedDate = () => {
    const marked: MarkedDates = {};
    const markedStartDate = minusHours(
      new Date(calendarStartDate).toISOString(),
      -7
    ).split("T")[0];

    const days = getDaysArray(
      new Date(markedStartDate || calendarDueDate || new Date()),
      new Date(calendarDueDate || calendarStartDate)
    );
    marked[minusHours(new Date().toISOString(), -7).split("T")[0]] = {
      textColor: COLOR.BLACK,
      dotColor: COLOR.GREEN,
      marked: true,
    };
    if (days.length <= 1) {
      const d = days[0].toISOString().split("T")[0];
      marked[d] = {
        startingDay: true,
        endingDay: true,
        color: COLOR.GREEN,
        textColor: COLOR.WHITE,
      };
    } else {
      days.forEach((day, index) => {
        const d = day.toISOString().split("T")[0];
        if (index === 0) {
          marked[d] = {
            startingDay: true,
            color: COLOR.GREEN,
            textColor: COLOR.WHITE,
          };
        } else if (index === days.length - 1) {
          marked[d] = {
            endingDay: true,
            color: COLOR.GREEN,
            textColor: COLOR.WHITE,
          };
        } else {
          marked[d] = { color: COLOR.LIGHTGREEN, textColor: COLOR.BLACK };
        }
      });
    }
    return marked;
  };

  const onDayPress = ({ timestamp }: { timestamp: any }) => {
    if (calendarStartDate && calendarDueDate) {
      setCalendarDueDate(null);
      setCalendarStartDate(new Date(timestamp));
      return;
    }
    if (timestamp < new Date(calendarStartDate)) {
      setCalendarDueDate(null);
      setCalendarStartDate(new Date(timestamp));
      return;
    }
    setCalendarDueDate(new Date(timestamp));
  };

  const onClear = () => {
    const tempDate = [...date];
    tempDate.splice(0, tempDate.length);
    setDate(tempDate);
    setActive(false);
  };

  const onSubmit = () => {
    const tempDate = [...date];
    tempDate[0] = calendarStartDate;
    if (calendarDueDate) tempDate[1] = calendarDueDate;
    setDate(tempDate);
    setActive(false);
  };

  const ArrowComponent = ({ direction }: { direction: string }) => {
    return (
      <View
        style={{
          backgroundColor: COLOR.WHITE,
          justifyContent: "center",
          alignItems: "center",
          height: 50,
          width: 50,
        }}
      >
        {direction === "left" ? (
          <RIcon name="ArrowShortLeftGreen" />
        ) : (
          <RIcon name="ArrowShortRightGreen" />
        )}
      </View>
    );
  };

  useEffect(() => {
    if (active) {
      setCalendarStartDate(new Date());
      setCalendarDueDate(null);
    }
  }, [active]);

  return (
    <RXModal visible={active} onClose={() => setActive(false)}>
      <View style={styles.container}>
        <RNCalendar
          markingType="period"
          markedDates={markedDate()}
          onDayPress={(date) => onDayPress(date)}
          renderArrow={(direction) => <ArrowComponent direction={direction} />}
          style={styles.modalContainer}
        />
        <View style={styles.rowContainer}>
          <RButton
            onPress={onClear}
            title="ค่าเริ่มต้น"
            containerStyle={[
              styles.buttonContainer,
              { backgroundColor: COLOR.PINK },
            ]}
          />
          <RButton
            onPress={onSubmit}
            title="ยืนยัน"
            containerStyle={styles.buttonContainer}
            disabled={!calendarStartDate}
          />
        </View>
      </View>
    </RXModal>
  );
};

export default Calendar;

const styles = StyleSheet.create({
  container: {
    width: 385,
    height: 472,
    borderRadius: 16,
    paddingHorizontal: 24,
    paddingBottom: 24,
    flexDirection: "column",
    gap: 12,
    backgroundColor: COLOR.WHITE,
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalContainer: {
    width: 337,
    height: 390,
  },
  buttonContainer: {
    width: 121,
  },
});
