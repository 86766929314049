import axios, { AxiosRequestConfig } from "axios";
import { getAccessToken } from "../stores/authStore";
import {
  ENVIRONMENT,
  STAGING_API_URL,
  PRODUCTION_API_URL,
  LOCAL_API_URL,
} from "@env";

let API_URL: string = "";

export const environment =
  ENVIRONMENT === "production" ? "production" : "staging";
console.log("You are in", environment);

switch (ENVIRONMENT) {
  case "staging":
    API_URL = STAGING_API_URL;
    break;
  case "production":
    API_URL = PRODUCTION_API_URL;
    break;
  default:
    API_URL = LOCAL_API_URL;
    break;
}

const headers = async (cfg: AxiosRequestConfig["headers"] | null = null) => {
  return {
    Authorization: `Bearer ${await getAccessToken()}`,
    ...cfg,
  };
};

export const pickleGET = async <T>(url: string) => {
  const response = await axios.get<T>(`${API_URL}${url}`, {
    headers: await headers(),
  });
  return response.data;
};

export const picklePOST = async <T>(
  url: string,
  body: Record<string, any>,
  cfg: AxiosRequestConfig["headers"] | null = null
) => {
  const response = await axios.post<T>(`${API_URL}${url}`, body, {
    headers: await headers(cfg),
  });
  return response.data;
};
