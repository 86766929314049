import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import {} from "rn-rx-icons";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { PLACEHOLDER_IMAGE, difTime } from "../Util";
import RIcon from "../reusable/RIcon";

interface Props {
  isIncognito?: boolean;
  rating: number;
  tag: Array<string | undefined>;
  workImages: Array<string | undefined>;
  brandImageUri: string;
  brandTitle: string;
  dayPast: string;
  comment: string;
  isLast?: boolean;
}

const InfluencerReviewPreview = (props: Props) => {
  const {
    isIncognito = false,
    rating = 0,
    tag,
    workImages,
    brandImageUri = "",
    brandTitle,
    dayPast,
    comment,
  } = props;

  const renderWorkImages = () => {
    return (
      <View style={styles.workImagesContainer}>
        {workImages.map((image) => {
          if (image) {
            return <Image style={styles.image} source={{ uri: image }} />;
          }
        })}
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View>
        <View style={{ flexDirection: "row" }}>
          {!isIncognito && (
            <Image
              source={{ uri: brandImageUri }}
              style={{
                width: 35,
                height: 35,
                borderRadius: 20,
                borderColor: COLOR.GREEN,
                borderWidth: 1.5,
              }}
            />
          )}
          {isIncognito && (
            <Image
              source={{ uri: PLACEHOLDER_IMAGE }}
              style={{
                width: 35,
                height: 35,
                borderRadius: 20,
                borderColor: COLOR.GREEN,
                borderWidth: 1.5,
              }}
            />
          )}
          <View style={{ flex: 1, marginLeft: 11 }}>
            <Text
              style={[
                TEXT.body1BOLD,
                { maxWidth: "60%", marginLeft: 1, marginBottom: -3 },
              ]}
              numberOfLines={1}
            >
              {isIncognito ? `${brandTitle.slice(0, 3)}*******` : brandTitle}
            </Text>
            <View style={{ flex: 1, flexDirection: "row" }}>
              {new Array(5).fill("0").map((a, index) => {
                if (index < Math.floor(rating)) {
                  return (
                    <RIcon key={`star${index}`} name="StarFill" size={12} />
                  );
                }
                return <RIcon key={`star${index}`} name="Star" size={12} />;
              })}
            </View>
          </View>
          <View style={{ marginLeft: "auto" }}>
            <Text style={[TEXT.caption1, { color: COLOR.DARKGRAY }]}>
              {difTime(dayPast, true, true)} ago
            </Text>
          </View>
        </View>
        {!!tag.length && (
          <View style={styles.tagContainer}>
            {tag.map((reviewTag, index) => {
              return (
                <View style={styles.reviewTag} key={reviewTag || "" + index}>
                  <Text style={{ ...TEXT.body1, color: COLOR.DARKGREEN }}>
                    {reviewTag}
                  </Text>
                </View>
              );
            })}
          </View>
        )}
        {comment.length !== 0 && (
          <Text style={[TEXT.body1, { marginTop: 10 }]} numberOfLines={1}>
            {comment}
          </Text>
        )}
      </View>
      {workImages.length > 0 && renderWorkImages()}
    </View>
  );
};

export default InfluencerReviewPreview;

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    borderBottomColor: COLOR.LIGHTGRAY,
    borderBottomWidth: 1,
    borderStyle: "dashed",
  },
  tagContainer: {
    flex: 1,
    flexWrap: "wrap",
    flexDirection: "row",
    gap: 8,
    marginTop: 16,
  },
  workImagesContainer: { flexDirection: "row", gap: 16, marginTop: 24 },
  reviewTag: {
    alignSelf: "baseline",
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: COLOR.LIGHTGREEN2,
    borderRadius: 50,
  },
  image: {
    width: 120,
    height: 120,
    borderRadius: 16,
  },
});
