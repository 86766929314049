export type IPicture = {
  data: {
    height: number;
    is_silhouette: boolean;
    url: string;
    width: number;
  };
};
export interface IBasicInfluencerAccount {
  picture?: IPicture;
  img?: string;
  id: string;
  email: string;
  name: string;
  contactEmail?: string;
  contactName?: string;
  type: "apple" | "google" | "facebook";
  access_token?: string;
}

export interface IInfluencerAccount extends IBasicInfluencerAccount {
  platform: string;
  displayName: string;
  grantedPermissions: string[];
  declinedPermissions: string[];
  data_access_expires_at: Date;
  instagramId: string | null;
  tiktokId: string | null;
  lemonUrl: string | null;
  dealContacts: string[];
  agreed: boolean;
  contactTelephone: string;
  contactTel: string;
  deliveryPhone: string;
  addressRecipient: string;
  address: string;
  bank: string;
  bankNumber: string;
  bankOwnerName: string;
  interests: string[];
  expoToken: string[];
  profileImages: string[];
  reSignedIn: boolean;
  feedConsent: boolean;
  birthdate: Date;
  gender: string;
  bodySize: {
    weight: string;
    height: string;
    bust: string;
    waist: string;
    hip: string;
    footwear: string;
  };
  province: string;
  introduce: string;
  isBanned: {
    status: boolean;
    reason: string[];
    bannedAt: Date;
  };
  createdAt: Date;
  updatedAt: Date;
  instagram?: IInstagramInfluencer;
  profile_picture_url?: string;
  tiktok?: ITiktokInfluencer;
  isVerified?: boolean;
  instagramUsername: string;
  tiktokUsername: string;
  followers_count: number;

  pendingCount: number;
  ongoingCount: number;
  checkCount: number;
  doneCount: number;
  cancelledCount: number;
  rejectedCount: number;
}

export const initialInfluencerAccount: IInfluencerAccount = {
  platform: "",
  displayName: "",
  grantedPermissions: [],
  declinedPermissions: [],
  instagramId: null,
  tiktokId: null,
  lemonUrl: null,
  dealContacts: [],
  agreed: false,
  contactTelephone: "",
  contactTel: "",
  deliveryPhone: "",
  addressRecipient: "",
  address: "",
  bank: "",
  bankNumber: "",
  bankOwnerName: "",
  interests: [],
  expoToken: [],
  profileImages: [],
  reSignedIn: false,
  feedConsent: false,
  birthdate: new Date(),
  gender: "",
  bodySize: {
    weight: "",
    height: "",
    bust: "",
    waist: "",
    hip: "",
    footwear: "",
  },
  province: "",
  introduce: "",
  isBanned: {
    status: false,
    reason: [],
    bannedAt: new Date(),
  },
  id: "",
  email: "",
  name: "",
  type: "apple",
  data_access_expires_at: new Date(),
  isVerified: false,
  instagramUsername: "",
  tiktokUsername: "",
  followers_count: 0,
  pendingCount: 0,
  ongoingCount: 0,
  checkCount: 0,
  doneCount: 0,
  cancelledCount: 0,
  rejectedCount: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export interface IInstagramInfluencer {
  id: string;
  username: string;
  page: string;
  followers_count: number;
  isVerified: boolean;
  activeFollowers: number;
  locations: ILocation[];
  ages: IAge[];
  genders: IGender[];
  recentMedia: IInstagramMedia[];
  recentMediaLikes: number;
  recentMediaEngagement: number;
  profile_picture_url: string;
  insights: {
    months: IMonthInsights[];
  };
  storyInsights?: IInstagramStoryInsights;
  reviews: IInfluencerReview[];
  reviewOverall: {
    avgRating: number;
    totalReview: number;
    tagCount?: number[];
  };
  profileReaches?: number;
  profileImpressions?: number;
  type: "INSTAGRAM_BUSINESS";
}

//instagram
export interface IInstagramStoryInsights {
  avgStoryReach?: number;
  avgStoryImpressions?: number;
}

export interface IInstagramMedia {
  caption: string;
  children?: {
    data: AlbumImage[];
  };
  comment_count: number;
  like_count: number;
  media_type: TMediaType;
  media_url: string;
  permalink: string;
  thumbnail_url?: string;
  timestamp: string;
  username: string;
}

interface AlbumImage {
  id: string;
  permalink: string;
  media_url?: string;
  thumbnail_url?: string;
  media_type: "IMAGE" | "VIDEO";
}

export interface IInfluencerReview {
  _id: string;
  doneAt: string;
  review: { rate: number; commend: string; reviewTag?: number[] };
  brand: { name: string; img: string };
  media: {
    children?: {
      data: AlbumImage[];
    };
    media_type?: string;
    media_url?: string;
    thumbnail_url?: string;
  }[];
  isIncognito?: boolean;
}

export interface IMonthInsights {
  id: string;
  name: "reach" | "impressions";
  period: string;
  values: Record<number, IInsightValue>;
  title: string;
}

export interface IInsightValue {
  value: number;
  end_time: string;
}

export interface ILocation {
  0: string;
  1: number;
}

export interface IAge {
  0: "18-24" | "25-34" | "35-44" | "45-54" | "55-64" | "65+";
  1: number;
}

export interface IGender {
  0: "F" | "M" | "U";
  1: number;
}

export type TMediaType = "IMAGE" | "CAROUSEL_ALBUM" | "VIDEO";

//tiktok
export interface ITiktokInfluencer {
  id: string;
  followers_count?: number;
  username: string;
  isVerified: boolean;
  recentMedia: ITiktokVideoInfo[];
  recentMediaLikes: number;
  recentMediaViews: number;
  recentMediaShares: number;
  recentMediaComments: number;
  profile_picture_url: string;
  insights?: Record<number, IMonthInsights>;
  type: "TIKTOK";
}

export interface ITiktokVideoInfo {
  id: string;
  title: string;
  cover_image_url: string;
  duration: number;
  height: number;
  width: number;
  embed_html: string;
  embed_link: string;
  like_count: number;
  comment_count: number;
  share_count: number;
  view_count: number;
  video_description: string;
  share_url: string;
  create_time: number;
}

export interface IFacebookInfluencer {
  id: string;
  username: string;
  profile_picture_url: string;
  followers_count: number;
  profileReaches: number;
  profileImpressions: number;
  recentMediaEngagement: number;
  recentMediaLikes: number;
  recentMediaComments: number;
  recentMediaShares: number;
  mediaCalculationCount: number;
  locations: Array<ILocation>;
  ages: Array<IAge>;
  genders: Array<IGender>;
  isVerified: boolean;
}
export interface ILocation {
  0: string;
  1: number;
}

export interface IAge {
  0: "18-24" | "25-34" | "35-44" | "45-54" | "55-64" | "65+";
  1: number;
}

export interface IGender {
  0: "F" | "M" | "U";
  1: number;
}
