import React from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  Linking,
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { TEXT } from "../../styles/Text";
import { IInstagramMedia } from "../../types/influencerAccount";
import { COLOR } from "../../styles/Colors";
import { PLACEHOLDER_IMAGE } from "../Util";
import RIcon from "../reusable/RIcon";

interface Props {
  medias: Array<IInstagramMedia>;
  username: string;
}

const InstagramRecentMedia = (props: Props) => {
  const { medias, username } = props;

  const renderMedia = () => {
    return medias.map((media) => {
      return (
        <Image
          style={styles.media}
          source={{
            uri: media.media_url || media.thumbnail_url,
          }}
          defaultSource={{ uri: PLACEHOLDER_IMAGE }}
        />
      );
    });
  };

  return (
    <View style={styles.container}>
      <View style={styles.rowContainer}>
        <Text style={styles.boldHeader6Text}>View on</Text>
        <LinearGradient
          colors={["#FEDA75", "#FA7E1E", "#D62976", "#962FBF", "#4F5BD5"]}
          locations={[0, 0.2393, 0.4893, 0.7081, 0.9633]}
          start={{ x: 3.6, y: 4.12 }}
          end={{ x: 4.57, y: 3 }}
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: 128,
            height: 32,
            borderRadius: 16,
          }}
        >
          <TouchableOpacity
            style={[styles.rowContainer, { gap: 4 }]}
            onPress={() => {
              Linking.openURL(`https://instagram.com/${username}`);
            }}
          >
            <RIcon name="Instagram" />
            <Text style={styles.boldBody1Text}>Instagram</Text>
          </TouchableOpacity>
        </LinearGradient>
      </View>
      <View style={styles.mediaContainer}>{renderMedia()}</View>
    </View>
  );
};

export default InstagramRecentMedia;

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 24,
  },
  rowContainer: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  mediaContainer: {
    flexDirection: "row",
    gap: 32,
  },
  media: {
    width: 250,
    height: 250,
    borderRadius: 16,
  },
  boldHeader6Text: {
    ...TEXT.header6BOLD,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
    color: COLOR.WHITE,
  },
});
