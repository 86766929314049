import React from "react";
import {
  ActivityIndicator,
  StyleProp,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import { BUTTON } from "../../styles/Button";
import { COLOR } from "../../styles/Colors";
import RIcon, { PNGIcon } from "./RIcon";

interface Props {
  title: string;
  onPress: () => void;
  loading?: boolean;
  disabled?: boolean;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  success?: {
    backgroundColor: string;
    textColor: string;
  };
  fail?: {
    backgroundColor: string;
    textColor: string;
  };
  icon?: PNGIcon;
  iconPosition?: "front" | "back";
  iconSize?: number;
}

const RButton = (props: Props) => {
  const {
    title,
    onPress,
    loading,
    disabled,
    containerStyle,
    textStyle,
    success = {
      backgroundColor: COLOR.GREEN,
      textColor: COLOR.WHITE,
    },
    fail = {
      backgroundColor: COLOR.LIGHTGRAY,
      textColor: COLOR.DARKGRAY,
    },
    icon,
    iconPosition,
    iconSize = 24,
  } = props;

  const renderTitle = () => {
    if (!icon)
      return (
        <Text
          style={[
            {
              ...BUTTON.PRIMARY.text,
              color: disabled ? fail.textColor : success.textColor,
            },
            textStyle,
          ]}
        >
          {title}
        </Text>
      );
    if (icon && iconPosition === "back") {
      return (
        <>
          <Text
            style={[
              {
                ...BUTTON.PRIMARY.text,
                color: disabled ? fail.textColor : success.textColor,
              },
              textStyle,
            ]}
          >
            {title}
          </Text>
          <RIcon name={icon} size={iconSize} />
        </>
      );
    } else {
      return (
        <>
          <RIcon name={icon} size={iconSize} />
          <Text
            style={[
              {
                ...BUTTON.PRIMARY.text,
                color: disabled ? fail.textColor : success.textColor,
              },
              textStyle,
            ]}
          >
            {title}
          </Text>
        </>
      );
    }
  };
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        {
          ...BUTTON.PRIMARY.button,
          backgroundColor: disabled
            ? fail.backgroundColor
            : success.backgroundColor,
          width: 300,
          maxWidth: "80%",
          height: 50,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        },
        // SHADOW.BLACK10,
        containerStyle,
      ]}
      disabled={loading || disabled}
    >
      {loading ? <ActivityIndicator color={COLOR.WHITE} /> : renderTitle()}
    </TouchableOpacity>
  );
};

export default RButton;
