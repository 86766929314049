export const COLOR = {
  BLACK: "#191414",
  DARKGRAY: "#999999",
  GRAY: "#CBCBCB",
  LIGHTGRAY: "#EAEAEA",
  WHITE: "#FFFFFF",
  OFFWHITE: "#F8F8F8",

  DARKGREEN: "#008F6B",
  GREEN: "#00D0AA",
  LIGHTGREEN: "#A9F6E3",
  LIGHTGREEN2: "#DDFFF7",

  DARKPINK: "#B23955",
  PINK: "#FF527A",
  LIGHTPINK: "#FFEBF0",
  LIGHTPINK2: "#FDDEE6",

  DARKYELLOW: "#D29101",
  YELLOW: "#FFC648",
  LIGHTYELLOW: "#FFE7B2",
  LIGHTYELLOW2: "#FFEFCC",
  LEMON: "#FFF700",

  BLUE: "#56CCF2",

  ORANGE: "#F2994A",
  LIGHTORANGE: "#FFE5CE",
};

export const SHADOW = {
  NONE: {
    shadowColor: COLOR.BLACK,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
  },
  BLACK5: {
    shadowColor: COLOR.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.05,
    shadowRadius: 10,
    elevation: 10,
  },
  BLACK10: {
    shadowColor: COLOR.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    elevation: 10,
  },
  GREEN20: {
    shadowColor: "rgba(0,208,170)",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 4,
  },
};
