import { makeAutoObservable } from "mobx";

export type StackType = {
  display: string;
  path: string;
};

export class NavigationStore {
  constructor() {
    makeAutoObservable(this);
  }

  navigationStack: Array<StackType> = [];

  pushStack = (stack: StackType) => {
    this.navigationStack.push(stack);
  };

  popStack = () => {
    this.navigationStack.pop();
  };

  spliceStack = (stackPath: string) => {
    const length = this.navigationStack.length;
    const index =
      this.navigationStack.findIndex((stack) => stack.path === stackPath) + 1;
    const spliceCount = length - index;
    this.navigationStack.splice(index, spliceCount);
  };

  clearStack = () => {
    this.navigationStack = [];
  };
}

export const navigationStore = new NavigationStore();
