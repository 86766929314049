import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { WINDOW_HEIGHT } from "../Util";
import RDropDown from "../reusable/DropdownPicker";
import RButton from "../reusable/RButton";
import RXModal from "../reusable/RXModal/RXModal";

const RejectModal = (props: {
  visible: boolean;
  value: string;
  onClose: () => void;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  onReject: () => void;
}) => {
  const { visible, value, onChange, onClose, onReject } = props;
  const [open, setOpen] = useState<boolean>(false);
  return (
    <RXModal transparent={true} visible={visible} onClose={onClose}>
      <View style={styles.rejectContainer}>
        <Text style={styles.boldHeader5Text}>ปฏิเสธบัญชีอินฟลูเอนเซอร์</Text>
        <Text style={styles.body1Text}>
          ยืนยันปฏิเสธบัญชีอินฟลูเอนเซอร์ที่เลือก
          <br />
          สถานะอินฟลูเอนเซอร์จะเปลี่ยนเป็น “Reject”
        </Text>
        <RDropDown
          options={[
            {
              label: "ยอด follower ไม่ถึง 3000",
              value: "ยอด follower ไม่ถึง 3000",
            },
            {
              label: "ยอด active follower ไม่ถึง 5%",
              value: "ยอด active follower ไม่ถึง 5%",
            },
            { label: "เป็น account brand", value: "เป็น account brand" },
            { label: "อื่นๆ", value: "อื่นๆ" },
          ]}
          placeHolder={"Reasoning"}
          value={value}
          onValueChange={(value) => {
            onChange(value as string);
          }}
          containerStyle={styles.dropdownContainer}
          listItemStyle={styles.listItemContainer}
          open={open}
          setOpen={setOpen}
        />
        <View style={styles.rowContainer}>
          <RButton
            onPress={onClose}
            containerStyle={styles.cancelButtonContainer}
            textStyle={styles.boldButtonText}
            title="ยกเลิก"
          />
          <RButton
            onPress={onReject}
            containerStyle={styles.buttonContainer}
            title="ยืนยัน"
          />
        </View>
      </View>
    </RXModal>
  );
};

export default RejectModal;

const styles = StyleSheet.create({
  scrollViewContainer: {
    height: WINDOW_HEIGHT - 170,
  },
  rowContainer: {
    width: 300,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rejectContainer: {
    padding: 32,
    borderRadius: 24,
    backgroundColor: COLOR.WHITE,
    width: 400,
    alignItems: "center",
    gap: 16,
  },
  dropdownContainer: {
    paddingTop: 12,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 24,
    height: 50,
    width: 300,
    alignSelf: "center",
  },
  cancelButtonContainer: {
    marginTop: 10,
    borderWidth: 1,
    borderColor: COLOR.GREEN,
    backgroundColor: COLOR.WHITE,
    width: 136,
    height: 48,
  },
  buttonContainer: {
    marginTop: 10,
    width: 136,
    height: 48,
  },
  listItemContainer: {
    width: 300,
    alignSelf: "center",
  },
  body1Text: {
    ...TEXT.body1,
    textAlign: "center",
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
  },
  boldButtonText: {
    ...TEXT.buttonBOLD,
    color: COLOR.DARKGREEN,
  },
});
