import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { COLOR, SHADOW } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { WINDOW_WIDTH } from "../Util";
import RIcon from "../reusable/RIcon";
import RInput from "../reusable/RInput";
import Calendar from "./Calendar";

interface Props {
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  date: Array<Date>;
  setDate: React.Dispatch<React.SetStateAction<Array<Date>>>;
}

const Search = (props: Props) => {
  const { setKeyword, date, setDate } = props;
  const [active, setActive] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  return (
    <View style={styles.container}>
      <Text style={styles.body1Text}>ค้นหา</Text>
      <RInput
        textProps={{
          value: searchText,
          onChangeText: (text) => {
            setSearchText(text);
          },
          onSubmitEditing: () => {
            const keyword = searchText.trim().toLowerCase();
            setKeyword(keyword);
          },
        }}
        type={"normal"}
        containerStyle={{ width: WINDOW_WIDTH - 450, minHeight: 40 }}
      />
      <TouchableOpacity onPress={() => setActive(true)}>
        <RIcon name="Filter" />
      </TouchableOpacity>
      <Calendar
        active={active}
        setActive={setActive}
        date={date}
        setDate={setDate}
      />
    </View>
  );
};

export default Search;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 16,
    ...SHADOW.BLACK5,
    zIndex: 999,
    gap: 24,
  },
  searchContainer: {
    borderRadius: 30,
    backgroundColor: COLOR.OFFWHITE,
    paddingHorizontal: 16,
    paddingVertical: 10,
    alignItems: "center",
    justifyContent: "center",
    height: 20,
    flex: 8,
  },
  body1Text: {
    ...TEXT.body1,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
});
