import React from "react";
import { Image, StyleSheet, View, Text } from "react-native";
import { IInfluencerAccount } from "../../types/influencerAccount";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { PLACEHOLDER_IMAGE } from "../Util";

interface Props {
  influencer: IInfluencerAccount;
}

const InfluencerDetailHeader = (props: Props) => {
  const { influencer } = props;

  const renderProfileImage = () => {
    if (!!influencer.img) {
      return influencer.img;
    } else {
      return PLACEHOLDER_IMAGE;
    }
  };
  return (
    <View style={styles.container}>
      <View style={styles.avatarContainer}>
        <Image
          style={styles.avatarContainer}
          source={{ uri: renderProfileImage() }}
        />
      </View>
      <View style={styles.detailContainer}>
        <Text style={styles.boldHeader5Text}>
          {influencer.displayName || "-"}
        </Text>
        <Text style={styles.captionText}>Active 2 hr ago</Text>
        <View style={styles.interestsContainer}>
          {influencer?.interests?.map((interest) => {
            return (
              <View style={styles.interestContainer}>
                <Text style={styles.body2Text}>{interest}</Text>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};

export default InfluencerDetailHeader;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingHorizontal: 32,
    paddingVertical: 24,
    backgroundColor: COLOR.WHITE,
    gap: 24,
  },
  detailContainer: {
    flexDirection: "column",
    gap: 8,
  },
  avatarContainer: {
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: COLOR.WHITE,
  },
  interestsContainer: {
    flexDirection: "row",
    gap: 8,
  },
  interestContainer: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: COLOR.LIGHTGRAY,
    paddingVertical: 2,
    paddingHorizontal: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
  },
  captionText: {
    ...TEXT.caption1,
  },
  boldBody2Text: {
    ...TEXT.body2BOLD,
  },
  body2Text: {
    ...TEXT.body2,
  },
});
