import * as ImagePicker from "expo-image-picker";
import * as MediaLibrary from "expo-media-library";
import {
  getTrackingPermissionsAsync,
  isAvailable,
  requestTrackingPermissionsAsync,
} from "expo-tracking-transparency";
import { makeAutoObservable } from "mobx";
import { Platform } from "react-native";
import LocalStorage from "../utils/LocalStorage";

export const PERMISSIONS = {
  CAMERAROLL: -999,
  TRACKING: -998,
};

export class PermissionStore {
  permissions: any[] = [];

  constructor() {
    makeAutoObservable(this);
    this.getAvailablePermissions().then();
  }

  getAvailablePermissions = async () => {
    const { status: cameraRollStatus } =
      await ImagePicker.getMediaLibraryPermissionsAsync();
    if (cameraRollStatus === "granted") {
      this.permissions.push(PERMISSIONS.CAMERAROLL);
    }
    const { status: trackingStatus } = await getTrackingPermissionsAsync();
    if (trackingStatus === "granted") {
      this.permissions.push(PERMISSIONS.TRACKING);
    }
  };

  requestCameraRollPermission = async () => {
    if (Platform.OS !== "web") {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        console.log(
          "Sorry, we need camera roll permissions to make this work!"
        );
        return false;
      }
      this.permissions.push(PERMISSIONS.CAMERAROLL);
      return true;
    }
    return false;
  };

  requestMediaPermission = async () => {
    if (Platform.OS !== "android") {
      return true;
    }
    return await MediaLibrary.requestPermissionsAsync();
  };

  requestTrackingPermission = async () => {
    if (!isAvailable()) {
      return true;
    }
    const requested = await LocalStorage.getItem("TrackingPermission");
    if (requested === "APPROVED" || requested === "DECLINED") {
      return requested === "APPROVED";
    }
    const { granted } = await requestTrackingPermissionsAsync();
    if (granted) {
      await LocalStorage.setItem("TrackingPermission", "APPROVED");
    } else {
      await LocalStorage.setItem("TrackingPermission", "DECLINED");
    }
    return granted;
  };

  isPermissionAvailable = async (permission: any) => {
    const isGranted = this.permissions.includes(permission);
    if (!isGranted && permission === PERMISSIONS.CAMERAROLL) {
      const cameraPermission = await this.requestCameraRollPermission();
      const mediaPermission = await this.requestMediaPermission();
      return cameraPermission && mediaPermission;
    }
    if (!isGranted && permission === PERMISSIONS.TRACKING) {
      return await this.requestTrackingPermission();
    }
    return true;
  };
}

export const permissionStore = new PermissionStore();
