import React from "react";
import {
  Image,
  Linking,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import {
  HTTP_PATTERN,
  PLACEHOLDER_IMAGE,
  SOCIAL_PATTERN,
  mapCategoryIcon,
} from "../Util";
import RButton from "../reusable/RButton";
import RIcon, { PNGIcon } from "../reusable/RIcon";
import { IInfluencerAccount } from "../../types/influencerAccount";

const InfluencerModal = (props: {
  visible: boolean;
  onClose: () => void;
  data: IInfluencerAccount;
  onApprove: () => Promise<void>;
  onReject: () => Promise<void>;
}) => {
  const { visible, data, onApprove, onReject, onClose } = props;
  const onUnload = () => {
    onClose();
  };

  const renderEmailIcon = () => {
    if (data.isVerified) {
      return (
        <View style={styles.emailStatusContainer}>
          <RIcon name="CheckCircleFill" size={32} />
        </View>
      );
    }
  };

  const renderEmailVerified = () => {
    if (data.isVerified) {
      return (
        <View style={styles.verifiedContainer}>
          <RIcon
            name="CheckCircleFill"
            size={20}
            imageStyle={{ marginRight: 4 }}
          />
          <Text style={styles.greenCaptionText}>Email Verified</Text>
        </View>
      );
    } else {
      return (
        <View style={styles.unVerifiedContainer}>
          <RIcon name="WaitingFill" size={20} imageStyle={{ marginRight: 4 }} />
          <Text style={styles.orangeBody1Text}>Email Unverified</Text>
        </View>
      );
    }
  };

  const renderInformation = (
    information: string,
    icon: PNGIcon,
    onPress: () => void,
    followers?: string
  ) => {
    if (!!information) {
      return (
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <TouchableOpacity
            style={{ flexDirection: "row", gap: 8 }}
            onPress={onPress}
          >
            <RIcon name={icon} />
            <Text style={styles.buttonText}>{information}</Text>
          </TouchableOpacity>
          <Text style={styles.buttonText}>{followers}</Text>
        </View>
      );
    }
  };

  const renderInterests = (interests: Array<string>) => {
    if (!!interests && interests.length > 0) {
      return (
        <Text style={[styles.body1Text, { width: 200 }]}>
          {interests.map((interest: string) => {
            return (
              mapCategoryIcon(interest)?.icon +
              " " +
              mapCategoryIcon(interest)?.value +
              "\n"
            );
          })}
        </Text>
      );
    }
  };

  return (
    <Modal transparent={true} onDismiss={onUnload} visible={visible}>
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <TouchableWithoutFeedback onPress={onUnload}>
          <View
            style={[
              StyleSheet.absoluteFill,
              {
                backgroundColor: "rgba(0,0,0,0.7)",
              },
            ]}
          />
        </TouchableWithoutFeedback>
        <View
          style={{
            backgroundColor: COLOR.WHITE,
            flexWrap: "wrap",
            width: 440,
            padding: 32,
            overflow: "hidden",
            borderRadius: 16,
            alignContent: "center",
          }}
        >
          <View style={styles.columnContainer}>
            <View style={styles.rowContainer}>
              <View style={{ position: "relative" }}>
                <Image
                  source={{
                    uri: data.profile_picture_url || PLACEHOLDER_IMAGE,
                  }}
                  style={{ width: 112, height: 112, borderRadius: 100 }}
                />
                {renderEmailIcon()}
              </View>
              <View style={styles.nameContainer}>
                {/* detail related */}
                <Text style={[styles.boldHeader5Text, { width: 200 }]}>
                  {data.displayName}
                </Text>
                {renderInterests(data.interests)}
                {renderEmailVerified()}
              </View>
            </View>
            {renderInformation(data.contactName || "", "Profile", () => {})}
            {renderInformation(data.contactTelephone, "Phone", () => {
              () => {
                Linking.openURL(`tel:${data.contactTelephone}`);
              };
            })}
            {renderInformation(data.email, "Email", () => {
              () => {
                Linking.openURL(`mailto:${data.email}`);
              };
            })}
            <View style={styles.solidLine} />
            {renderInformation(
              data.instagramUsername,
              "IGCircle",
              () => {
                if (SOCIAL_PATTERN.test(data.instagramUsername)) {
                  if (HTTP_PATTERN.test(data.instagramUsername)) {
                    Linking.openURL(data.instagramUsername);
                  } else {
                    Linking.openURL("https://" + data.instagramUsername);
                  }
                } else {
                  Linking.openURL(
                    "https://instagram.com/" + data.instagramUsername
                  );
                }
              },
              (data.followers_count || 0).toString()
            )}
            {renderInformation(
              data.tiktokUsername,
              "TiktokCircle",
              () => {
                if (SOCIAL_PATTERN.test(data.tiktokUsername)) {
                  if (HTTP_PATTERN.test(data.tiktokUsername)) {
                    Linking.openURL(data.tiktokUsername);
                  } else {
                    Linking.openURL("https://" + data.tiktokUsername);
                  }
                } else if (/@/.test(data.tiktokUsername)) {
                  Linking.openURL("https://tiktok.com/" + data.tiktokUsername);
                } else {
                  Linking.openURL("https://tiktok.com/@" + data.tiktokUsername);
                }
              },
              (data.followers_count || 0).toString()
            )}

            {!data.isVerified && (
              <View style={styles.spaceBetweenRowContainer}>
                <RButton
                  title="Reject"
                  onPress={onReject}
                  containerStyle={{ width: 136, backgroundColor: COLOR.PINK }}
                />
                <RButton
                  title="Approve"
                  onPress={onApprove}
                  containerStyle={{ width: 136 }}
                />
              </View>
            )}
          </View>
          <TouchableOpacity
            onPress={() => {
              onUnload();
            }}
          >
            <Text>X</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default InfluencerModal;

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    gap: 32,
  },
  spaceBetweenRowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 32,
  },
  columnContainer: {
    flexDirection: "column",
    height: "100%",
    backgroundColor: COLOR.WHITE,
    maxWidth: 360,
    overflow: "hidden",
    gap: 16,
  },
  nameContainer: {
    flexDirection: "column",
    height: "100%",
    backgroundColor: COLOR.WHITE,
    overflow: "hidden",
    gap: 2,
  },
  verifiedContainer: {
    color: COLOR.WHITE,
    borderRadius: 16,
    padding: 4,
    flexDirection: "row",
    backgroundColor: COLOR.LIGHTGREEN2,
    width: 114,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 8,
  },
  unVerifiedContainer: {
    color: COLOR.WHITE,
    borderRadius: 16,
    padding: 4,
    flexDirection: "row",
    backgroundColor: COLOR.LIGHTORANGE,
    with: 128,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 8,
  },
  emailStatusContainer: {
    position: "absolute",
    width: 32,
    height: 32,
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.WHITE,
    borderWidth: 3,
    bottom: 0,
    right: 0,
    borderRadius: 32,
    alignItems: "center",
    justifyContent: "center",
  },
  solidLine: {
    borderWidth: 1,
    borderColor: COLOR.LIGHTGRAY,
    width: 440,
  },
  body1Text: {
    ...TEXT.body1,
  },
  whiteBody1Text: {
    ...TEXT.body1,
    color: COLOR.WHITE,
  },
  orangeBody1Text: {
    ...TEXT.body1,
    color: COLOR.ORANGE,
  },
  greenCaptionText: {
    ...TEXT.caption1,
    color: COLOR.GREEN,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
    marginBottom: 8,
  },
  buttonText: {
    ...TEXT.button,
  },
});
