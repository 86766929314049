import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLOR } from "../styles/Colors";
import { TEXT } from "../styles/Text";
import { WINDOW_HEIGHT } from "./Util";
import RButton from "./reusable/RButton";

interface Props {
  listCount: number;
  onCancel: () => void;
  onAccept: () => void;
}

const BulkVerificationModal = (props: Props) => {
  const { listCount, onCancel, onAccept } = props;

  if (listCount === 0) return <></>;
  return (
    <View style={styles.container}>
      <Text style={styles.body1Text}>เลือก {listCount} รายการ</Text>
      <RButton
        onPress={onAccept}
        title="ยืนยันที่เลือก"
        icon="Check"
        iconPosition="front"
        containerStyle={{ width: 160 }}
        textStyle={[TEXT.buttonBOLD, { color: COLOR.WHITE }]}
      />
      <RButton
        onPress={onCancel}
        title="ปฎิเสธที่เลือก"
        containerStyle={{ backgroundColor: COLOR.PINK, width: 160 }}
        textStyle={[TEXT.buttonBOLD, { color: COLOR.WHITE }]}
        icon="Close"
        iconPosition="front"
      />
    </View>
  );
};

export default BulkVerificationModal;

const styles = StyleSheet.create({
  container: {
    padding: 16,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    borderRadius: 16,
    backgroundColor: COLOR.OFFWHITE,
    position: "absolute",
    width: 212,
    height: 164,
    zIndex: 99999,
    left: 10,
    top: WINDOW_HEIGHT - 170,
  },
  body1Text: {
    ...TEXT.body1,
  },
});
