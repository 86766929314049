import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import { PLACEHOLDER_IMAGE, percentage } from "../Util";
import RIcon from "../reusable/RIcon";
import { TEXT } from "../../styles/Text";
import { COLOR } from "../../styles/Colors";
import { IInstagramInfluencer } from "../../types/influencerAccount";

interface Props {
  instagram: IInstagramInfluencer;
}

const InstagramCard = (props: Props) => {
  const { instagram } = props;
  const {
    profile_picture_url = PLACEHOLDER_IMAGE,
    username = "-",
    followers_count = 0,
    activeFollowers = 0,
    profileImpressions = 0,
    profileReaches = 0,
    recentMediaLikes = 0,
    recentMediaEngagement = 0,
    storyInsights = { avgStoryImpressions: 0, avgStoryReach: 0 },
  } = instagram;

  const { avgStoryImpressions, avgStoryReach } = storyInsights;
  const renderProfileImage = () => {
    if (!!profile_picture_url) {
      return (
        <Image
          style={styles.avatarContainer}
          source={{ uri: profile_picture_url }}
        />
      );
    } else {
      return (
        <Image
          style={styles.avatarContainer}
          source={{ uri: PLACEHOLDER_IMAGE }}
        />
      );
    }
  };

  return (
    <View style={styles.cardContainer}>
      <LinearGradient
        colors={["#FEDA75", "#FA7E1E", "#D62976", "#962FBF", "#4F5BD5"]}
        locations={[0, 0.2393, 0.4893, 0.7081, 0.9633]}
        start={{ x: 3.6, y: 4.12 }}
        end={{ x: 4.57, y: 3 }}
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: 934,
          height: 278,
          borderRadius: 16,
        }}
      >
        <View style={styles.instagramContainer}>
          <View style={styles.instagramLeftContainer}>
            <View style={styles.profileContainer}>
              {renderProfileImage()}
              <Text style={styles.boldHeader6Text}>{username}</Text>
            </View>
            <View style={styles.followerDetailContainer}>
              <View style={styles.columnContainer}>
                <Text style={styles.boldHeader5Text}>{followers_count}</Text>
                <View style={styles.rowContainer}>
                  <RIcon name="Follower" />
                  <Text style={styles.followerText}>Followers</Text>
                </View>
              </View>
              <View style={styles.alignEndColumnContainer}>
                <Text style={styles.boldHeader5Text}>
                  {percentage(activeFollowers || 0, followers_count || 0)}
                </Text>
                <Text style={styles.followerText}>Active Followers</Text>
              </View>
            </View>
          </View>
          <View style={styles.instagramRightContainer}>
            <View style={[styles.statContainer, { flex: 2 }]}>
              <View style={styles.columnContainer}>
                <View style={styles.rowContainer}>
                  <RIcon name="Profile" />
                  <Text style={styles.boldButtonText}>Account</Text>
                </View>
                <Text style={styles.body2Text}>คำนวณเฉลี่ย 30 วันล่าสุด</Text>
              </View>
              <View style={styles.columnContainer}>
                <View style={styles.rowContainer}>
                  <RIcon name="Image" />
                  <Text style={styles.boldButtonText}>Post</Text>
                </View>
                <Text style={styles.body2Text}>
                  คำนวณเฉลี่ยจาก 3 โพสต์ล่าสุด
                </Text>
              </View>
              <View style={styles.columnContainer}>
                <View style={styles.rowContainer}>
                  <RIcon name="Story" />
                  <Text style={styles.boldButtonText}>Story</Text>
                </View>
                <Text style={styles.body2Text}>คำนวณเฉลี่ย 7 วันย้อนหลัง</Text>
              </View>
            </View>
            <View style={[styles.statContainer, { flex: 1 }]}>
              <View style={styles.columnContainer}>
                <View style={styles.rowContainer}>
                  <RIcon name="Reach" />
                  <Text style={styles.boldButtonText}>
                    {profileReaches?.toFixed(2)}
                  </Text>
                </View>
                <Text style={styles.body2Text}>Reach</Text>
              </View>
              <View style={styles.columnContainer}>
                <View style={styles.rowContainer}>
                  <RIcon name="HeartFill" />
                  <Text style={styles.boldButtonText}>{recentMediaLikes}</Text>
                </View>
                <Text style={styles.body2Text}>Like</Text>
              </View>
              <View style={styles.columnContainer}>
                <View style={styles.rowContainer}>
                  <RIcon name="Reach" />
                  <Text style={styles.boldButtonText}>
                    {avgStoryReach?.toFixed(2)}
                  </Text>
                </View>
                <Text style={styles.body2Text}>Reach</Text>
              </View>
            </View>
            <View style={[styles.statContainer, { flex: 1 }]}>
              <View style={styles.columnContainer}>
                <View style={styles.rowContainer}>
                  <RIcon name="ImpressionFill" />
                  <Text style={styles.boldButtonText}>
                    {profileImpressions?.toFixed(2)}
                  </Text>
                </View>
                <Text style={styles.body2Text}>Impression</Text>
              </View>
              <View style={styles.columnContainer}>
                <View style={styles.rowContainer}>
                  <RIcon name="Engage" />
                  <Text style={styles.boldButtonText}>
                    {recentMediaEngagement}
                  </Text>
                </View>
                <Text style={styles.body2Text} numberOfLines={1}>
                  Engagement
                </Text>
              </View>
              <View style={styles.columnContainer}>
                <View style={styles.rowContainer}>
                  <RIcon name="ImpressionFill" />
                  <Text style={styles.boldButtonText}>
                    {avgStoryImpressions?.toFixed(2)}
                  </Text>
                </View>
                <Text style={styles.body2Text}>Impression</Text>
              </View>
            </View>
          </View>
        </View>
      </LinearGradient>
    </View>
  );
};

export default InstagramCard;

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 24,
    padding: 32,
    backgroundColor: COLOR.WHITE,
  },
  cardContainer: {
    flexDirection: "column",
    backgroundColor: COLOR.WHITE,
    gap: 16,
  },
  followerDetailContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 48,
  },
  columnContainer: {
    flexDirection: "column",
    gap: 4,
  },
  alignEndColumnContainer: {
    flexDirection: "column",
    gap: 4,
    alignItems: "flex-end",
  },
  avatarContainer: {
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: COLOR.WHITE,
  },
  instagramContainer: {
    padding: 32,
    width: 928,
    height: 272,
    borderRadius: 16,
    margin: 3,
    backgroundColor: COLOR.WHITE,
    flexDirection: "row",
  },
  instagramLeftContainer: {
    flexDirection: "column",
    gap: 66,
    borderStyle: "dashed",
    borderRightColor: COLOR.DARKPINK,
    borderRightWidth: 1,
    paddingRight: 48,
  },
  statContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 16,
  },
  instagramRightContainer: {
    flex: 1,
    flexDirection: "row",
    paddingLeft: 40,
  },
  profileContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  rowContainer: {
    flexDirection: "row",
    gap: 4,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
  },
  boldHeader6Text: {
    ...TEXT.header6BOLD,
  },
  boldButtonText: {
    ...TEXT.buttonBOLD,
    marginTop: 2,
  },
  body2Text: {
    ...TEXT.body2,
    marginLeft: 28,
    overflow: "visible",
  },
  followerText: {
    ...TEXT.body2,
    marginTop: 4,
  },
});
