import { useFonts } from "expo-font";
import React from "react";
import RootNavigation from "./navigations/RootNavigation";
import authStore from "./stores/authStore";
import notificationStore from "./stores/notificationStore";
import { permissionStore } from "./stores/permissionStore";
import {
  AuthStoreContext,
  NotificationStoreContext,
  PermissionStoreContext,
  VerificationStoreContext,
  BrandStoreContext,
  InfluencerStoreContext,
  NavigationStoreContext,
} from "./stores/storeContexts";
import verificationStore from "./stores/verificationStore";
import { NavigationContainer } from "@react-navigation/native";
import { brandStore } from "./stores/brandStore";
import { influencerStore } from "./stores/influencerStore";
import { Helmet } from "react-helmet";
import { navigationStore } from "./stores/navigationStore";

export default function App() {
  let [fontsLoaded] = useFonts({
    AktivGrotesk_Regular: require("./assets/fonts/AktivGrotesk_Trial_Rg.ttf"),
    AktivGrotesk_Bold: require("./assets/fonts/AktivGrotesk_Trial_Bd.ttf"),
  });

  if (!fontsLoaded) {
    return null;
  }
  return (
    <AuthStoreContext.Provider value={authStore}>
      <VerificationStoreContext.Provider value={verificationStore}>
        <PermissionStoreContext.Provider value={permissionStore}>
          <NotificationStoreContext.Provider value={notificationStore}>
            <BrandStoreContext.Provider value={brandStore}>
              <InfluencerStoreContext.Provider value={influencerStore}>
                <NavigationStoreContext.Provider value={navigationStore}>
                  <NavigationContainer>
                    <Helmet>
                      <title>Pickle Internal</title>
                    </Helmet>
                    <RootNavigation />
                  </NavigationContainer>
                </NavigationStoreContext.Provider>
              </InfluencerStoreContext.Provider>
            </BrandStoreContext.Provider>
          </NotificationStoreContext.Provider>
        </PermissionStoreContext.Provider>
      </VerificationStoreContext.Provider>
    </AuthStoreContext.Provider>
  );
}
