import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import { useNavigate } from "react-router-dom";
import ActivityIndicator from "../../components/ActivityIndicator";
import { WINDOW_HEIGHT } from "../../components/Util";
import Search from "../../components/atoms/Search";
import BanInfluencer from "../../components/influencer/BanInfluencer";
import ListItem, { TitleKey } from "../../components/reusable/ListItem";
import {
  useInfluencerStore,
  useNavigationStore,
  useVerificationStore,
} from "../../stores/storeContexts";
import { IInfluencerAccount } from "../../types/influencerAccount";
import { formatDate } from "../../utils/formatDate";
import RXModal from "../../components/reusable/RXModal/RXModal";

const titles: Array<TitleKey> = [];

const keys = [
  { display: "No.", value: "number", size: 1 },
  {
    display: "Image",
    value: "img",
    size: 3,
  },
  {
    display: "Pickle Username",
    value: "displayName",
    size: 5,
  },
  {
    display: "Instagram",
    value: "instagramUsername",
    size: 4,
  },
  {
    display: "Tiktok",
    value: "tiktokUsername",
    size: 4,
  },
  {
    display: "Pending",
    value: "pendingCount",
    size: 3,
  },
  {
    display: "Ongoing",
    value: "ongoingCount",
    size: 3,
  },
  {
    display: "Done",
    value: "doneCount",
    size: 3,
  },
  {
    display: "Reject",
    value: "rejectedCount",
    size: 3,
  },
  {
    display: "Cancel",
    value: "cancelledCount",
    size: 3,
  },
  {
    display: "Register At",
    value: "createdAt",
    size: 3,
  },
  {
    display: "Ban",
    value: "bannable",
    size: 1,
  },
];

keys.forEach((key) => {
  titles.push({ display: key.display, size: key.size });
});

const InfluencerListScreen = () => {
  const {
    getInfluencerList,
    influencerList,
    setSortBy,
    setSortOrder,
    setInfluencerListPage,
    banLoading,
  } = useVerificationStore();
  const { getInfluencer } = useInfluencerStore();
  const { pushStack } = useNavigationStore();

  const navigation = useNavigate();
  const [selectedList, setSelectedList] = useState<Array<string>>([]);
  const {
    sortBy = { display: "createdAt", value: "createdAt" },
    sortOrder,
    data = [],
    page = 0,
    loading = false,
  } = influencerList;
  const [key, setKey] = useState<string>("createdAt");
  const [sort, swapSort] = useState<"asc" | "desc">("desc");
  const [keyword, setKeyword] = useState<string>("");
  const [date, setDate] = useState<Array<Date>>([]);
  const [banId, setBanId] = useState<string>("");
  const [banStatus, setBanStatus] = useState<boolean>(false);
  const [positionX, setPositionX] = useState<number>(0);
  const [positionY, setPositionY] = useState<number>(0);

  const setSort = (key: string) => {
    if (sortBy.display !== key) {
      setSortBy(
        "influencers",
        "list",
        keys[keys.findIndex((keyIndex) => keyIndex.display === key)]
      );
      setSortOrder("influencers", "list", "asc");
    } else {
      if (sortOrder === "asc") {
        setSortOrder("influencers", "list", "desc");
      } else if (sortOrder === "desc") {
        setSortOrder("influencers", "list", "desc");
        setSortBy("influencers", "list", {
          display: "createdAt",
          value: "createdAt",
        });
      }
    }
  };

  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    // disable infinite scroll for now
    // const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    // if (layoutMeasurement.height + contentOffset.y >= contentSize.height) {
    //   setInfluencerListPage(page + 1);
    // }
  };

  const filterDate = (influencer: IInfluencerAccount) => {
    const createdAt = new Date(influencer.createdAt);
    switch (date.length) {
      case 0:
        return true;
      case 1:
        return (
          formatDate(createdAt.toString()) === formatDate(date[0].toString())
        );
      default:
        return !!createdAt && createdAt >= date[0] && createdAt <= date[1];
    }
  };

  const BanningComponent = () => {
    if (banId !== "") {
      return (
        <BanInfluencer
          positionX={positionX - 448}
          positionY={positionY - 96}
          id={banId}
          banStatus={banStatus}
          setBanId={setBanId}
        />
      );
    }
    return <></>;
  };

  const ListComponent = ({
    influencer,
    index,
  }: {
    influencer: IInfluencerAccount;
    index: number;
  }) => {
    return (
      <ListItem
        onPress={() => {
          getInfluencer(influencer.id);
          pushStack({
            display: influencer?.displayName || "Influencer Detail",
            path: "/influencer/detail",
          });
          navigation("/influencer/detail");
        }}
        key={influencer.id}
        keys={keys}
        ignored={["_id"]}
        data={influencer}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        index={index}
        useEmailVerify={false}
        showVerify={true}
        banId={banId}
        setBanId={setBanId}
        setBanStatus={setBanStatus}
        setPositionX={setPositionX}
        setPositionY={setPositionY}
      />
    );
  };

  const TableComponent = () => {
    const listItems: Array<JSX.Element> = [];
    data.map((influencer, index) => {
      if (!!date[0]) {
        if (filterDate(influencer)) {
          listItems.push(
            <ListComponent influencer={influencer} index={index} />
          );
        }
      } else if (index < page * 10) {
        listItems.push(<ListComponent influencer={influencer} index={index} />);
      }
    });
    return (
      <View>
        {data?.map((item, index) => (
          <ListComponent influencer={item} index={index} />
        ))}
      </View>
    );
  };

  const LoadingModalComponent = () => {
    if (banLoading) {
      return (
        <RXModal visible={banLoading} onClose={() => {}}>
          <ActivityIndicator />
        </RXModal>
      );
    }
    return <></>;
  };

  useEffect(() => {
    getInfluencerList({ page: 1, searchText: keyword });
  }, [keyword, date, page, sort, key]);

  useEffect(() => {
    setSort(key);
  }, [key, sort]);

  if (loading) {
    return <ActivityIndicator style={{ marginTop: 32 }} />;
  }

  return (
    <View>
      <ListItem
        keys={keys}
        title={titles}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        sortOrder={sort}
        sortKey={sortBy.display}
        setSortKey={setKey}
        swapSort={() => {
          if (sort === "asc") {
            swapSort("desc");
          } else {
            swapSort("asc");
          }
        }}
      />
      <Search setKeyword={setKeyword} date={date} setDate={setDate} />
      <ScrollView
        style={styles.scrollViewContainer}
        onScroll={(event) => onScroll(event)}
        scrollEventThrottle={16}
      >
        <TableComponent />
      </ScrollView>
      <BanningComponent />
      <LoadingModalComponent />
    </View>
  );
};

export default observer(InfluencerListScreen);

const styles = StyleSheet.create({
  scrollViewContainer: {
    height: WINDOW_HEIGHT - 170,
  },
});
