import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { ILocation } from "../../types/influencerAccount";

interface Props {
  locations: Array<ILocation>;
  dataExistFollowers: number;
}

const Location = (props: Props) => {
  const { locations, dataExistFollowers } = props;

  if (locations.length === 0) {
    return null;
  }
  const locationDemographic = (
    location: string,
    followers: number,
    isFirst = false
  ) => {
    return (
      <View style={styles.row}>
        <Text style={styles.value} numberOfLines={1}>
          {location}
        </Text>
        <View style={styles.container}>
          <View
            style={{
              backgroundColor: isFirst ? COLOR.GREEN : COLOR.LIGHTGREEN,
              borderRadius: 10,
              height: "100%",
              width: `${Math.round((followers / dataExistFollowers) * 100)}%`,
            }}
          />
        </View>
        <Text style={styles.percentage} numberOfLines={1}>
          {Math.round((followers / dataExistFollowers) * 100)}%
        </Text>
      </View>
    );
  };

  const otherFollowers =
    dataExistFollowers -
    locations[0][1] -
    locations[1][1] -
    locations[2][1] -
    locations[3][1];

  return (
    <View style={styles.contentContainer}>
      {locationDemographic(
        locations[0][0].split(", ")[1],
        locations[0][1],
        true
      )}
      {locationDemographic(locations[1][0].split(", ")[1], locations[1][1])}
      {locationDemographic(locations[2][0].split(", ")[1], locations[2][1])}
      {locationDemographic(locations[3][0].split(", ")[1], locations[3][1])}
      {locationDemographic("Others", otherFollowers)}
    </View>
  );
};

export default Location;

const styles = StyleSheet.create({
  contentContainer: {
    width: "66%",
    alignSelf: "center",
    gap: 16,
  },
  header: {
    ...TEXT.caption2,
    color: COLOR.DARKGRAY,
    marginTop: 10,
  },
  row: {
    flexDirection: "row",
  },
  value: {
    width: "30%",
    alignItems: "center",
    marginRight: 4,
    ...TEXT.body1,
  },
  container: {
    height: 10,
    flex: 1,
    alignSelf: "center",
  },
  percentage: {
    width: "20%",
    textAlign: "right",
    ...TEXT.body2BOLD,
  },
});
