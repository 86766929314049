const doubleDigitsFormat = (date: number) => {
  if (date < 10) {
    return "0" + date;
  }
  return date;
};

export const formatDate = (date: string) => {
  const d = new Date(date);
  return `${doubleDigitsFormat(d.getDate())}/${doubleDigitsFormat(
    d.getMonth() + 1
  )}/${d.getFullYear()}`;
};
