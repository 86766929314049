import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";
import { environment } from "../api/pickle";
import {
  FirebaseDatabase,
  firebaseConfig,
} from "../components/banners/FirebaseDatabase";
import { IBanner, IBanners } from "../components/banners/IBanner";
import { IBannerFirebase } from "../components/banners/IBannerFirebase";
import ListBanners from "../components/banners/ListBanners";
import RButton from "../components/reusable/RButton";
import RImagePicker from "../components/reusable/RImagePicker";
import { COLOR } from "../styles/Colors";
import { TEXT } from "../styles/Text";

const Banner = () => {
  const [firebaseDriver, setFirebaseDriver] = useState<IBannerFirebase | null>(
    null
  );
  const [image, setImage] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [banners, setBanners] = React.useState<IBanners>({});

  const onAddBanner = () => {
    const banner: IBanner = { image: image, url: url };
    if (firebaseDriver) {
      firebaseDriver.addBanner(banner).then((banners) => {
        setBanners(banners);
      });
    }
  };

  useEffect(() => {
    setFirebaseDriver(
      new FirebaseDatabase(environment, firebaseConfig[environment])
    );
  }, [environment]);

  return (
    <View style={styles.container}>
      <Text style={styles.boldHeader5Text}>Add New banner</Text>
      <Text style={styles.body1Text}>
        <b>Attach File</b>
        {" : Size 330 x100 px   (.jpeg, .png)"}
      </Text>
      <RImagePicker
        onChange={(image: string) => {
          setImage(image);
        }}
        containerStyle={{ width: 330, height: 100 }}
        imageStyle={{ width: 330, height: 100 }}
        title="เลือกไฟล์"
      />

      <View style={styles.rowContainer}>
        <Text style={styles.boldBody1Text}>File Name</Text>
        <Text style={[styles.boldBody1Text, { width: 10 }]}>:</Text>
        <TextInput
          editable={false}
          value={image}
          onChangeText={(text: string) => setImage(text)}
          placeholder="ชื่อไฟล์"
          placeholderTextColor={COLOR.GRAY}
          style={styles.textContainer}
        />
      </View>

      <View style={styles.rowContainer}>
        <Text style={styles.boldBody1Text}>URL</Text>
        <Text style={[styles.boldBody1Text, { width: 10 }]}>:</Text>
        <TextInput
          value={url}
          onChangeText={(text: string) => setUrl(text)}
          placeholder="แนบลิ้งก์ URL"
          placeholderTextColor={COLOR.GRAY}
          style={styles.textContainer}
        />
      </View>

      <View style={[styles.rowContainer, { gap: 24 }]}>
        <RButton
          onPress={() => {
            setImage("");
            setUrl("");
          }}
          title="Clear"
          textStyle={{ marginLeft: 4, color: COLOR.DARKGREEN }}
          containerStyle={{
            borderColor: COLOR.GREEN,
            backgroundColor: COLOR.WHITE,
            borderWidth: 1,
            width: 150,
          }}
        />
        <RButton
          onPress={() => onAddBanner()}
          title="Submit"
          icon="Check"
          textStyle={{ marginLeft: 4 }}
          containerStyle={{
            width: 150,
          }}
        />
      </View>

      <View style={styles.existContainer}>
        <Text style={styles.boldHeader5Text}>Existing Banner</Text>
      </View>

      <ListBanners
        FirebaseDatabase={firebaseDriver}
        banners={banners}
        setBanners={setBanners}
      />
    </View>
  );
};

export default observer(Banner);

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    padding: 24,
    backgroundColor: COLOR.WHITE,
    flex: 1,
  },
  rowContainer: {
    flexDirection: "row",
    padding: 10,
    marginTop: 8,
    alignItems: "center",
  },
  imageContainer: {
    width: 120,
    height: 120,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: COLOR.OFFWHITE,
    borderRadius: 16,
    marginTop: 10,
  },
  textContainer: {
    ...TEXT.body1,
    padding: 10,
    borderBottomColor: COLOR.GRAY,
    borderBottomWidth: 1,
    marginLeft: 10,
    width: "100%",
    maxWidth: 812,
  },
  existContainer: {
    borderTopWidth: 1,
    borderTopColor: COLOR.LIGHTGRAY,
    borderStyle: "dashed",
    paddingTop: 24,
    marginTop: 28,
    marginRight: 24,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
    width: 100,
  },
  body1Text: {
    ...TEXT.body1,
    marginTop: 16,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
    marginTop: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
  },
});
