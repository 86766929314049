import React from "react";
import {
  Modal,
  StyleSheet,
  Text,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { IInfluencerAccount } from "../../types/influencerAccount";
import RButton from "../reusable/RButton";

const UnlinkModal = (props: {
  visible: boolean;
  onClose: () => void;
  onApprove: () => void;
  influencer: IInfluencerAccount;
}) => {
  const { visible, onApprove, onClose, influencer } = props;

  const haveWork = influencer.pendingCount > 0 || influencer.ongoingCount > 0;

  const renderDetail = () => {
    if (haveWork) {
      const { pendingCount, ongoingCount } = influencer;
      return (
        <>
          <Text style={styles.boldHeader5Text}>ยังมีงานค้างอยู่</Text>
          {pendingCount > 0 && (
            <Text style={styles.body1Text}>
              สถานะ pending จำนวน {pendingCount} งาน
            </Text>
          )}
          {ongoingCount > 0 && (
            <Text style={styles.body1Text}>
              สถานะ ongoing จำนวน {ongoingCount} งาน
            </Text>
          )}
        </>
      );
    } else {
      return (
        <Text style={styles.boldHeader5Text}>อินฟลูคนนี้ไม่มีงานค้าง</Text>
      );
    }
  };
  return (
    <Modal transparent={true} onDismiss={onClose} visible={visible}>
      <View style={styles.container}>
        <TouchableWithoutFeedback onPress={onClose}>
          <View
            style={[
              StyleSheet.absoluteFill,
              {
                backgroundColor: "rgba(0,0,0,0.7)",
              },
            ]}
          />
        </TouchableWithoutFeedback>
        <View style={styles.contentContainer}>
          <View style={styles.columnContainer}>
            {renderDetail()}
            <View style={styles.rowContainer}>
              <RButton
                title="ยกเลิก"
                onPress={onClose}
                containerStyle={styles.cancelButtonStyle}
              />
              <RButton
                title="ยืนยัน"
                onPress={onApprove}
                containerStyle={styles.approveButtonStyle}
              />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default UnlinkModal;

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  contentContainer: {
    backgroundColor: COLOR.WHITE,
    flexWrap: "wrap",
    padding: 32,
    overflow: "hidden",
    borderRadius: 16,
    alignContent: "center",
  },
  rowContainer: {
    flexDirection: "row",
    gap: 32,
  },
  spaceBetweenRowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 32,
  },
  columnContainer: {
    flexDirection: "column",
    height: "100%",
    backgroundColor: COLOR.WHITE,
    maxWidth: 360,
    overflow: "hidden",
    gap: 16,
    alignContent: "center",
  },
  approveButtonStyle: {
    width: 110,
    marginTop: 32,
  },
  cancelButtonStyle: {
    width: 110,
    backgroundColor: COLOR.PINK,
    marginTop: 32,
  },
  body1Text: {
    ...TEXT.body1,
  },
  whiteBody1Text: {
    ...TEXT.body1,
    color: COLOR.WHITE,
  },
  orangeBody1Text: {
    ...TEXT.body1,
    color: COLOR.ORANGE,
  },
  greenCaptionText: {
    ...TEXT.caption1,
    color: COLOR.GREEN,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
    marginBottom: 8,
  },
  buttonText: {
    ...TEXT.button,
  },
});
