import React from "react";
import { Linking, StyleSheet, Text, View } from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { IInfluencerAccount } from "../../types/influencerAccount";
import LemonCard from "../molecules/LemonCard";
import RButton from "../reusable/RButton";

interface Props {
  influencer: IInfluencerAccount;
}

const InfluencerDetailLemon = (props: Props) => {
  const { influencer } = props;

  const renderLemonCard = () => {
    if (!!influencer.lemonUrl) {
      return <LemonCard url={influencer.lemonUrl} />;
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.boldHeader5Text}>Lemon8</Text>
      {renderLemonCard()}
      <View style={styles.rowContainer}>
        <Text style={styles.boldHeader6Text}>View on</Text>
        <RButton
          containerStyle={styles.button}
          textStyle={styles.boldBody1Text}
          title="Lemon"
          onPress={() =>
            Linking.openURL(
              `https://${influencer.lemonUrl}` || "https://lemon8-app.com"
            )
          }
          icon="LemonCircle"
        />
      </View>
    </View>
  );
};

export default InfluencerDetailLemon;

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 24,
    padding: 32,
    backgroundColor: COLOR.WHITE,
  },
  rowContainer: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
  },
  button: {
    justifyContent: "center",
    alignItems: "center",
    width: 128,
    height: 32,
    borderRadius: 16,
    backgroundColor: COLOR.LEMON,
  },
  boldHeader6Text: {
    ...TEXT.header6BOLD,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
    marginLeft: 4,
  },
});
