import { StyleSheet, View, Text, Image, TouchableOpacity } from "react-native";
import RIcon from "./reusable/RIcon";
import { COLOR } from "../styles/Colors";
import React, { useEffect } from "react";
import { TEXT } from "../styles/Text";

import { useLocation, useNavigate } from "react-router-dom";
import { useNavigationStore } from "../stores/storeContexts";
import { observer } from "mobx-react";

type BrandPageType = "verification" | "list" | "notification" | "banner";
type InfluencerPageType = "verification" | "list" | "notification" | "delete";

const Header = () => {
  const { clearStack, pushStack, navigationStack } = useNavigationStore();
  const navigation = useNavigate();
  const route = useLocation();
  const { pathname } = route;
  const platform = pathname.split("/")[1];
  const page = pathname.split("/")[2];
  const isLoginPage = !platform;

  const initialStack = {
    brand: {
      verification: { display: "Verification", path: "/brand/verification" },
      list: { display: "User List", path: "/brand/list" },
      deals: { display: "User List", path: "/brand/list" },
      deal: { display: "User List", path: "/brand/list" },
      infludetail: { display: "User List", path: "/brand/list" },
      notification: { display: "Notification", path: "/brand/notification" },
      banner: { display: "Banner", path: "/brand/banner" },
    },
    influencer: {
      verification: {
        display: "Verification",
        path: "/influencer/verification",
      },
      list: { display: "User List", path: "/influencer/list" },
      detail: { display: "User List", path: "/influencer/list" },
      notification: {
        display: "Notification",
        path: "/influencer/notification",
      },
      delete: { display: "Delete", path: "/influencer/delete" },
    },
  };

  const initStack = () => {
    switch (platform) {
      case "brand":
        pushStack({ display: "Brand", path: "/brand/verification" });
        pushStack(initialStack[platform][page as BrandPageType]);
        break;
      case "influencer":
        pushStack({ display: "Influencer", path: "/influencer/verification" });
        pushStack(initialStack[platform][page as InfluencerPageType]);
        break;
    }
  };

  const renderColor = () => {
    if (platform === "influencer") {
      return COLOR.GREEN;
    } else {
      return COLOR.BLACK;
    }
  };

  const renderTitle = () => {
    if (platform === "influencer") {
      return "Pickle for influencer";
    } else if (platform === "brand") {
      return "Pickle for brand";
    } else {
      return "";
    }
  };

  const renderLogout = () => {
    if (!isLoginPage) {
      return (
        <TouchableOpacity
          style={styles.rowContainer}
          onPress={() => {
            clearStack();
            localStorage.removeItem("auth");
            navigation("/");
          }}
        >
          <RIcon name="LogOut" />
          <Text style={styles.buttonBoldText}>Log out</Text>
        </TouchableOpacity>
      );
    } else {
      return <View style={styles.rowContainer}></View>;
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("auth")) {
      navigation("/");
    }
  }, [pathname]);

  useEffect(() => {
    if (navigationStack.length === 0) initStack();
  }, []);

  return (
    <View
      style={[
        styles.headerContainer,
        {
          backgroundColor: renderColor(),
        },
      ]}
    >
      <Text style={[styles.buttonBoldText, { flex: 2 }]}>{renderTitle()}</Text>
      <TouchableOpacity
        style={styles.logoContainer}
        onPress={() => {
          clearStack();
          navigation("/platform");
        }}
      >
        <Image
          source={require("../assets/PickleLogo.png")}
          style={{ width: 128, height: 48 }}
        />
      </TouchableOpacity>
      <View style={styles.logoutContainer}>{renderLogout()}</View>
    </View>
  );
};

export default observer(Header);

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 16,
    height: 72,
  },
  logoContainer: {
    alignItems: "center",
    flex: 6,
  },
  logoutContainer: {
    alignItems: "flex-end",
    flex: 2,
  },
  rowContainer: {
    flexDirection: "row",
    gap: 4,
  },
  buttonBoldText: {
    ...TEXT.buttonBOLD,
    color: COLOR.WHITE,
  },
});
