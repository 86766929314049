import { COLOR } from "./Colors";
import { FontAktivGroteskBold, FontAktivGroteskRegular } from "./Font";

const commonTextStyle = {
  color: COLOR.BLACK,
};

export const TEXT = {
  header1: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskRegular,
    fontSize: 96,
    lineHeight: 100,
  },
  header2: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskRegular,
    fontSize: 60,
    lineHeight: 64,
  },
  header3: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskRegular,
    fontSize: 48,
    lineHeight: 52,
  },
  header4: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskRegular,
    fontSize: 34,
    lineHeight: 43.52,
  },
  header5: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskRegular,
    fontSize: 24,
    lineHeight: 30,
  },
  header6: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskRegular,
    fontSize: 20,
    lineHeight: 24,
  },
  body1: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskRegular,
    fontSize: 16,
    lineHeight: 20,
  },
  body2: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskRegular,
    fontSize: 14,
    lineHeight: 18,
  },
  caption1: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskRegular,
    fontSize: 12,
    lineHeight: 15,
  },
  caption2: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskRegular,
    fontSize: 10,
    lineHeight: 12,
  },
  overline: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskRegular,
    fontSize: 10,
    lineHeight: 14,
    textTransform: "uppercase",
  },
  button: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskRegular,
    fontSize: 18,
    lineHeight: 23,
  },
  header1BOLD: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskBold,
    fontSize: 50,
    lineHeight: 52,
  },
  header2BOLD: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskBold,
    fontSize: 60,
    lineHeight: 44,
  },
  header3BOLD: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskBold,
    fontSize: 48,
    lineHeight: 61,
  },
  header4BOLD: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskBold,
    fontSize: 34,
    lineHeight: 36,
  },
  header5BOLD: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskBold,
    fontSize: 24,
    lineHeight: 28,
  },
  header6BOLD: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskBold,
    fontSize: 20,
    lineHeight: 24,
  },
  body1BOLD: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskBold,
    fontSize: 16,
    lineHeight: 22,
  },
  body2BOLD: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskBold,
    fontSize: 14,
    lineHeight: 18,
  },
  caption1BOLD: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskBold,
    fontSize: 12,
    lineHeight: 15,
  },
  caption2BOLD: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskBold,
    fontSize: 10,
    lineHeight: 12,
  },
  buttonBOLD: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskBold,
    fontSize: 18,
    lineHeight: 20,
  },
  overlineBOLD: {
    ...commonTextStyle,
    fontFamily: FontAktivGroteskBold,
    fontSize: 16,
    lineHeight: 18,
    textTransform: "uppercase",
  },
};
