import React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useNavigate } from "react-router-dom";
import { COLOR } from "../styles/Colors";
import { TEXT } from "../styles/Text";
import { useNavigationStore } from "../stores/storeContexts";

const WelcomeScreen = () => {
  const { pushStack } = useNavigationStore();
  const navigation = useNavigate();
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={[
          styles.selectionContainer,
          { backgroundColor: COLOR.BLACK, marginTop: 104 },
        ]}
        onPress={() => {
          pushStack({ display: "Brand", path: "/brand/verification" });
          pushStack({ display: "Verification", path: "/brand/verification" });
          navigation("/brand/verification");
        }}
      >
        <Image
          source={require("../assets/pickle-brand.png")}
          style={{ width: 74, height: 74 }}
        />
        <Text style={styles.boldHeader4Text}>Pickle for Brand</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={[
          styles.selectionContainer,
          { backgroundColor: COLOR.GREEN, marginTop: 22 },
        ]}
        onPress={() => {
          pushStack({
            display: "Influencer",
            path: "/influencer/verification",
          });
          pushStack({
            display: "Verification",
            path: "/influencer/verification",
          });
          navigation("/influencer/verification");
        }}
      >
        <Image
          source={require("../assets/pickle-influencer.png")}
          style={{ width: 74, height: 74 }}
        />
        <Text style={styles.boldHeader4Text}>Pickle for Influencer</Text>
      </TouchableOpacity>
    </View>
  );
};

export default WelcomeScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
  },
  selectionContainer: {
    display: "flex",
    width: 525,
    height: 138,
    flexDirection: "row",
    gap: 32,
    alignItems: "center",
    padding: 32,
    borderRadius: 16,
    marginLeft: -229,
  },
  boldHeader4Text: {
    ...TEXT.header4BOLD,
    color: COLOR.WHITE,
  },
});
