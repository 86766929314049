import axios, { AxiosRequestHeaders } from "axios";

export type MicrosoftMeResponse = {
  displayName: string;
  givenName: string;
  id: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  officeLocation: string;
  preferredLanguage: string;
  surname: string;
  userPrincipalName: string;
};

export const microsoftAPI = async <T>(
  url: string,
  headers: AxiosRequestHeaders
) => {
  const response = await axios.get<T>(
    `https://graph.microsoft.com/v1.0/${url}`,
    { headers }
  );
  return response.data;
};
