import React, { useEffect } from "react";
import { View } from "react-native";
import Banner from "./Banner";
import { IBanners } from "./IBanner";
import { IBannerFirebase } from "./IBannerFirebase";

interface Props {
  FirebaseDatabase: IBannerFirebase | null;
  banners: IBanners;
  setBanners: React.Dispatch<React.SetStateAction<IBanners>>;
}

const ListBanners = (props: Props) => {
  const { FirebaseDatabase, banners, setBanners } = props;

  const confirmRemoveBanner = (key: string) => {
    FirebaseDatabase!
      .removeBanner(key)
      .then((banners) => {
        setBanners(banners);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (FirebaseDatabase) {
      FirebaseDatabase.getCurrentBanners().then((banners) => {
        setBanners(banners);
      });
    }
  }, [FirebaseDatabase]);

  return (
    <View>
      {Object.keys(banners).map((key, index) => {
        const banner = banners[key];
        return (
          <Banner
            key={key}
            banner={banner}
            removeBanner={() => {
              confirmRemoveBanner(key);
            }}
            index={index + 1}
          />
        );
      })}
    </View>
  );
};

export default ListBanners;
