export interface IBrandAccount {
  brandId: string;
  email: string;
  password: string;
  name: string;
  img: string;
  category: string;
  contactName: string;
  contactPosition: string;
  contactEmail: string;
  contactTelephone: string;

  companyName: string;
  facebook: string;
  industry: string;
  instagram: string;
  tiktok: string;
  website: string;

  expoToken: string[];

  waittingForVerify: boolean;

  agreed: boolean;
  isVerified: boolean;
  isEmailVerified: boolean;
  verifyEmailToken: string;
  lastEmailVerifySent: Date;
  resetCode: string;
  resetCodeExpire: Date;
  active: boolean;

  omiseCustomerId: string;

  subscriptionId: string;
  balance: number;
  registerFrom: string;
  verifiedAt: Date;

  createdAt: Date;
  updatedAt: Date;
}

export const initialBrandAccount = {
  brandId: "",
  email: "",
  password: "",
  name: "",
  img: "",
  category: "",
  contactName: "",
  contactPosition: "",
  contactEmail: "",
  contactTelephone: "",

  companyName: "",
  facebook: "",
  industry: "",
  instagram: "",
  tiktok: "",
  website: "",

  expoToken: [],

  waittingForVerify: false,

  agreed: false,
  isVerified: false,
  isEmailVerified: false,
  verifyEmailToken: "",
  lastEmailVerifySent: new Date(),
  resetCode: "",
  resetCodeExpire: new Date(),
  active: true,

  omiseCustomerId: "",

  subscriptionId: "",
  balance: 0,
  registerFrom: "",
  verifiedAt: new Date(),
  createdAt: new Date(),
  updatedAt: new Date(),
};
