import { StyleSheet, Text as SystemText, TextProps } from "react-native";
import React from "react";

interface Props {
  children: string | undefined;
  weight?:
    | "bold"
    | "normal"
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900"
    | undefined;
}

export const HeaderText = (props: Props & TextProps) => {
  const { children, style } = props;
  return (
    <SystemText {...props} style={[styles.header, style]}>
      {children}
    </SystemText>
  );
};

const Text = (props: Props & TextProps) => {
  const { children, weight, style, ...otherProps } = props;

  return (
    <SystemText {...otherProps} style={[{ fontWeight: weight }, style]}>
      {children}
    </SystemText>
  );
};

export default Text;

const styles = StyleSheet.create({
  header: {
    fontSize: 24,
  },
});
