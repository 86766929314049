import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { useLocation, useNavigate } from "react-router-dom";
import ActivityIndicator from "../../components/ActivityIndicator";
import InfluencerDetailHeader from "../../components/influencer/InfluencerDetailHeader";
import InfluencerDetailInstagram from "../../components/influencer/InfluencerDetailInstagram";
import InfluencerDetailLemon from "../../components/influencer/InfluencerDetailLemon";
import InfluencerDetailOverview from "../../components/influencer/InfluencerDetailOverview";
import InfluencerDetailReview from "../../components/influencer/InfluencerDetailReview";
import InfluencerDetailTiktok from "../../components/influencer/InfluencerDetailTiktok";
import { useInfluencerStore } from "../../stores/storeContexts";
import { COLOR, SHADOW } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
interface Props {
  scrollViewRef: React.MutableRefObject<ScrollView | null>;
}

const InfluencerListScreen = (props: Props) => {
  const { scrollViewRef } = props;
  const {
    influencer,
    influencerLoading = false,
    unlink,
    unlinkTiktok,
  } = useInfluencerStore();
  const navigation = useNavigate();
  const route = useLocation();

  const [selectedBar, setSelectedBar] = useState<string>("default");

  const overviewRef = useRef<View | null>(null);
  const instagramRef = useRef<View | null>(null);
  const tiktokRef = useRef<View | null>(null);
  const lemonRef = useRef<View | null>(null);
  const reviewRef = useRef<View | null>(null);

  const haveIG = !!influencer.instagram;
  const haveTK = !!influencer.tiktok;
  const haveLM = !!influencer.lemonUrl && influencer.lemonUrl.trim() !== "";
  const haveReviews = !!influencer.instagram?.reviewOverall;

  const unlinkSocial = (type: "ig" | "tk") => {
    switch (type) {
      case "ig":
        unlink();
        influencer.instagram = undefined;
        break;
      case "tk":
        unlinkTiktok();
        influencer.tiktok = undefined;
        break;
    }
  };

  const scrollToView = (ref: React.RefObject<View | null>) => {
    if (ref.current && scrollViewRef.current) {
      ref.current.measureLayout(
        scrollViewRef.current.getInnerViewNode(),
        (x, y) => {
          scrollViewRef.current?.scrollTo({
            y,
            animated: true,
          });
        }
      );
    }
  };

  const BarComponent = ({ name }: { name: string }) => {
    if (selectedBar === name) {
      return <View style={styles.activeContainer}></View>;
    }
    return <></>;
  };

  const InstagramBarComponent = () => {
    if (haveIG) {
      return (
        <TouchableOpacity
          style={styles.barContainer}
          onPress={() => {
            setSelectedBar("instagram");
            scrollToView(instagramRef);
          }}
        >
          <Text style={styles.boldBody1Text}>Instagram</Text>
          <BarComponent name="instagram" />
        </TouchableOpacity>
      );
    }
    return <></>;
  };

  const TiktokBarComponent = () => {
    if (haveTK) {
      return (
        <TouchableOpacity
          style={styles.barContainer}
          onPress={() => {
            setSelectedBar("tiktok");
            scrollToView(tiktokRef);
          }}
        >
          <Text style={styles.boldBody1Text}>Tiktok</Text>
          <BarComponent name="tiktok" />
        </TouchableOpacity>
      );
    }
    return <></>;
  };

  const LemonBarComponent = () => {
    if (haveLM) {
      return (
        <TouchableOpacity
          style={styles.barContainer}
          onPress={() => {
            setSelectedBar("lemon");
            scrollToView(lemonRef);
          }}
        >
          <Text style={styles.boldBody1Text}>Lemon8</Text>
          <BarComponent name="lemon" />
        </TouchableOpacity>
      );
    }
    return <></>;
  };

  const ReviewsBarComponent = () => {
    if (haveReviews) {
      return (
        <TouchableOpacity
          style={styles.barContainer}
          onPress={() => {
            setSelectedBar("review");
            scrollToView(reviewRef);
          }}
        >
          <Text style={styles.boldBody1Text}>Review</Text>
          <BarComponent name="review" />
        </TouchableOpacity>
      );
    }
    return <></>;
  };

  const BarComponents = () => {
    return (
      <View style={styles.rowContainer}>
        <TouchableOpacity
          style={styles.barContainer}
          onPress={() => {
            setSelectedBar("default");
            scrollToView(overviewRef);
          }}
        >
          <Text style={styles.boldBody1Text}>Overview</Text>
          <BarComponent name="default" />
        </TouchableOpacity>
        <InstagramBarComponent />
        <TiktokBarComponent />
        <LemonBarComponent />
        <ReviewsBarComponent />
      </View>
    );
  };

  useEffect(() => {
    if (influencer.id === "" && !influencerLoading) {
      if (route.pathname.includes("brand")) {
        navigation("/brand/list");
      } else {
        navigation("/influencer/list");
      }
    }
  }, []);

  const InstagramComponent = () => {
    if (haveIG) {
      return (
        <View ref={instagramRef}>
          <InfluencerDetailInstagram
            influencer={influencer}
            onUnlink={() => unlinkSocial("ig")}
          />
        </View>
      );
    }
    return <></>;
  };

  const TiktokComponent = () => {
    if (haveTK) {
      return (
        <View ref={tiktokRef}>
          <InfluencerDetailTiktok
            influencer={influencer}
            onUnlink={() => unlinkSocial("tk")}
          />
        </View>
      );
    }
    return <></>;
  };

  const LemonComponent = () => {
    if (haveLM) {
      return (
        <View ref={lemonRef}>
          <InfluencerDetailLemon influencer={influencer} />
        </View>
      );
    }
    return <></>;
  };

  const ReviewComponent = () => {
    if (haveReviews) {
      return (
        <View ref={reviewRef}>
          <InfluencerDetailReview influencer={influencer} />
        </View>
      );
    }
    return <></>;
  };

  if (influencerLoading) {
    return <ActivityIndicator style={{ marginTop: 32 }} />;
  }

  return (
    <View>
      <InfluencerDetailHeader influencer={influencer} />
      <BarComponents />
      <View ref={overviewRef}>
        <InfluencerDetailOverview
          influencer={influencer}
          loading={influencerLoading}
        />
      </View>
      <InstagramComponent />
      <TiktokComponent />
      <LemonComponent />
      <ReviewComponent />
    </View>
  );
};

export default observer(InfluencerListScreen);

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    backgroundColor: COLOR.WHITE,
    zIndex: 999,
    ...SHADOW.BLACK5,
  },
  barContainer: {
    position: "relative",
    width: 140,
    height: 48,
    alignItems: "center",
    justifyContent: "center",
    overflow: "visible",
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  activeContainer: {
    position: "absolute",
    bottom: 0,
    height: 4,
    width: 120,
    backgroundColor: COLOR.GREEN,
    borderRadius: 2,
    zIndex: 9999,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
  },
});
