import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import RButton from "../reusable/RButton";
import RXModal from "../reusable/RXModal/RXModal";

interface Props {
  activeModal: boolean;
  setActiveModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSend: () => void;
}

const BannerDeleteModal = (props: Props) => {
  const { activeModal, setActiveModal, onSend } = props;

  return (
    <RXModal visible={activeModal} onClose={() => setActiveModal(false)}>
      <View style={styles.modalContainer}>
        <Text style={styles.boldHeader5Text}>ยืนยันลบแบนเนอร์</Text>
        <Text style={styles.body1Text}>
          หากลบแล้วจะไม่สามารถไม่สามารถกู้คืนได้
        </Text>
        <View style={styles.buttonContainer}>
          <RButton
            title="ยกเลิก"
            onPress={() => setActiveModal(false)}
            containerStyle={{
              width: 150,
              borderColor: COLOR.GREEN,
              borderWidth: 1,
              backgroundColor: COLOR.WHITE,
            }}
            textStyle={{ color: COLOR.DARKGREEN, marginLeft: 4 }}
          />
          <RButton
            title="ยืนยัน"
            onPress={() => {
              onSend();
              setActiveModal(false);
            }}
            containerStyle={{ width: 150 }}
            textStyle={{ marginLeft: 4 }}
          />
        </View>
      </View>
    </RXModal>
  );
};

export default BannerDeleteModal;

const styles = StyleSheet.create({
  modalContainer: {
    width: 394,
    borderRadius: 24,
    backgroundColor: COLOR.WHITE,
    flexDirection: "column",
    alignItems: "center",
    padding: 32,
    gap: 8,
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
    marginTop: 16,
  },
  columnContainer: {
    flexDirection: "column",
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
  },
  body1Text: {
    ...TEXT.body1,
    maxWidth: 270,
    overflow: "hidden",
  },
});
