import React, { useState } from "react";
import { StyleSheet, View, Text } from "react-native";
import { IInfluencerAccount } from "../../types/influencerAccount";
import TiktokCard from "../molecules/TiktokCard";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import TiktokRecentMedia from "../molecules/TiktokRecentMedia";
import UnlinkModal from "./UnlinkModal";
import RButton from "../reusable/RButton";

interface Props {
  influencer: IInfluencerAccount;
  onUnlink: () => void;
}

const InfluencerDetailTiktok = (props: Props) => {
  const { influencer, onUnlink } = props;

  const [showModal, setShowModal] = useState<string>("");

  const renderTiktokCard = () => {
    if (!!influencer.tiktok) {
      return <TiktokCard tiktok={influencer.tiktok} />;
    }
  };

  const renderRecentMedia = () => {
    if (
      !!influencer.tiktok?.recentMedia &&
      influencer.tiktok?.recentMedia.length > 0
    ) {
      return (
        <TiktokRecentMedia
          medias={influencer.tiktok.recentMedia}
          username={influencer.tiktok.username}
        />
      );
    }
  };

  const renderModal = () => {
    if (showModal === "unlink") {
      return (
        <UnlinkModal
          visible={true}
          onClose={() => {
            setShowModal("");
          }}
          onApprove={onUnlink}
          influencer={influencer}
        />
      );
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.spaceBetweenRowContainer}>
        <Text style={styles.boldHeader5Text}>Tiktok</Text>
        <RButton
          title="ยกเลิกการเชื่อมต่อ"
          onPress={() => setShowModal("unlink")}
          containerStyle={styles.buttonStyle}
        />
      </View>
      {renderTiktokCard()}
      {renderRecentMedia()}
      {renderModal()}
    </View>
  );
};

export default InfluencerDetailTiktok;

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 24,
    padding: 32,
    backgroundColor: COLOR.WHITE,
  },
  spaceBetweenRowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 928,
  },
  buttonStyle: {
    backgroundColor: COLOR.PINK,
    paddingHorizontal: 16,
    width: 200,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
  },
});
