import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { useNavigate } from "react-router-dom";
import {
  PLACEHOLDER_IMAGE,
  mapCategoryIcon,
  mapPlatformIcon,
} from "../../components/Util";
import ListItem, { TitleKey } from "../../components/reusable/ListItem";
import {
  useInfluencerStore,
  useNavigationStore,
  useVerificationStore,
} from "../../stores/storeContexts";
import { COLOR, SHADOW } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import ActivityIndicator from "../../components/ActivityIndicator";

const titles: Array<TitleKey> = [];

const keys = [
  { display: "Influencer Name", value: "displayName", size: 2 },
  {
    display: "Influencer Interest",
    value: "interests",
    size: 2,
  },
  {
    display: "Is invited",
    value: "invited",
    size: 1,
  },
  {
    display: "Is Nego",
    value: "negotiate",
    size: 1,
  },
  {
    display: "Budget",
    value: "cash",
    size: 1,
  },
  {
    display: "Net Pay",
    value: "productValue",
    size: 1,
  },
  {
    display: "Status",
    value: "status",
    size: 1,
  },
  {
    display: "Rating",
    value: "rate",
    size: 1,
  },
  {
    display: "Request At",
    value: "createdAt",
    size: 1,
  },
  {
    display: "Last Edit At",
    value: "createdAt",
    size: 1,
  },
];

keys.forEach((key) => {
  titles.push({ display: key.display, size: key.size });
});

const DealDetailScreen = () => {
  const { deal, setSortBy, setSortOrder } = useVerificationStore();
  const { getInfluencer } = useInfluencerStore();
  const { pushStack } = useNavigationStore();

  const navigation = useNavigate();
  const {
    sortBy = { display: "createdAt", value: "createdAt" },
    sortOrder,
    detail = {
      name: "",
      category: "",
      photos: [],
      platform: [],
    },
    data = [],
    loading = false,
  } = deal;
  const [key, setKey] = useState<string>("createdAt");
  const [sort, swapSort] = useState<"asc" | "desc">("desc");

  const setSort = (key: string) => {
    if (sortBy.display !== key) {
      setSortBy(
        "brands",
        "deal",
        keys[keys.findIndex((keyIndex) => keyIndex.display === key)]
      );
      setSortOrder("brands", "deal", "asc");
    } else {
      if (sortOrder === "asc") {
        setSortOrder("brands", "deal", "desc");
      } else if (sortOrder === "desc") {
        setSortOrder("brands", "deal", "desc");
        setSortBy("brands", "deal", {
          display: "createdAt",
          value: "createdAt",
        });
      }
    }
  };

  const renderImage = () => {
    if (detail?.photos?.length > 0) {
      return detail.photos[0];
    } else {
      return PLACEHOLDER_IMAGE;
    }
  };

  const renderPlatforms = () => {
    if (detail?.platforms?.length > 0) {
      return (
        <View style={{ flexDirection: "row", gap: 8 }}>
          {detail.platforms.map((platform: string) => {
            return mapPlatformIcon(platform)?.icon;
          })}
        </View>
      );
    }
  };

  const renderHeaderBrand = () => {
    return (
      <View style={styles.rowContainer}>
        <View style={{ position: "relative", marginRight: 18 }}>
          <Image
            source={{ uri: renderImage() }}
            style={{
              width: 120,
              height: 120,
              borderRadius: 12,
            }}
          />
        </View>
        <View style={styles.columnContainer}>
          <Text style={styles.buttonBoldText}>{detail.name}</Text>
          <View style={styles.categoryContainer}>
            <Text style={[styles.body1Text, { color: COLOR.DARKGREEN }]}>
              {mapCategoryIcon(detail.category)?.icon + " " + detail.category}
            </Text>
          </View>
          {renderPlatforms()}
        </View>
      </View>
    );
  };

  useEffect(() => {
    setSort(key);
  }, [key, sort]);

  useEffect(() => {
    if (data.length === 0 && !loading) {
      navigation("/brand/list");
    }
  }, []);

  if (deal.loading) {
    return <ActivityIndicator style={{ marginTop: 32 }} />;
  }

  return (
    <View style={{ flex: 1 }}>
      {renderHeaderBrand()}
      <View>
        <ListItem
          keys={keys}
          title={titles}
          selectedList={[]}
          setSelectedList={() => {}}
          sortOrder={sort}
          sortKey={sortBy.display}
          setSortKey={setKey}
          swapSort={() => {
            if (sort === "asc") {
              swapSort("desc");
            } else {
              swapSort("asc");
            }
          }}
          containerStyle={[
            SHADOW.BLACK5,
            {
              zIndex: 999,
            },
          ]}
        />
        {data.map((element, index) => (
          <ListItem
            key={element.brandId}
            keys={keys}
            ignored={["_id"]}
            data={element}
            selectedList={[]}
            setSelectedList={() => {}}
            index={index}
            onPress={() => {
              getInfluencer(element.accountId);
              pushStack({
                display: element?.displayName || "Influencer Detail",
                path: "/brand/list",
              });
              navigation("/brand/infludetail");
            }}
            nameContainerStyle={styles.nameContainer}
            containerStyle={styles.listContainer}
          />
        ))}
      </View>
    </View>
  );
};

export default observer(DealDetailScreen);

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: 104,
    padding: 16,
    backgroundColor: COLOR.WHITE,
  },
  columnContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: 16,
  },
  centerColumnContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
  },
  smallEmailStatusContainer: {
    position: "absolute",
    width: 21,
    height: 21,
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.WHITE,
    borderWidth: 3,
    bottom: 0,
    right: 0,
    borderRadius: 28,
    alignItems: "center",
    justifyContent: "center",
  },
  categoryContainer: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    borderRadius: 20,
    backgroundColor: COLOR.LIGHTGREEN,
  },
  listContainer: {
    minHeight: 40,
  },
  nameContainer: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    paddingLeft: 10,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
  },
  body1Text: {
    ...TEXT.body1,
  },
  buttonBoldText: {
    ...TEXT.buttonBOLD,
  },
  body2Text: {
    ...TEXT.body2,
  },
});
