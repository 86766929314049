import { makeAutoObservable, runInAction } from 'mobx';
import {
  getAuth,
  OAuthProvider,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import { Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Firebase } from '../class/Firebase';
import { microsoftAPI, MicrosoftMeResponse } from '../api/microsoft';
import { picklePOST } from '../api/pickle';

const saveAccessToken = async (accessToken: string) => {
  await AsyncStorage.setItem('accessToken', accessToken);
};

const removeAccessToken = async () => {
  await AsyncStorage.removeItem('accessToken');
};

export const getAccessToken = async () => {
  return await AsyncStorage.getItem('accessToken');
};

export class AuthStore {
  account: MicrosoftMeResponse | undefined;
  isLoggedIn = false;
  authStorage = '';
  constructor() {
    makeAutoObservable(this, {});
    const FirebaseApp = new Firebase();
    FirebaseApp.init();
    // loadAccountDetail
    getAccessToken().then((accessToken) => {
      if (accessToken) {
        this.getMicroftUserDetail(accessToken);
      }
    });
  }

  loginWithMicrosoft = () => {
    const Provider = new OAuthProvider('microsoft.com');
    const auth = getAuth();
    signInWithRedirect(auth, Provider)
      .then((result) => {
        console.log(result);
        const credential = OAuthProvider.credentialFromResult(result);
        if (!credential) {
          throw new Error();
        }

        const accessToken = credential.accessToken;
        if (!accessToken) {
          throw new Error();
        }
        saveAccessToken(accessToken);
        this.getMicroftUserDetail(accessToken);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  login = async (username: string, password: string) => {
    try {
      const loginResult = await picklePOST('/backoffice/login', {
        username,
        password,
      });
      if (!!loginResult) {
        localStorage.setItem('auth', 'success');
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  logout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      runInAction(() => {
        localStorage.setItem('auth', '');
        this.isLoggedIn = false;
      });
      removeAccessToken();
    });
    // window.location.href =
    //   "https://login.microsoftonline.com/b4045290-7f3e-40ea-ab8e-0a7720f23037/oauth2/v2.0/logout";
  };

  get getAccount() {
    return this.account;
  }

  setAccount = (account: MicrosoftMeResponse) => {
    // logic if account is not our internal account
    if (!account.mail.includes('@pickle.co.th')) {
      Alert.alert('ขออภัย', 'คุณไม่สามารถเข้าสู่ระบบได้');
      this.logout();
      return;
    }

    runInAction(() => {
      this.isLoggedIn = true;
      this.account = account;
    });
  };

  private getMicroftUserDetail = async (
    accessToken?: string
  ): Promise<MicrosoftMeResponse | null> => {
    if (!accessToken) {
      return null;
    }
    const data = await microsoftAPI<MicrosoftMeResponse>(`/me`, {
      Authorization: `Bearer ${accessToken}`,
    });
    this.setAccount(data);

    return data;
  };
}

export default new AuthStore();
