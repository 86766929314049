import React from "react";
import { Dimensions, View } from "react-native";
import Text from "./Text";
import RIcon from "./reusable/RIcon";
import { picklePOST } from "../api/pickle";
import {
  IFacebookInfluencer,
  IInstagramInfluencer,
  ITiktokInfluencer,
} from "../types/influencerAccount";

export const SOCIAL_PATTERN = /(?:instagram\.com|tiktok\.com)/;

export const HTTP_PATTERN = /^(https?:\/\/)/;

export const FACEBOOK_PATTERN = /(?:facebook\.com)/;

export const FieldComponent = (props: {
  title: string;
  children: string | JSX.Element | undefined;
}) => {
  const { children, title } = props;
  if (!children) return null;
  return (
    <View style={{ marginBottom: 5 }}>
      <Text weight="bold">{title}</Text>
      {typeof children === "string" ? <Text>{children}</Text> : children}
    </View>
  );
};

export const isNull = (
  data: string | IInstagramInfluencer | ITiktokInfluencer | IFacebookInfluencer
) => {
  if (typeof data === "string") return data === "";
  return !data;
};

export const findAllIndexes = (
  array: Array<any>,
  predicate: (value: any, index: number, obj: any[]) => boolean
): number[] => {
  const indexes = [];
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) {
      indexes.push(l);
    }
  }
  return indexes;
};

export const difTime = (
  comparedTime: string | Date,
  isPass = true,
  fullword = false,
  isDayUnit = false
) => {
  if (!comparedTime) return "";
  const compared = new Date(comparedTime);
  const now = new Date();
  const Difference_In_Time = isPass
    ? (now.getTime() - compared.getTime()) / 1000
    : (compared.getTime() - now.getTime()) / 1000; // second
  if (Difference_In_Time < 0) {
    return `0 ${fullword ? "day" : "d"}`;
  }
  if (isDayUnit) {
    return `${Math.round(Difference_In_Time / 86400)} ${
      fullword ? "days" : "d"
    }`;
  }
  if (Difference_In_Time > 31536000) {
    return `${Math.round(Difference_In_Time / 31536000)} ${
      fullword ? "years" : "y"
    }`;
  }
  if (Difference_In_Time > 2629746) {
    return `${Math.round(Difference_In_Time / 2629746)} ${
      fullword ? "months" : "m"
    }`;
  }
  if (Difference_In_Time > 604800) {
    return `${Math.round(Difference_In_Time / 604800)} ${
      fullword ? "weeks" : "w"
    }`;
  }
  if (Difference_In_Time > 86400) {
    return `${Math.round(Difference_In_Time / 86400)} ${
      fullword ? "days" : "d"
    }`;
  }
  if (Difference_In_Time > 3600) {
    return `${Math.round(Difference_In_Time / 3600)} ${
      fullword ? "hours" : "h"
    }`;
  }
  if (Difference_In_Time > 60) {
    return `${Math.round(Difference_In_Time / 60)} ${
      fullword ? "minutes" : "m"
    }`;
  }
  return `${Math.round(Difference_In_Time)} ${fullword ? "seconds" : "s"}`;
};

export const percentage = (first: number, second: number) => {
  if (first >= second) {
    return "100%";
  }
  return Math.round(first / second) + "%";
};

export const minusHours = (ISOString: string, numberofhour: number) => {
  var d = new Date(ISOString);
  d.setHours(d.getHours() - numberofhour);
  return d.toISOString();
};

export const minusMinutes = (ISOString: string, numberofminute: number) => {
  var d = new Date(ISOString);
  d.setMinutes(d.getMinutes() - numberofminute);
  return d.toISOString();
};

export const getDaysArray = function (start: Date, end: Date) {
  if (start > end) return [];
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const toMonthYear = (dueDate: string) => {
  if (!dueDate) {
    return "";
  }
  var month = new Array();
  month[0] = "Jan";
  month[1] = "Feb";
  month[2] = "Mar";
  month[3] = "Apr";
  month[4] = "May";
  month[5] = "Jun";
  month[6] = "Jul";
  month[7] = "Aug";
  month[8] = "Sep";
  month[9] = "Oct";
  month[10] = "Nov";
  month[11] = "Dec";
  const date = new Date(dueDate);
  return `${month[date.getMonth()]}, ${date.getFullYear()}`;
};

export const numberWithCommas = (x: string | number) => {
  if (!x) {
    return "0";
  }
  let s = x;
  if (typeof x === "number") {
    if (x % 1 !== 0) s = x.toFixed(2);
  }
  return s?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const reviewTagBinaryConverter = (index: number) => {
  switch (index) {
    case 0:
      return "ทำตามบรีฟ";
    case 1:
      return "ถ่ายรูปสวย";
    case 2:
      return "ตอบแชทไว";
    case 3:
      return "ส่งงานตรงเวลา";
    case 4:
      return "ความคิดสร้างสรรค์";
  }
};

const interestCategory: Array<{
  value: string;
  icon: string;
}> = [
  {
    value: "Application & Service",
    icon: "📱",
  },
  {
    value: "Art & Stationary",
    icon: "🎨",
  },
  {
    value: "Beauty & Cosmetics",
    icon: "💄",
  },
  {
    value: "Cafe & Restaurant",
    icon: "🍰",
  },
  {
    value: "Family & Toy",
    icon: "🍼",
  },
  {
    value: "Fashion & Accessory",
    icon: "👗",
  },
  {
    value: "Finance",
    icon: "💰",
  },
  {
    value: "Food & Beverage",
    icon: "🍔",
  },
  {
    value: "Health & Sport",
    icon: "🏀",
  },
  {
    value: "Home & Decoration",
    icon: "🛋️",
  },
  {
    value: "Pet & Animal",
    icon: "🐶",
  },
  {
    value: "Real Estate & Property",
    icon: "🏢",
  },
  {
    value: "Technology & Electronics",
    icon: "📡",
  },
  {
    value: "Traveling & Event",
    icon: "🏝️",
  },
  {
    value: "Vehicle & Transport",
    icon: "🚗",
  },
];

export const platforms = [
  {
    value: "Instagram",
    icon: <RIcon name="IGCircle" />,
  },
  {
    value: "Tiktok",
    icon: <RIcon name="TiktokCircle" />,
  },
  {
    value: "Lemon",
    icon: <RIcon name="LemonCircle" />,
  },
];

export const mapCategoryIcon = (name: string) => {
  return interestCategory.find((x) => x.value === name);
};

export const mapPlatformIcon = (name: string) => {
  return platforms.find((x) => x.value === name);
};

export const checkImg = async (img: string) => {
  try {
    const result = await picklePOST("/influencers/checkimg", {
      img: img,
    });
    return result;
  } catch (error) {
    console.log(error);
    return true;
  }
};

export const PLACEHOLDER_IMAGE = require("../assets/Placeholder.jpg");

export const WINDOW_HEIGHT = Dimensions.get("window").height;

export const WINDOW_WIDTH = Dimensions.get("window").width;
