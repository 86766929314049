import { makeAutoObservable, runInAction } from "mobx";
import { pickleGET, picklePOST } from "../api/pickle";
import { IInfluencerAccount } from "../types/influencerAccount";

export class InfluencerStore {
  constructor() {
    makeAutoObservable(this);
  }

  influencer: IInfluencerAccount = {
    platform: "",
    displayName: "",
    grantedPermissions: [],
    declinedPermissions: [],
    data_access_expires_at: new Date(),
    instagramId: null,
    tiktokId: null,
    lemonUrl: null,
    dealContacts: [],
    agreed: false,
    contactTelephone: "",
    contactTel: "",
    deliveryPhone: "",
    addressRecipient: "",
    address: "",
    bank: "",
    bankNumber: "",
    bankOwnerName: "",
    interests: [],
    expoToken: [],
    profileImages: [],
    reSignedIn: false,
    feedConsent: false,
    birthdate: new Date(),
    gender: "",
    bodySize: {
      weight: "",
      height: "",
      bust: "",
      waist: "",
      hip: "",
      footwear: "",
    },
    province: "",
    introduce: "",
    isBanned: {
      status: false,
      reason: [],
      bannedAt: new Date(),
    },
    instagramUsername: "",
    tiktokUsername: "",
    followers_count: 0,
    id: "",
    email: "",
    name: "",
    type: "apple",
    pendingCount: 0,
    ongoingCount: 0,
    checkCount: 0,
    doneCount: 0,
    cancelledCount: 0,
    rejectedCount: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
  };

  influencerLoading: boolean = false;

  setInfluencer = (newInfluencer: IInfluencerAccount) => {
    this.influencer = newInfluencer;
  };

  getInfluencer = async (id: string) => {
    try {
      this.influencerLoading = true;
      const queryString = new URLSearchParams({
        id,
      });

      const data = await pickleGET<IInfluencerAccount>(
        `/influencers/auth/detail?${queryString.toString()}`
      );

      runInAction(() => {
        this.influencer = data;
      });
    } catch (error) {
      runInAction(() => {
        if (error instanceof Error) console.log(error);
      });
    } finally {
      runInAction(() => {
        this.influencerLoading = false;
      });
    }
  };

  unlink = async () => {
    try {
      await picklePOST("/backoffice/sna/disconnect/instagram", {
        influencerAccountId: this.influencer.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  unlinkTiktok = async () => {
    try {
      await picklePOST("/backoffice/sna/disconnect/tiktok", {
        influencerAccountId: this.influencer.id,
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export const influencerStore = new InfluencerStore();
