import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { IInfluencerAccount } from "../../types/influencerAccount";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import InfluencerReview from "../molecules/InfluencerReview";
import { numberWithCommas } from "../Util";

interface Props {
  influencer: IInfluencerAccount;
}

const InfluencerDetailReview = (props: Props) => {
  const { influencer } = props;

  const renderReview = () => {
    if (!!influencer.instagram?.reviewOverall) {
      return (
        <InfluencerReview
          reviewOverall={influencer.instagram.reviewOverall}
          reviews={influencer.instagram.reviews}
          influencerId={influencer.id}
        />
      );
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text style={styles.boldHeader5Text}>
          Review{" "}
          {numberWithCommas(
            influencer.instagram?.reviewOverall.avgRating || 0
          ) + "/5"}
        </Text>
        <Text style={styles.buttonText}>
          {(influencer.instagram?.reviewOverall.totalReview || 0) +
            " ความคิดเห็น"}
        </Text>
      </View>
      {renderReview()}
    </View>
  );
};

export default InfluencerDetailReview;

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    gap: 24,
    padding: 32,
    backgroundColor: COLOR.WHITE,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 928,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
  },
  buttonText: {
    ...TEXT.button,
  },
});
