import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import ActivityIndicator from "../../components/ActivityIndicator";
import { WINDOW_HEIGHT } from "../../components/Util";
import Search from "../../components/atoms/Search";
import BrandModal from "../../components/brand/BrandModal";
import ListItem, { TitleKey } from "../../components/reusable/ListItem";
import { useVerificationStore } from "../../stores/storeContexts";
import { COLOR } from "../../styles/Colors";
import { IBrandAccount } from "../../types/brandAccount";
import { formatDate } from "../../utils/formatDate";

const titles: Array<TitleKey> = [];

const keys = [
  { display: "Select", value: "select", size: 1 },
  {
    display: "Logo",
    value: "img",
    size: 1,
  },
  {
    display: "Category",
    value: "category",
    size: 2,
  },
  {
    display: "Brand Name",
    value: "name",
    size: 2,
  },
  {
    display: "Instagram",
    value: "instagram",
    size: 2,
  },
  {
    display: "Tiktok",
    value: "tiktok",
    size: 2,
  },
  {
    display: "Register At",
    value: "createdAt",
    size: 2,
  },
];

keys.forEach((key) => {
  titles.push({ display: key.display, size: key.size });
});

const VerificationScreen = () => {
  const {
    getBrands,
    brands,
    setSortBy,
    setSortOrder,
    selectedList,
    setSelectedList,
    setBrandsPage,
  } = useVerificationStore();

  const [key, setKey] = useState<string>("createdAt");
  const [sort, swapSort] = useState<"asc" | "desc">("desc");
  const [brandId, setBrandId] = useState<string | undefined>(undefined);
  const [keyword, setKeyword] = useState<string>("");
  const [date, setDate] = useState<Array<Date>>([]);

  const {
    sortBy = { display: "createdAt", value: "createdAt" },
    sortOrder,
    loading = false,
    data = [],
    page,
  } = brands;

  const setSort = (key: string) => {
    if (sortBy.display !== key) {
      setSortBy(
        "brands",
        "verification",
        keys[keys.findIndex((keyIndex) => keyIndex.display === key)]
      );
      setSortOrder("brands", "verification", "asc");
    } else {
      if (sortOrder === "asc") {
        setSortOrder("brands", "verification", "desc");
      } else if (sortOrder === "desc") {
        setSortOrder("brands", "verification", "desc");
        setSortBy("brands", "verification", {
          display: "createdAt",
          value: "createdAt",
        });
      }
    }
  };

  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    if (layoutMeasurement.height + contentOffset.y >= contentSize.height) {
      setBrandsPage(page + 1);
    }
  };

  const searchData = (brand: IBrandAccount) => {
    const lowerCaseKeyword = keyword.toLowerCase();
    return (
      (!!brand.name && brand.name.toLowerCase().includes(lowerCaseKeyword)) ||
      (!!brand.tiktok &&
        brand.tiktok.toLowerCase().includes(lowerCaseKeyword)) ||
      (!!brand.instagram &&
        brand.instagram.toLowerCase().includes(lowerCaseKeyword))
    );
  };

  const filterDate = (brand: IBrandAccount) => {
    const createdAt = new Date(brand.createdAt);
    switch (date.length) {
      case 0:
        return true;
      case 1:
        return (
          formatDate(createdAt.toString()) === formatDate(date[0].toString())
        );
      default:
        return !!createdAt && createdAt >= date[0] && createdAt <= date[1];
    }
  };

  const ListItemComponent = ({ brand }: { brand: IBrandAccount }) => {
    return (
      <ListItem
        color={brand.isVerified ? COLOR.LIGHTGREEN : COLOR.WHITE}
        onPress={() => {
          setBrandId(brand.brandId);
        }}
        key={brand.brandId}
        keys={keys}
        ignored={["_id", "brandId"]}
        data={brand}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        showVerify={true}
      />
    );
  };

  const TableComponent = () => {
    const listItems: Array<JSX.Element> = [];
    data.map((brand, index) => {
      if (!!keyword) {
        if (searchData(brand)) {
          listItems.push(<ListItemComponent brand={brand} />);
        }
      } else if (!!date[0]) {
        if (filterDate(brand)) {
          listItems.push(<ListItemComponent brand={brand} />);
        }
      } else if (index < page * 10) {
        listItems.push(<ListItemComponent brand={brand} />);
      }
    });
    return <View>{listItems}</View>;
  };

  useEffect(() => {
    getBrands(1);
  }, []);

  useEffect(() => {
    setSort(key);
  }, [key, sort]);

  if (loading) {
    return <ActivityIndicator style={{ marginTop: 32 }} />;
  }

  return (
    <View>
      <BrandModal
        brandId={brandId}
        visible={!!brandId}
        onClose={() => {
          setBrandId(undefined);
        }}
      />
      <ListItem
        keys={keys}
        title={titles}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        sortOrder={sort}
        sortKey={sortBy.display}
        setSortKey={setKey}
        swapSort={() => {
          if (sort === "asc") {
            swapSort("desc");
          } else {
            swapSort("asc");
          }
        }}
        showVerify={true}
      />
      <Search setKeyword={setKeyword} date={date} setDate={setDate} />
      <ScrollView
        onScroll={(event) => onScroll(event)}
        scrollEventThrottle={16}
        style={styles.scrollViewContainer}
      >
        <TableComponent />
      </ScrollView>
    </View>
  );
};

export default observer(VerificationScreen);

const styles = StyleSheet.create({
  scrollViewContainer: {
    height: WINDOW_HEIGHT - 170,
  },
});
