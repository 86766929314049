import { createContext, useContext } from "react";
import { AuthStore } from "./authStore";
import { VerificationStore } from "./verificationStore";
import { PermissionStore } from "./permissionStore";
import { NotificationStore } from "./notificationStore";
import { BrandStore } from "./brandStore";
import { InfluencerStore } from "./influencerStore";
import { NavigationStore } from "./navigationStore";

export const AuthStoreContext = createContext<AuthStore>(null!);

export const PermissionStoreContext = createContext<PermissionStore>(null!);

export const VerificationStoreContext = createContext<VerificationStore>(null!);

export const NotificationStoreContext = createContext<NotificationStore>(null!);

export const BrandStoreContext = createContext<BrandStore>(null!);

export const InfluencerStoreContext = createContext<InfluencerStore>(null!);

export const NavigationStoreContext = createContext<NavigationStore>(null!);

export const useAuthStore = () => {
  return useContext(AuthStoreContext);
};

export const useVerificationStore = () => {
  return useContext(VerificationStoreContext);
};

export const usePermissionStore = () => {
  return useContext(PermissionStoreContext);
};

export const useNotificationStore = () => {
  return useContext(NotificationStoreContext);
};

export const useBrandStore = () => {
  return useContext(BrandStoreContext);
};

export const useInfluencerStore = () => {
  return useContext(InfluencerStoreContext);
};

export const useNavigationStore = () => {
  return useContext(NavigationStoreContext);
};
