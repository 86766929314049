import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import DropDownPicker, {
  ItemType,
  ValueType,
} from "react-native-dropdown-picker";
import { COLOR } from "../../styles/Colors";

interface Props {
  options: ItemType<string | number | boolean>[];
  value: string;
  onValueChange: React.Dispatch<React.SetStateAction<ValueType | null>>;
  placeHolder?: string;
  containerStyle?: StyleProp<ViewStyle>;
  listItemStyle?: StyleProp<ViewStyle>;
  zIndex?: number;
  listMode?: "DEFAULT" | "FLATLIST" | "MODAL" | "SCROLLVIEW";
  disabled?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RDropDown = (props: Props) => {
  const {
    options = [],
    value,
    onValueChange,
    placeHolder = "Name",
    containerStyle = {},
    zIndex,
    listMode = "FLATLIST",
    disabled = false,
    listItemStyle,
    open,
    setOpen,
  } = props;

  return (
    <DropDownPicker
      disabled={disabled}
      listMode={listMode}
      zIndex={zIndex}
      style={[
        {
          flexDirection: "row",
          alignContent: "center",
          borderRadius: 8,
          borderWidth: 1,
          borderColor: COLOR.GRAY,
          height: "100%",
          width: "100%",
        },
        containerStyle,
      ]}
      dropDownContainerStyle={[
        {
          borderWidth: 1,
          borderRadius: 8,
          borderColor: COLOR.GRAY,
        },
        listItemStyle,
      ]}
      listItemLabelStyle={{
        padding: 5,
        color: COLOR.GRAY,
      }}
      selectedItemLabelStyle={{
        color: COLOR.BLACK,
      }}
      showTickIcon={false}
      itemSeparator={true}
      itemSeparatorStyle={{
        backgroundColor: COLOR.GRAY,
      }}
      listItemContainerStyle={{
        marginVertical: 5,
      }}
      open={open}
      setOpen={setOpen}
      value={value}
      setValue={onValueChange}
      items={options}
      placeholder={placeHolder}
    />
  );
};

export default RDropDown;
