import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import ActivityIndicator from "../../components/ActivityIndicator";
import { WINDOW_HEIGHT } from "../../components/Util";
import Search from "../../components/atoms/Search";
import InfluencerModal from "../../components/influencer/InfluencerModal";
import RejectModal from "../../components/molecules/RejectModal";
import ListItem, { TitleKey } from "../../components/reusable/ListItem";
import { useVerificationStore } from "../../stores/storeContexts";
import { COLOR } from "../../styles/Colors";
import {
  IInfluencerAccount,
  initialInfluencerAccount,
} from "../../types/influencerAccount";
import { formatDate } from "../../utils/formatDate";

const titles: Array<TitleKey> = [];

const keys = [
  { display: "Select", value: "select", size: 1 },
  {
    display: "Image",
    value: "profile_picture_url",
    size: 1,
  },
  {
    display: "Interest",
    value: "interests",
    size: 2,
  },
  {
    display: "Pickle Username",
    value: "displayName",
    size: 2,
  },
  {
    display: "Social",
    value: "socialName",
    size: 2,
  },
  {
    display: "Register At",
    value: "createdAt",
    size: 2,
  },
];

keys.forEach((key) => {
  titles.push({ display: key.display, size: key.size });
});

const InfluVerificationScreen = () => {
  const {
    getInfluencers,
    influencers,
    setSortBy,
    setSortOrder,
    approveInflu,
    rejectInflu,
    selectedList,
    setSelectedList,
    setInfluencersPage,
  } = useVerificationStore();

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalData, setModalData] = useState<IInfluencerAccount>(
    initialInfluencerAccount
  );
  const [rejectModalVisible, setRejectModalVisible] = useState<boolean>(false);
  const [rejectReason, setRejectReason] = useState<string>("");
  const [data, setData] = useState<{
    instagramId?: string;
    tiktokId?: string;
    facebookId?: string;
  }>({
    instagramId: undefined,
    tiktokId: undefined,
    facebookId: undefined,
  });
  const [key, setKey] = useState<string>("createdAt");
  const [sort, swapSort] = useState<"asc" | "desc">("desc");
  const [keyword, setKeyword] = useState<string>("");
  const [date, setDate] = useState<Array<Date>>([]);

  const {
    sortBy = { display: "createdAt", value: "createdAt" },
    sortOrder,
    loading = false,
    page = 0,
    data: influData = [],
  } = influencers;

  const setSort = (key: string) => {
    if (sortBy.display !== key) {
      setSortBy(
        "influencers",
        "verification",
        keys[keys.findIndex((keyIndex) => keyIndex.display === key)]
      );
      setSortOrder("influencers", "verification", "asc");
    } else {
      if (sortOrder === "asc") {
        setSortOrder("influencers", "verification", "desc");
      } else if (sortOrder === "desc") {
        setSortOrder("influencers", "verification", "desc");
        setSortBy("influencers", "verification", {
          display: "createdAt",
          value: "createdAt",
        });
      }
    }
  };

  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    if (layoutMeasurement.height + contentOffset.y >= contentSize.height) {
      setInfluencersPage(page + 1);
    }
  };

  useEffect(() => {
    getInfluencers(1).catch();
  }, []);

  useEffect(() => {
    setSort(key);
  }, [key, sort]);

  const onApproveInflu = async () => {
    if (modalData.platform === "TIKTOK") {
      await approveInflu(modalData.id, undefined, undefined);
    } else if (modalData.platform === "FACEBOOK_PAGE") {
      await approveInflu(undefined, undefined, modalData.id);
    } else {
      await approveInflu(undefined, modalData.id, undefined);
    }
    setModalVisible(false);
  };

  const onRejectPress = async () => {
    if (modalData.platform === "TIKTOK") {
      setData({ tiktokId: modalData.id });
    } else if (modalData.platform === "FACEBOOK_PAGE") {
      setData({ facebookId: modalData.id });
    } else {
      setData({ instagramId: modalData.id });
    }
    setRejectModalVisible(true);
  };

  const onRejectInflu = async () => {
    setRejectModalVisible(false);
    setModalVisible(false);
    const result = await rejectInflu(
      data.tiktokId,
      data.instagramId,
      data.facebookId,
      rejectReason
    );
    // log for easy tracking on website
    console.log(result);
    console.log("finished rejecting");
  };

  const searchData = (influencer: IInfluencerAccount) => {
    const lowerCaseKeyword = keyword.toLowerCase();
    return (
      (!!influencer.displayName &&
        influencer.displayName.toLowerCase().includes(lowerCaseKeyword)) ||
      (!!influencer.name &&
        influencer.name.toLowerCase().includes(lowerCaseKeyword)) ||
      (!!influencer.contactName &&
        influencer.contactName.toLowerCase().includes(lowerCaseKeyword)) ||
      (!!influencer.tiktokUsername &&
        influencer.tiktokUsername.toLowerCase().includes(lowerCaseKeyword)) ||
      (!!influencer.instagramUsername &&
        influencer.instagramUsername.toLowerCase().includes(lowerCaseKeyword))
    );
  };

  const filterDate = (influencer: IInfluencerAccount) => {
    const createdAt = new Date(influencer.createdAt);
    switch (date.length) {
      case 0:
        return true;
      case 1:
        return (
          formatDate(createdAt.toString()) === formatDate(date[0].toString())
        );
      default:
        return !!createdAt && createdAt >= date[0] && createdAt <= date[1];
    }
  };

  const ListComponent = ({
    influencer,
  }: {
    influencer: IInfluencerAccount;
  }) => {
    const onPress = () => {
      setModalData(influencer);
      setModalVisible(true);
    };
    return (
      <ListItem
        color={influencer.isVerified ? COLOR.LIGHTGREEN : COLOR.WHITE}
        keys={keys}
        data={influencer}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        onPress={onPress}
        useEmailVerify={false}
      />
    );
  };

  const TableComponent = () => {
    const listItems: Array<JSX.Element> = [];
    influData?.map((item, index) => {
      if (!!keyword) {
        if (searchData(item)) {
          listItems.push(<ListComponent influencer={item} />);
        }
      } else if (!!date[0]) {
        if (filterDate(item)) {
          listItems.push(<ListComponent influencer={item} />);
        }
      } else if (index < page * 10) {
        listItems.push(<ListComponent influencer={item} />);
      }
    });
    return <View>{listItems}</View>;
  };

  if (loading) {
    return <ActivityIndicator style={{ marginTop: 32 }} />;
  }

  return (
    <View>
      <InfluencerModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        onApprove={onApproveInflu}
        onReject={onRejectPress}
        data={modalData}
      />
      <RejectModal
        visible={rejectModalVisible}
        onClose={() => {
          setRejectModalVisible(false);
        }}
        onReject={onRejectInflu}
        value={rejectReason}
        onChange={(reason) => {
          setRejectReason(reason);
        }}
      />
      <ListItem
        keys={keys}
        title={titles}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
        sortOrder={sort}
        sortKey={sortBy.display}
        setSortKey={setKey}
        swapSort={() => {
          if (sort === "asc") {
            swapSort("desc");
          } else {
            swapSort("asc");
          }
        }}
      />
      <Search setKeyword={setKeyword} date={date} setDate={setDate} />
      <ScrollView
        style={styles.scrollViewContainer}
        onScroll={(event) => onScroll(event)}
        scrollEventThrottle={16}
      >
        <TableComponent />
      </ScrollView>
    </View>
  );
};

export default observer(InfluVerificationScreen);

const styles = StyleSheet.create({
  scrollViewContainer: {
    height: WINDOW_HEIGHT - 170,
  },
});
