import React from "react";
import {
  ActivityIndicator as RNActivityIndicator,
  ActivityIndicatorProps,
} from "react-native";
import { COLOR } from "../styles/Colors";

const ActivityIndicator = (props: Omit<ActivityIndicatorProps, "color">) => {
  return <RNActivityIndicator {...props} color={COLOR.GREEN} />;
};

export default ActivityIndicator;
