import React, { useEffect, useState } from "react";
import { Text, TouchableOpacity, View, StyleSheet } from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { IInfluencerReview } from "../../types/influencerAccount";
import { reviewTagBinaryConverter } from "../Util";
import EmptyState from "../atoms/EmptyState";
import { PNGIcon } from "../reusable/RIcon";
import InfluReviewTagDisplay from "./InfluReviewTagDisplay";
import InfluReviewPreview from "./InfluencerReviewPreview";
import { useVerificationStore } from "../../stores/storeContexts";
import { observer } from "mobx-react";
import ActivityIndicator from "../ActivityIndicator";

interface Props {
  reviewOverall: {
    avgRating: number;
    totalReview: number;
    tagCount?: number[];
  };
  reviews: IInfluencerReview[];
  influencerId: string;
}

const InfluencerReview = (props: Props) => {
  const { reviews = [], reviewOverall, influencerId } = props;
  const { influencerReviewList, getInfluencerReviews, clearReviewList } =
    useVerificationStore();

  const [currentReviews, setCurrentReviews] = useState<
    Array<IInfluencerReview>
  >([]);

  const { tagCount = [0, 0, 0, 0, 0] } = reviewOverall;

  const { loading } = influencerReviewList;

  const reviewTags: { icon: PNGIcon; text: string; value: number }[] = [
    {
      icon: "TermsFill",
      text: "ทำตามบรีฟ",
      value: tagCount[0],
    },
    {
      icon: "Camera",
      text: "ถ่ายรูปสวย",
      value: tagCount[1],
    },
    {
      icon: "ChatFill",
      text: "ตอบแชทไว",
      value: tagCount[2],
    },
    {
      icon: "ClockFill",
      text: "ส่งงานตรงเวลา",
      value: tagCount[3],
    },
    {
      icon: "CreativeFill",
      text: "ความคิดสร้างสรรค์",
      value: tagCount[4],
    },
  ];

  const comments = currentReviews.map((review) => {
    const images: Array<string | undefined> = [];

    review.media.forEach((media) => {
      switch (media.media_type) {
        case "IMAGE":
          images.push(media.media_url);
          break;
        case "VIDEO":
          images.push(media.thumbnail_url);
          break;
        case "CAROUSEL_ALBUM":
          media.children?.data.forEach((child) => {
            if (child.media_type === "VIDEO") {
              images.push(child.thumbnail_url);
            } else if (child.media_type === "IMAGE") {
              images.push(child.media_url);
            }
          });
          break;
        default:
      }
    });

    let brandTitle = review.brand.name;
    const reviewTags = review.review?.reviewTag || [];
    let tag: Array<string | undefined> = [];
    if (!!reviewTags.length) {
      tag = reviewTags.reduce(
        (
          result: Array<string | undefined>,
          reviewTag: number,
          index: number
        ) => {
          if (reviewTag !== 0) {
            result.push(reviewTagBinaryConverter(index));
          }
          return result;
        },
        []
      );
    }

    return (
      <InfluReviewPreview
        key={review._id}
        isIncognito={review.isIncognito}
        rating={review.review.rate}
        tag={tag || []}
        brandImageUri={review.brand.img}
        brandTitle={brandTitle}
        comment={review.review.commend}
        dayPast={review.doneAt}
        workImages={images.slice(0, 5)}
      />
    );
  });

  const ButtonComponent = () => {
    if (currentReviews.length === 0) return <></>;
    if (loading) return <ActivityIndicator style={styles.loading} />;
    return (
      <TouchableOpacity
        onPress={() => {
          getInfluencerReviews(influencerId, influencerReviewList.page + 1);
        }}
        style={styles.button}
      >
        <Text style={styles.boldBody1text}>ดูเพิ่มเติม</Text>
      </TouchableOpacity>
    );
  };

  useEffect(() => {
    if (influencerReviewList.data.length !== 0) {
      setCurrentReviews(influencerReviewList.data);
    } else {
      setCurrentReviews(reviews);
    }
  }, [influencerReviewList.data.length]);

  useEffect(() => {
    clearReviewList();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <InfluReviewTagDisplay reviewTags={reviewTags} />
      </View>

      {currentReviews.length > 0 ? (
        comments
      ) : (
        <EmptyState
          title="ไม่พบความคิดเห็น"
          subtitle={["กรุณาตรวจสอบตัวกรอง"]}
          imageContainerStyle={styles.imageContainer}
        />
      )}
      <ButtonComponent />
    </View>
  );
};

export default observer(InfluencerReview);

const styles = StyleSheet.create({
  container: {
    width: 928,
    minHeight: 300,
    borderRadius: 12,
    marginTop: 16,
  },
  contentContainer: { marginBottom: 16 },
  imageContainer: {
    marginBottom: -40,
  },
  button: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 12,
    paddingBottom: 13,
  },
  loading: {
    paddingTop: 12,
    paddingBottom: 13,
  },
  boldBody1text: { ...TEXT.body1BOLD, color: COLOR.DARKGREEN },
});
