import { observer } from "mobx-react";
import React, { useState } from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";
import RButton from "../components/reusable/RButton";
import { COLOR } from "../styles/Colors";
import { TEXT } from "../styles/Text";

const DeleteAccountScreen = () => {
  const [id, setId] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  return (
    <View style={styles.container}>
      <Text style={styles.boldHeader5Text}>Delete Account</Text>
      <View style={styles.rowContainer}>
        <Text style={styles.boldBody1Text}>Influencer ID</Text>
        <Text style={[styles.boldBody1Text, { width: 10 }]}>:</Text>
        <TextInput
          value={id}
          onChangeText={(text: string) => setId(text)}
          placeholder="User ID"
          placeholderTextColor={COLOR.GRAY}
          style={styles.textContainer}
        />
      </View>

      <View style={styles.rowContainer}>
        <Text style={styles.boldBody1Text}>Email</Text>
        <Text style={[styles.boldBody1Text, { width: 10 }]}>:</Text>
        <TextInput
          value={email}
          onChangeText={(text: string) => setEmail(text)}
          placeholder="email@domain.com"
          placeholderTextColor={COLOR.GRAY}
          style={styles.textContainer}
        />
      </View>

      <RButton
        onPress={() => {}}
        title="Delete Account"
        textStyle={{ marginLeft: 4 }}
        containerStyle={{
          width: 208,
          marginTop: 40,
        }}
      />
    </View>
  );
};

export default observer(DeleteAccountScreen);

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    padding: 24,
    backgroundColor: COLOR.WHITE,
    flex: 1,
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 24,
  },
  textContainer: {
    ...TEXT.body1,
    padding: 10,
    borderBottomColor: COLOR.GRAY,
    borderBottomWidth: 1,
    marginLeft: 10,
    width: "100%",
    maxWidth: 812,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
    width: 110,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
    marginTop: 16,
  },
});
