import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { StyleSheet, Switch, Text, View } from "react-native";
import PreviewModal from "../components/notifications/PreviewModal";
import RImagePicker from "../components/reusable/RImagePicker";
import RInput from "../components/reusable/RInput";
import SubmitNotification from "../components/submit/SubmitNotification";
import Target from "../components/targets/Target";
import { Notification } from "../components/types/Notification";
import { useNotificationStore } from "../stores/storeContexts";
import { COLOR } from "../styles/Colors";
import { TEXT } from "../styles/Text";

interface Props {
  type: "brand" | "influencer";
}
const NotificationScreen = (props: Props) => {
  const { type } = props;
  const { sendNotification } = useNotificationStore();
  const [notification, setNotification] = useState<Notification>({
    title: "",
    body: "",
    isSaved: false,
  });
  const [targetInfluencer, setTargetInfluencer] = useState<string>("");
  const [targetBrand, setTargetBrand] = useState<string>("");
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const [influencerIds, setInfluencerIds] = useState<Array<string>>([]);
  const [brandIds, setBrandIds] = useState<Array<string>>([]);

  const buttonActive = !!notification.title && !!notification.body;
  const onSend = () => {
    if (type === "brand") {
      sendNotification({ notification, type, targetIds: brandIds });
    } else {
      sendNotification({ notification, type, targetIds: influencerIds });
    }
  };

  const onImageChange = (imageUrl: string) => {
    if (imageUrl) {
      setNotification({ ...notification, image: imageUrl });
    } else {
      setNotification({ ...notification, image: undefined });
    }
  };

  useEffect(() => {
    if (targetInfluencer.length > 0) {
      setInfluencerIds(targetInfluencer.split(","));
    } else {
      setInfluencerIds([]);
    }
  }, [targetInfluencer]);

  useEffect(() => {
    if (targetBrand.length > 0) {
      setBrandIds(targetBrand.split(","));
    } else {
      setBrandIds([]);
    }
  }, [targetBrand]);

  return (
    <View style={styles.container}>
      <Text style={[styles.boldHeader5Text, { marginTop: 0 }]}>
        Notification
      </Text>
      <Text style={styles.boldBody1Text}>รูปภาพ</Text>
      <RImagePicker onChange={onImageChange} />
      <Text style={styles.boldBody1Text}>หัวข้อ</Text>
      <RInput
        type="normal"
        textProps={{
          multiline: true,
          value: notification.title,
          onChangeText: (text) =>
            setNotification({ ...notification, title: text }),
          placeholder: "Text Message",
        }}
        containerStyle={{ maxWidth: 720, height: 160 }}
      />
      <Text style={styles.boldBody1Text}>รายละเอียด</Text>
      <RInput
        type="normal"
        textProps={{
          multiline: true,
          value: notification.body,
          onChangeText: (text) =>
            setNotification({ ...notification, body: text }),
          placeholder: "Text Message",
        }}
        containerStyle={{ maxWidth: 720, height: 160 }}
      />
      <Target
        notification={notification}
        type={type}
        targetBrand={targetBrand}
        targetInfluencer={targetInfluencer}
        setTargetBrand={setTargetBrand}
        setTargetInfluencer={setTargetInfluencer}
      />
      <View style={{ flexDirection: "row", marginVertical: 20 }}>
        <Text style={[styles.boldBody1Text, { marginTop: 0 }]}>
          In-app notification :{" "}
        </Text>
        <Switch
          value={notification.isSaved}
          onValueChange={(value) => {
            setNotification({ ...notification, isSaved: value });
          }}
        />
      </View>
      <SubmitNotification
        onPress={() => setActiveModal(true)}
        active={buttonActive}
      />
      <PreviewModal
        notification={notification}
        activeModal={activeModal}
        onSend={onSend}
        setActiveModal={setActiveModal}
        type={type}
      />
    </View>
  );
};

export default observer(NotificationScreen);

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    padding: 16,
    backgroundColor: COLOR.WHITE,
    flex: 1,
  },
  imageContainer: {
    width: 120,
    height: 120,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: COLOR.OFFWHITE,
    borderRadius: 16,
    marginTop: 10,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
    marginTop: 16,
  },
  body1Text: {
    ...TEXT.body1,
    marginTop: 16,
    color: COLOR.GRAY,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
    marginTop: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 10,
  },
});
