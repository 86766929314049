import React from "react";
import {
  Image,
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { PLACEHOLDER_IMAGE } from "../Util";
import RIcon from "../reusable/RIcon";

interface Props {
  url: string;
}

const LemonCard = (props: Props) => {
  const { url } = props;

  const renderProfileImage = () => {
    return (
      <Image
        style={styles.avatarContainer}
        source={{ uri: PLACEHOLDER_IMAGE }}
      />
    );
  };

  return (
    <View style={styles.lemonContainer}>
      <View style={styles.lemonLeftContainer}>
        <View style={styles.profileContainer}>
          {renderProfileImage()}
          <Text style={styles.boldHeader6Text}>{"Lemon8 Username"}</Text>
        </View>
      </View>
      <View style={styles.lemonRightContainer}>
        <TouchableOpacity
          style={styles.rowContainer}
          onPress={() => Linking.openURL(`https://${url}`)}
        >
          <RIcon name="Link" />
          <Text style={styles.boldButtonText}>{url}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default LemonCard;

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "column",
    backgroundColor: COLOR.WHITE,
    gap: 16,
  },
  columnContainer: {
    flexDirection: "column",
    gap: 4,
  },
  avatarContainer: {
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: COLOR.WHITE,
  },
  lemonContainer: {
    padding: 24,
    width: 928,
    borderRadius: 16,
    margin: 3,
    backgroundColor: COLOR.WHITE,
    flexDirection: "row",
    borderColor: COLOR.LEMON,
    borderWidth: 3,
  },
  lemonLeftContainer: {
    flexDirection: "column",
    gap: 66,
    borderStyle: "dashed",
    borderRightColor: COLOR.BLACK,
    borderRightWidth: 1,
    paddingRight: 48,
    alignItems: "center",
  },
  lemonRightContainer: {
    flex: 1,
    flexDirection: "row",
    paddingLeft: 40,
    alignItems: "center",
  },
  profileContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  rowContainer: {
    flexDirection: "row",
    gap: 8,
  },
  boldHeader6Text: {
    ...TEXT.header6BOLD,
  },
  boldButtonText: {
    ...TEXT.buttonBOLD,
    marginTop: 2,
  },
});
