import React from "react";
import { StyleSheet, View, Text } from "react-native";
import Location from "../atoms/Location";
import Age from "../atoms/Age";
import Gender from "../atoms/Gender";
import { TEXT } from "../../styles/Text";
import { IAge, IGender, ILocation } from "../../types/influencerAccount";

interface Props {
  genders: Array<IGender>;
  locations: Array<ILocation>;
  ages: Array<IAge>;
}

const InstagramDemographic = (props: Props) => {
  const { genders, locations, ages } = props;

  const dataExistFollowers = genders.reduce((a, b) => a + b[1], 0);

  if (genders.length > 0 || locations.length > 0 || ages.length > 0) {
    return (
      <View style={styles.demographicContainer}>
        <Text style={[styles.boldHeader6Text, { alignSelf: "center" }]}>
          Follower Demographic
        </Text>
        {locations.length > 0 && (
          <>
            <Text style={styles.buttonText}>
              <b>Location</b> {"(อาศัยอยู่ที่)"}
            </Text>
            <Location
              locations={locations || []}
              dataExistFollowers={dataExistFollowers || 0}
            />
          </>
        )}
        {genders.length > 0 && (
          <>
            <Text style={styles.buttonText}>
              <b>Gender</b> {"(เพศ)"}
            </Text>
            <Gender
              genders={genders || []}
              dataExistFollowers={dataExistFollowers || 0}
            />
          </>
        )}
        {ages.length > 0 && (
          <>
            <Text style={styles.buttonText}>
              <b>Age</b> {"(อายุ)"}
            </Text>
            <Age
              ages={ages || []}
              dataExistFollowers={dataExistFollowers || 0}
            />
          </>
        )}
      </View>
    );
  } else {
    return <></>;
  }
};

export default InstagramDemographic;

const styles = StyleSheet.create({
  demographicContainer: {
    width: 928,
    flexDirection: "column",
    gap: 24,
  },
  boldHeader6Text: {
    ...TEXT.header6BOLD,
  },
  buttonText: {
    ...TEXT.button,
  },
});
