import React, { useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { COLOR, SHADOW } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import RButton from "../reusable/RButton";
import BannerDeleteModal from "./BannerDeleteModal";
import { IBanner } from "./IBanner";

interface Props {
  banner?: IBanner;
  removeBanner: () => void;
  index: number;
}

const Banner = (props: Props) => {
  const { banner, removeBanner, index } = props;
  const [active, setActive] = useState<boolean>(false);

  if (!banner?.image) {
    return null;
  }

  return (
    <View style={styles.container}>
      <BannerDeleteModal
        onSend={() => {
          removeBanner();
        }}
        activeModal={active}
        setActiveModal={setActive}
      />
      <View style={styles.rowContainer}>
        <Text style={styles.boldHeader6Text}>Banner {index}</Text>
      </View>
      <Image source={{ uri: banner.image }} style={styles.banner} />
      <View style={styles.rowContainer}>
        <Text style={styles.boldBody1Text}>File Name</Text>
        <Text style={[styles.body1Text, { width: "100%" }]}>
          : {banner.image}
        </Text>
      </View>
      <View style={styles.rowContainer}>
        <Text style={styles.boldBody1Text}>URL</Text>
        <Text style={[styles.body1Text, { width: "100%" }]}>
          : {banner.url}
        </Text>
      </View>
      <RButton
        onPress={() => setActive(true)}
        title="Delete"
        icon="TrashRed"
        textStyle={{ marginLeft: 4, color: COLOR.DARKPINK }}
        containerStyle={styles.buttonContainer}
      />
    </View>
  );
};

export default Banner;

const styles = StyleSheet.create({
  banner: { width: 330, height: 100, borderRadius: 14, margin: 10 },
  container: {
    flexDirection: "column",
    margin: 24,
    marginLeft: 0,
    padding: 24,
    borderRadius: 16,
    ...SHADOW.BLACK5,
  },
  buttonContainer: {
    borderColor: COLOR.PINK,
    backgroundColor: COLOR.WHITE,
    borderWidth: 1,
    width: 150,
    marginTop: 16,
  },
  rowContainer: {
    flexDirection: "row",
    padding: 10,
    alignItems: "center",
  },
  columnContainer: {
    flexDirection: "column",
    gap: 8,
  },
  boldHeader6Text: {
    ...TEXT.header6BOLD,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
    width: 100,
  },
  body1Text: {
    ...TEXT.body1,
  },
});
