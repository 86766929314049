import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import RDropDown from "../reusable/DropdownPicker";
import RButton from "../reusable/RButton";
import RInput from "../reusable/RInput";
import RXModal from "../reusable/RXModal/RXModal";

interface Props {
  onClose: () => void;
  onAccept: () => void;
  type: "permanent" | "temporary" | "unban";
  id: string;
  active: boolean;
  bannedDate: string;
  setBannedDate: React.Dispatch<React.SetStateAction<string>>;
  reason: string;
  setReason: React.Dispatch<React.SetStateAction<string>>;
  banStatus: boolean;
}

const BanModal = (props: Props) => {
  const {
    onClose,
    onAccept,
    type,
    active,
    bannedDate,
    setBannedDate,
    reason,
    setReason,
    banStatus,
  } = props;

  const [open, setOpen] = useState<boolean>(false);

  const acceptDisabled = !banStatus && !reason.trim();

  const DropdownComponent = () => {
    return (
      <View>
        <RDropDown
          options={[
            {
              label: "1",
              value: "1",
            },
            {
              label: "3",
              value: "3",
            },
            { label: "7", value: "7" },
            { label: "15", value: "15" },
            { label: "30", value: "30" },
          ]}
          open={open}
          setOpen={setOpen}
          placeHolder="1"
          value={bannedDate}
          onValueChange={(value) => setBannedDate(value as string)}
          containerStyle={styles.dropdownContainer}
          listItemStyle={styles.listItem}
        />
      </View>
    );
  };

  const DetailComponent = () => {
    switch (type) {
      case "permanent":
        return <Text style={styles.boldHeader5Text}>ระงับบัญชีถาวร</Text>;
      case "temporary":
        return (
          <>
            <Text style={styles.boldHeader5Text}>ระงับบัญชีชั่วคราว</Text>
            <View style={[styles.rowContainer, { zIndex: 99999, gap: 8 }]}>
              <View>
                <Text style={styles.body1Text}>โปรดใส่จำนวน</Text>
              </View>
              <DropdownComponent />
              <View>
                <Text style={styles.body1Text}>วัน</Text>
              </View>
            </View>
          </>
        );
      case "unban":
        return <Text style={styles.boldHeader5Text}>ปลดแบนบัญชีนี้</Text>;
    }
  };

  const ReasonComponent = () => {
    if (!banStatus) {
      return (
        <View>
          <View style={[styles.rowContainer, { gap: 4 }]}>
            <Text style={styles.body1Text}>รายละเอียด</Text>
            <Text style={[styles.body1Text, { color: COLOR.PINK }]}>*</Text>
          </View>
          <RInput
            textProps={{
              value: reason,
              onChangeText: (text) => setReason(text),
            }}
            containerStyle={styles.inputContainer}
            type="normal"
          />
        </View>
      );
    }
    return <></>;
  };

  return (
    <RXModal transparent={true} onClose={onClose} visible={active}>
      <View style={styles.container}>
        <TouchableOpacity onPress={onClose}>
          <View
            style={[
              StyleSheet.absoluteFill,
              {
                backgroundColor: "rgba(0,0,0,0.7)",
              },
            ]}
          />
        </TouchableOpacity>
        <View style={styles.contentContainer}>
          <View style={styles.columnContainer}>
            <DetailComponent />
            <ReasonComponent />
            <View
              style={[styles.rowContainer, { justifyContent: "space-between" }]}
            >
              <RButton
                title="ยกเลิก"
                onPress={onClose}
                containerStyle={styles.cancelButtonStyle}
              />
              <RButton
                title="ยืนยัน"
                onPress={onAccept}
                containerStyle={styles.approveButtonStyle}
                disabled={acceptDisabled}
              />
            </View>
          </View>
        </View>
      </View>
    </RXModal>
  );
};

export default BanModal;

const styles = StyleSheet.create({
  container: {
    width: 400,
    zIndex: 999999,
  },
  contentContainer: {
    backgroundColor: COLOR.WHITE,
    padding: 32,
    borderRadius: 16,
  },
  rowContainer: {
    flexDirection: "row",
    gap: 32,
    alignItems: "center",
  },
  columnContainer: {
    flexDirection: "column",
    height: "100%",
    backgroundColor: COLOR.WHITE,
    maxWidth: 360,
    overflow: "hidden",
    gap: 16,
  },
  dropdownContainer: {
    width: 60,
    height: 40,
    padding: 8,
    margin: 0,
    borderRadius: 12,
  },
  textInputContainer: {
    flexDirection: "row",
    width: 60,
    height: 40,
    padding: 8,
    paddingTop: 2,
    margin: 0,
    borderWidth: 1,
    borderRadius: 12,
    borderColor: COLOR.GRAY,
  },
  inputContainer: {
    backgroundColor: COLOR.WHITE,
    borderWidth: 1,
    borderRadius: 12,
    borderColor: COLOR.GRAY,
  },
  textInput: {
    width: 20,
  },
  iconContainer: {
    transform: [{ rotate: "90deg" }],
    alignSelf: "flex-end",
  },
  listItem: {
    width: 60,
    height: 100,
    padding: 8,
    margin: 0,
  },
  approveButtonStyle: {
    width: 110,
    marginTop: 16,
  },
  cancelButtonStyle: {
    width: 110,
    backgroundColor: COLOR.PINK,
    marginTop: 16,
  },
  body1Text: {
    ...TEXT.body1,
  },
  whiteBody1Text: {
    ...TEXT.body1,
    color: COLOR.WHITE,
  },
  orangeBody1Text: {
    ...TEXT.body1,
    color: COLOR.ORANGE,
  },
  greenCaptionText: {
    ...TEXT.caption1,
    color: COLOR.GREEN,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
    marginBottom: 8,
  },
  buttonText: {
    ...TEXT.button,
  },
});
