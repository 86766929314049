import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { TEXT } from "../../styles/Text";
import RInput from "../reusable/RInput";
import { Notification } from "../types/Notification";

interface Props {
  notification: Notification;
  type: "brand" | "influencer";
  targetInfluencer: string;
  targetBrand: string;
  setTargetInfluencer: React.Dispatch<React.SetStateAction<string>>;
  setTargetBrand: React.Dispatch<React.SetStateAction<string>>;
}

const Target = (props: Props) => {
  const {
    type,
    targetBrand,
    targetInfluencer,
    setTargetBrand,
    setTargetInfluencer,
  } = props;

  const renderTextInput = () => {
    if (type === "influencer") {
      return (
        <RInput
          type="normal"
          textProps={{
            multiline: true,
            style: [styles.textInput, { height: 100, marginTop: 10 }],
            placeholder:
              "Notification Account ID ( Seperated by Comma ,  )\nExample : notification001,notification001, notification001",
            value: targetInfluencer,
            onChangeText: (text) => setTargetInfluencer(text),
          }}
          containerStyle={{ maxWidth: 720, height: 160 }}
        />
      );
    } else {
      return (
        <RInput
          type="normal"
          textProps={{
            multiline: true,
            style: [styles.textInput, { height: 100, marginTop: 10 }],
            placeholder:
              "Brand Account ID ( Seperated by Comma ,  )\nExample : brandid001,brandid002,brandid003",
            value: targetBrand,
            onChangeText: (text) => setTargetBrand(text),
          }}
          containerStyle={{ maxWidth: 720, height: 160 }}
        />
      );
    }
  };
  return (
    <View>
      <Text style={styles.boldBody1Text}>Target User</Text>
      {renderTextInput()}
    </View>
  );
};

export default Target;

const styles = StyleSheet.create({
  textInput: {
    backgroundColor: "#F8F8F8",
    height: 160,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
    marginTop: 10,
  },
});
