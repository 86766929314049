import { View, Text, StyleSheet } from "react-native";
import React from "react";
import { COLOR } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import RIcon, { PNGIcon } from "../reusable/RIcon";

interface Props {
  reviewTags: { icon: PNGIcon; text: string; value: number }[];
}

const InfluReviewTagDisplay = (props: Props) => {
  const { reviewTags } = props;

  return (
    <View style={styles.tagsContainer}>
      {reviewTags.map((reviewTag, index) => {
        return (
          <View style={styles.tagContainer} key={reviewTag.text + index}>
            <RIcon name={reviewTag.icon} />
            <Text style={styles.body1Text}>
              {reviewTag.text}
              <Text style={styles.boldBody1Text}> ({reviewTag.value})</Text>
            </Text>
          </View>
        );
      })}
    </View>
  );
};

export default InfluReviewTagDisplay;

const styles = StyleSheet.create({
  tagsContainer: {
    flexWrap: "wrap",
    flexDirection: "row",
    gap: 8,
    borderBottomColor: COLOR.LIGHTGRAY,
    borderBottomWidth: 1,
    borderStyle: "dashed",
    paddingBottom: 24,
  },
  tagContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    backgroundColor: COLOR.LIGHTGREEN2,
    borderRadius: 16,
    gap: 8,
  },
  body1Text: {
    ...TEXT.body1,
    color: COLOR.DARKGREEN,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
    color: COLOR.DARKGREEN,
  },
});
