import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { useNavigate } from "react-router-dom";
import { PLACEHOLDER_IMAGE } from "../../components/Util";
import ListItem, {
  ListKey,
  TitleKey,
} from "../../components/reusable/ListItem";
import RIcon from "../../components/reusable/RIcon";
import {
  useBrandStore,
  useNavigationStore,
  useVerificationStore,
} from "../../stores/storeContexts";
import { COLOR, SHADOW } from "../../styles/Colors";
import { TEXT } from "../../styles/Text";
import { formatDate } from "../../utils/formatDate";
import ActivityIndicator from "../../components/ActivityIndicator";

const titles: Array<TitleKey> = [];

const keys: Array<ListKey> = [
  { display: "Campaign Name", value: "name", size: 1.5 },
  {
    display: "Limit",
    value: "limit",
    size: 1,
  },
  {
    display: "Product",
    value: "value",
    size: 1,
  },
  {
    display: "Cash",
    value: "cash",
    size: 1,
  },
  {
    display: "Is Nego",
    value: "negotiation",
    size: 1,
  },
  {
    display: "Match",
    value: "matchCount",
    size: 1,
  },
  {
    display: "Done",
    value: "doneCount",
    size: 1,
  },
  {
    display: "Cancel",
    value: "cancelledCount",
    size: 1,
  },
  {
    display: "Reject",
    value: "rejectedCount",
    size: 1,
  },
  {
    display: "Created At",
    value: "createdAt",
    size: 1,
  },
];

keys.forEach((key) => {
  titles.push({ display: key.display, size: key.size });
});

const BrandDetailScreen = () => {
  const { pushStack } = useNavigationStore();
  const { brand } = useBrandStore();
  const {
    brandId,
    img = "-",
    isEmailVerified = false,
    name = "",
    category = "",
    dealCount = 0,
    negoDealCount = 0,
    createdAt = "01/01/1970",
  } = brand;

  const navigation = useNavigate();

  const { getDeals, deals, setSortBy, setSortOrder, getDeal } =
    useVerificationStore();

  const { sortBy = { display: "createdAt", value: "createdAt" }, sortOrder } =
    deals;
  const [key, setKey] = useState<string>("createdAt");
  const [sort, swapSort] = useState<"asc" | "desc">("desc");

  const setSort = (key: string) => {
    if (sortBy.display !== key) {
      setSortBy(
        "brands",
        "deals",
        keys[keys.findIndex((keyIndex) => keyIndex.display === key)]
      );
      setSortOrder("brands", "deals", "asc");
    } else {
      if (sortOrder === "asc") {
        setSortOrder("brands", "deals", "desc");
      } else if (sortOrder === "desc") {
        setSortOrder("brands", "deals", "desc");
        setSortBy("brands", "deals", {
          display: "createdAt",
          value: "createdAt",
        });
      }
    }
  };

  const renderSmaillEmailIcon = (isVerified: boolean) => {
    if (isVerified) {
      return (
        <View style={styles.smallEmailStatusContainer}>
          <RIcon name="CheckCircleFill" size={21} />
        </View>
      );
    } else {
      return (
        <View style={styles.smallEmailStatusContainer}>
          <RIcon name="WaitingFill" size={21} />
        </View>
      );
    }
  };

  const renderHeaderBrand = () => {
    return (
      <View style={styles.rowContainer}>
        <View style={styles.rowContainer}>
          <View style={{ position: "relative", marginRight: 18 }}>
            <Image
              source={{ uri: img === "-" ? PLACEHOLDER_IMAGE : img }}
              style={{
                width: 50,
                height: 50,
                borderRadius: 50,
                borderWidth: 3,
                borderColor: COLOR.OFFWHITE,
              }}
            />
            {renderSmaillEmailIcon(!!isEmailVerified)}
          </View>
          <View style={styles.columnContainer}>
            <Text style={styles.boldHeader5Text}>{name}</Text>
            <View style={styles.categoryContainer}>
              <Text style={styles.body2Text}>{category}</Text>
            </View>
          </View>
        </View>
        <View style={styles.centerColumnContainer}>
          <Text style={styles.boldBody1Text}>{brandId}</Text>
          <Text style={styles.body1Text}>Brand ID</Text>
        </View>
        <View style={styles.centerColumnContainer}>
          <Text style={styles.boldBody1Text}>{dealCount}</Text>
          <Text style={styles.body1Text}>Total Campaign</Text>
        </View>
        <View style={styles.centerColumnContainer}>
          <Text style={styles.boldBody1Text}>{negoDealCount}</Text>
          <Text style={styles.body1Text}>Nego Campaign</Text>
        </View>
        <View style={styles.centerColumnContainer}>
          <Text style={styles.body1Text}>{formatDate(createdAt)}</Text>
          <Text style={styles.body1Text}>Register Date</Text>
        </View>
      </View>
    );
  };

  useEffect(() => {
    if (brand.brandId === "") {
      navigation("/brand/list");
    }
    getDeals(brandId);
  }, []);

  useEffect(() => {
    setSort(key);
  }, [key, sort]);

  if (deals.loading) {
    return <ActivityIndicator style={{ marginTop: 32 }} />;
  }

  return (
    <View style={{ flex: 1 }}>
      {renderHeaderBrand()}
      <View>
        <ListItem
          keys={keys}
          title={titles}
          selectedList={[]}
          setSelectedList={() => {}}
          sortOrder={sort}
          sortKey={sortBy.display}
          setSortKey={setKey}
          swapSort={() => {
            if (sort === "asc") {
              swapSort("desc");
            } else {
              swapSort("asc");
            }
          }}
          containerStyle={[
            SHADOW.BLACK5,
            {
              zIndex: 999,
            },
          ]}
        />
        {deals.data?.map((deal, index) => (
          <ListItem
            key={deal.brandId}
            keys={keys}
            ignored={["_id"]}
            data={deal}
            selectedList={[]}
            setSelectedList={() => {}}
            index={index}
            onPress={() => {
              getDeal(deal.dealId);
              pushStack({
                display: deal?.name || "Deal",
                path: "/brand/deal/detail",
              });
              navigation("/brand/deal/detail");
            }}
            containerStyle={styles.listContainer}
            nameContainerStyle={styles.nameContainer}
            nameTextStyle={styles.body1Text}
          />
        ))}
      </View>
    </View>
  );
};

export default observer(BrandDetailScreen);

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 104,
    padding: 16,
    backgroundColor: COLOR.WHITE,
  },
  listContainer: {
    minHeight: 40,
  },
  columnContainer: {
    flexDirection: "column",
    justifyContent: "center",
    gap: 8,
  },
  centerColumnContainer: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 16,
  },
  smallEmailStatusContainer: {
    position: "absolute",
    width: 21,
    height: 21,
    backgroundColor: COLOR.WHITE,
    borderColor: COLOR.WHITE,
    borderWidth: 3,
    bottom: 0,
    right: 0,
    borderRadius: 28,
    alignItems: "center",
    justifyContent: "center",
  },
  categoryContainer: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    borderRadius: 20,
    backgroundColor: COLOR.LIGHTGREEN,
  },
  nameContainer: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    paddingLeft: 10,
  },
  boldBody1Text: {
    ...TEXT.body1BOLD,
  },
  body1Text: {
    ...TEXT.body1,
  },
  boldHeader5Text: {
    ...TEXT.header5BOLD,
  },
  body2Text: {
    ...TEXT.body2,
  },
});
