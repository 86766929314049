import React, { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { COLOR, SHADOW } from "../styles/Colors";
import { TEXT } from "../styles/Text";

import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useNavigationStore,
  useVerificationStore,
} from "../stores/storeContexts";
import BulkVerificationModal from "./BulkVerificationModal";
import { WINDOW_HEIGHT } from "./Util";
import RIcon from "./reusable/RIcon";

type Menu = {
  display: string;
  path: string;
};

const brandMenu: Array<Menu> = [
  {
    display: "Verification",
    path: "/brand/verification",
  },
  {
    display: "User List",
    path: "/brand/list",
  },
  {
    display: "Notification",
    path: "/brand/notification",
  },
  {
    display: "Banner",
    path: "/brand/banner",
  },
];

const influencerMenu: Array<Menu> = [
  {
    display: "Verification",
    path: "/influencer/verification",
  },
  {
    display: "User List",
    path: "/influencer/list",
  },
  {
    display: "Notification",
    path: "/influencer/notification",
  },
  {
    display: "Delete Account",
    path: "/influencer/delete",
  },
];

const Drawer = () => {
  const { bulkApproveBrand, bulkApproveInflu, setSelectedList, selectedList } =
    useVerificationStore();
  const { pushStack, clearStack } = useNavigationStore();
  const navigation = useNavigate();
  const route = useLocation();

  const [active, setActive] = useState<string>("");

  const renderTitle = ({ menu }: { menu: Menu }) => {
    if (active === menu.path) {
      return <Text style={styles.BoldButtonText}>{menu.display}</Text>;
    } else {
      return <Text style={styles.buttonText}>{menu.display}</Text>;
    }
  };

  const renderMenu = () => {
    if (route.pathname.includes("influencer")) {
      return influencerMenu.map((menu) => {
        return (
          <TouchableOpacity
            style={styles.menuContainer}
            onPress={() => {
              clearStack();
              setSelectedList([]);
              pushStack({
                display: "Influencer",
                path: "/influencer/verification",
              });
              pushStack(menu);
              navigation(menu.path);
            }}
          >
            <Text>{renderTitle({ menu })}</Text>
            <RIcon name="ArrowShortRight" />
          </TouchableOpacity>
        );
      });
    } else {
      return brandMenu.map((menu) => {
        return (
          <TouchableOpacity
            style={styles.menuContainer}
            onPress={() => {
              clearStack();
              setSelectedList([]);
              pushStack({ display: "Brand", path: "/brand/verification" });
              pushStack(menu);
              navigation(menu.path);
            }}
          >
            <Text>{renderTitle({ menu })}</Text>
            <RIcon name="ArrowShortRight" />
          </TouchableOpacity>
        );
      });
    }
  };

  const handleOnAccept = async () => {
    if (route.pathname.includes("brand")) {
      await bulkApproveBrand(selectedList);
      setSelectedList([]);
    } else {
      await bulkApproveInflu(selectedList);
      setSelectedList([]);
    }
  };

  useEffect(() => {
    setActive(route.pathname);
  }, [route.pathname]);

  if (route.pathname === "/" || route.pathname === "/platform") {
    return <></>;
  }

  return (
    <>
      <BulkVerificationModal
        listCount={selectedList.length}
        onAccept={handleOnAccept}
        onCancel={() => setSelectedList([])}
      />
      <View style={styles.container}>{renderMenu()}</View>
    </>
  );
};

export default observer(Drawer);

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    paddingHorizontal: 16,
    width: 229,
    backgroundColor: COLOR.WHITE,
    position: "absolute",
    height: WINDOW_HEIGHT - 120,
    zIndex: 9999,
    marginTop: 120,
    ...SHADOW.BLACK5,
  },
  menuContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
  },
  buttonText: {
    ...TEXT.button,
  },
  BoldButtonText: {
    ...TEXT.buttonBOLD,
  },
});
